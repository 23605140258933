import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

import { Button } from "react-bootstrap";

import { League } from "../../models/Leagues";
import { imageUrl } from "../../config";

import { MainContainer, PageHeader, Small, Spinner, Well } from "../atoms";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";
import {
    ButtonSkeleton,
    LargeImageSkeleton,
    PageHeaderSkeleton,
    TableSkeleton,
} from "../atoms/Skeletons";
import { ShowImage } from "./styles/LeagueTemplate.styled";

interface TeamProps {
    header: string;
    image: string;
    showId: number;
    league: League;
    headerSecondary?: string;
    loading?: boolean;
    skeleton?: boolean;
}

const TeamTemplate: React.FC<TeamProps> = ({
    header,
    headerSecondary,
    image,
    loading,
    league,
    skeleton,
    showId,
    children,
}) => {
    const history = useHistory();

    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    <LargeImageSkeleton />
                    <Well>
                        <ButtonSkeleton />
                        <TableSkeleton size={2} />
                    </Well>
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && league && (
                <>
                    <div className="mb-3">
                        <Helmet prioritizeSeoTags defer={false}>
                            <title>{`${headerSecondary} - ${header}`}</title>
                            <meta
                                name="description"
                                content={`View ${headerSecondary}'s Team`}
                            />
                            <meta
                                property="og:url"
                                content={`https://realtvfantasy.com${window.location.pathname}`}
                            />
                            <meta
                                property="og:title"
                                content={`${headerSecondary} - ${header}`}
                            />
                            <meta
                                property="og:description"
                                content={`View ${headerSecondary}'s Team`}
                            />
                            <meta property="og:image" content={image} />
                            <meta
                                property="twitter:card"
                                content="summary_large_image"
                            />
                            <meta
                                property="twitter:title"
                                content={`${headerSecondary} - ${header}`}
                            />
                            <meta
                                property="twitter:description"
                                content={`${header} - ${headerSecondary}'s Team`}
                            />
                            <meta property="twitter:image" content={image} />
                        </Helmet>
                        <PageHeader>
                            <h1>
                                {header}{" "}
                                {headerSecondary ? (
                                    <Small>{headerSecondary}</Small>
                                ) : (
                                    ""
                                )}
                            </h1>
                        </PageHeader>
                        <ShowImage
                            className="mb-3"
                            src={
                                league.img
                                    ? `${imageUrl}${league.img
                                          .replace(/ /g, "%20")
                                          .replace(/\(/g, "%28")
                                          .replace(/\)/g, "%29")}`
                                    : image
                                          .replace(/ /g, "%20")
                                          .replace(/\(/g, "%28")
                                          .replace(/\)/g, "%29")
                            }
                            showId={showId}
                        />
                    </div>
                    {/* <Ad /> */}
                    <GoogleAd slot="7237766115" height={90} />
                    <Button
                        className="mb-3"
                        variant="light"
                        onClick={() =>
                            history.push(
                                `/myleagues/view/${league.id}${
                                    league.id === "0" ||
                                    league.id === 0 ||
                                    league.id === "team" ||
                                    league.id === "budget"
                                        ? `/${league.show_id}`
                                        : ""
                                }`
                            )
                        }
                    >
                        Back
                    </Button>
                    {children}
                    {/* <Ad fixed /> */}
                    <GoogleAd fixed slot="7455698336" height={90} />
                </>
            )}
        </MainContainer>
    );
};

export default TeamTemplate;
