export const colors = {
    header: "#1f1f1f",
    white: "#ffffff",
    error: "#dc3545",

    primaryLight: "#edf1f7",
    secondaryLight: "#fafafa",
    secondaryHeaderLight: "#f1f1f1",
    borderLight: "#e4e9f2",

    primaryDark: "#151a30",
    secondaryDark: "#222b45",
    secondaryHeaderDark: "#222b45",
    borderDark: "#000000",

    primaryBtn: "#0495ee",
    primaryBtnHover: "#40bbf4",
    successBtn: "#60af20",
    successBtnHover: "#8fcf50",
    dangerBtn: "#b00020",
    dangerBtnHover: "#cf3341",

    warningAlert: "#ff9f05",
    successAlert: "#60af20",
    dangerAlert: "#b00020",

    button: "rgb(32, 137, 220)",
    gold: "#d3af37",
    silver: "#c0c0c0",
    bronze: "#CD7F32",
    fire: "#e25822",
    pinkRose: "#FF007F",
    redRose: "#cb0e40",
    bb: "#22649A",
    bip: "#778910",
};
