import React from "react";
import { useSelector } from "react-redux";

import determineCountdown from "../../helpers/determineCountdown";
import { Show } from "../../models/Shows";
import { League } from "../../models/Leagues";
import { RootState } from "../../store";

import Alert from "react-bootstrap/Alert";

import CountdownTimer from "./CountdownTimer";

interface LineupHeaderProps {
    type?: "lineup" | "question";
    show: Show;
    league: League;
}

const LineupHeader: React.FC<LineupHeaderProps> = ({
    show,
    league,
    type = "lineup",
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );

    const showType = show.type === "week" ? "Week" : "Episode";
    const lockedMessage =
        type === "lineup"
            ? `Lineups Are Locked For The ${showType}`
            : `Answers Are Locked For The ${showType}`;
    const episodeStartedMessage =
        type === "lineup"
            ? `This league doesn't start until ${showType} ${league.episode_started}. You can set your lineup starting ${showType} ${league.episode_started}`
            : `This league doesn't start until ${showType} ${league.episode_started}. You can answer challenge questions starting ${showType} ${league.episode_started}`;

    const isLocked = () => show.locked;
    const isCompleted = () => show.current_episode > show.episodes;
    const showEpisodeStarted = () =>
        show.current_episode < league.episode_started;
    const showTimer = () => {
        return (
            !show.locked &&
            show.active &&
            show.current_episode <= show.episodes &&
            show.current_episode >= league.episode_started
        );
    };

    return (
        <>
            {isLocked() && (
                <Alert className="mr-2 ml-2" variant="warning">
                    {lockedMessage}
                </Alert>
            )}
            {showTimer() && (
                <div className="ml-3 mr-3">
                    <CountdownTimer
                        targetDate={determineCountdown(timezoneOffsets, show)}
                    />
                </div>
            )}
            {isCompleted() && (
                <Alert className="mr-2 ml-2" variant="warning">
                    The league has been completed
                </Alert>
            )}
            {showEpisodeStarted() && (
                <Alert className="mr-2 ml-2 mt-3" variant="warning">
                    {episodeStartedMessage}
                </Alert>
            )}
        </>
    );
};

export default LineupHeader;
