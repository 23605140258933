import React from "react";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Avatar from "@mui/material/Avatar";
import Row from "react-bootstrap/Row";

import { RootState } from "../../store";
import { imageUrl } from "../../config";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import TableTeam from "../atoms/TableTeam";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import CastTeam from "./CastTeam";

interface DraftListItemProps {
    player: any; //Cast | Team | LeagueTeams
    buttonText?: string;
    onClickAction?: () => void;
    draftee?: LeaguesLeagueUser;
    leagueUser?: LeaguesLeagueUser;
    league?: League;
    disabled?: boolean;
    draft?: boolean;
}

const DraftListItem: React.FC<DraftListItemProps> = ({
    player,
    buttonText,
    onClickAction,
    draftee,
    leagueUser,
    league,
    disabled,
    draft,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <TableTeam>
            <tbody>
                <tr>
                    <td className="pl-4">
                        <Row className="align-items-center">
                            <Avatar
                                style={{ height: 60, width: 60 }}
                                src={`${imageUrl}${player.pic}`}
                            />
                            <div className="pl-2">
                                <span style={{ color: theme.textColor }}>{`${
                                    player.first_name && player.first_name
                                } ${
                                    player.last_name && player.last_name
                                }`}</span>
                                <CastTeam
                                    left
                                    size={10}
                                    team={
                                        player.cast
                                            ? player.cast.team
                                            : player.team
                                    }
                                    partner={
                                        player.cast
                                            ? player.cast.partner
                                            : player.partner
                                    }
                                />
                            </div>
                        </Row>
                        {subscription &&
                            !draft &&
                            hasPremiumSub(subscription.name) && (
                                <div
                                    className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none"
                                    style={{ fontSize: 14 }}
                                >
                                    <br />
                                    Avg Draft Position:{" "}
                                    <b>
                                        {player.average_draft_position ?? "-"}
                                    </b>
                                </div>
                            )}
                    </td>
                    {subscription &&
                        !draft &&
                        hasPremiumSub(subscription.name) && (
                            <td
                                className="d-none d-md-table-cell"
                                style={{
                                    color: theme.textColor,
                                }}
                            >
                                Avg Draft Position:{" "}
                                <b>{player.average_draft_position ?? "-"}</b>
                            </td>
                        )}
                    <td className="text-right" style={{ width: 80 }}>
                        {buttonText &&
                        onClickAction &&
                        draftee &&
                        league &&
                        league.draft_started ? (
                            <Button
                                disabled={
                                    draftee.id !== leagueUser.id || disabled
                                }
                                onClick={onClickAction}
                            >
                                {buttonText}
                            </Button>
                        ) : null}
                    </td>
                </tr>
            </tbody>
        </TableTeam>
    );
};

export default DraftListItem;
