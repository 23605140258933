import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import { League } from "../../models/Leagues";
import { DraftTypes, FullShow, ShowLimits } from "../../models/Shows";
import { Cast } from "../../models/Cast";
import { LeagueScoring } from "../../models/LeagueScoring";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { validGenderLimits, validRookieLimit } from "../../helpers/limitChecks";
import strings from "../../config/strings";
import { Socials } from "../../models/Socials";

import { ArticleTextSkeleton } from "../atoms/Skeletons";
import { SectionHeader } from "../atoms";
import Input from "../atoms/Input";
import Select from "../atoms/Select";
import SubmitButton from "../atoms/SubmitButton";
import LeagueWeeklyRedraftForm from "../molecules/LeagueWeeklyRedraftForm";
import LeagueTeamForm from "../molecules/LeagueTeamForm";
import ScoringForm from "../molecules/ScoringForm";
import PageTemplate from "../templates/PageTemplate";
import RadioButtons from "../atoms/RadioButtons";
import LeagueFullDraftForm from "../molecules/LeagueFullDraftForm";
import AreYouSureModal from "../atoms/AreYouSureModal";
import SponsoredLeagueForm from "./SponsoredLeagueForm";
import { ChallengeQuestion } from "../../models/ChallengeQuestions";
import Switch from "../atoms/Switch";
import QuestionsForm from "../molecules/QuestionsForm";

const validationSchema = yup.object({
    leagueName: yup.string().required("League Name is required"),
    episodeStarted: yup.number().required("Episode Started is required"),
    draftType: yup.string().required("Draft Type is required"),
    playersPerWeek: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Weekly Redraft",
        then: yup.number().required("Players Per Week is required"),
        otherwise: yup.number().nullable(),
    }),
    leagueType: yup.string().required("League Type is required"),
    leagueSize: yup.mixed().nullable(),
    playerLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) =>
            draftType === "Weekly Redraft" || draftType === "Weekly Budget",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    playersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.number().required("Players Per Team is required").nullable(),
        otherwise: yup.number().nullable(),
    }),
    startersPerTeam: yup.number().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup
            .number()
            .required("Starters Per Team is required")
            .test(
                "starters_less_than_players",
                "Starters Per Team must be less than or equal to Players Per Team",
                function (value) {
                    const { playersPerTeam } = this.parent;
                    if (value) {
                        if (playersPerTeam < value) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .nullable(),
        otherwise: yup.number().nullable(),
    }),
    swaps: yup.mixed().optional(),
    lastSwapEp: yup.mixed().optional(),
    rookieLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    maleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    femaleLimit: yup.mixed().when("draftType", {
        is: (draftType: DraftTypes) => draftType === "Team",
        then: yup.mixed().nullable(),
        otherwise: yup.mixed().nullable(),
    }),
    allowTrading: yup.boolean().optional(),
    scores: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            description: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
    includeQuestions: yup.boolean().optional(),
    questions: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            description: yup.string(),
            score: yup
                .string()
                .matches(/^-?\d*$/g, "Invalid Score")
                .required("Invalid Score"),
        })
    ),
    instagram: yup.string().nullable(),
    twitter: yup.string().nullable(),
    tiktok: yup.string().nullable(),
    podcast: yup
        .string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter a valid url!"
        )
        .nullable(),
});

interface ParamTypes {
    id: string;
}

const EditLeagueForm: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [scoring, setScoring] = useState<LeagueScoring[]>([]);
    const [questionScoring, setQuestionScoring] = useState<ChallengeQuestion[]>(
        []
    );
    const [scoreChangeVisible, setScoreChangeVisible] =
        useState<boolean>(false);
    const [startersVisible, setStartersVisible] = useState<boolean>(false);
    const [weeklyVisible, setWeeklyVisible] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [castCount, setCastCount] = useState<number>(0);
    const [league, setLeague] = useState<League>();
    const [show, setShow] = useState<FullShow>();
    const [showLimits, setShowLimits] = useState<ShowLimits>({
        max_player_limit: 5,
        max_players_per_week: 10,
        max_players_per_team: 10,
        max_starters_per_team: 10,
    });
    const [initialValues, setInitialValues] = useState({
        show: "",
        leagueName: "",
        episodeStarted: 1,
        draftType: "Weekly Redraft",
        leagueType: "Private",
        img: null,
        leagueSize: undefined,
        allowTrading: true,
        playersPerWeek: 2,
        playerLimit: null,
        rookieLimit: null,
        maleLimit: null,
        femaleLimit: null,
        numPicks: "N/A",
        playersPerTeam: 2,
        startersPerTeam: 1,
        includeQuestions: false,
        scores: [],
        questions: [],
        instagram: null,
        twitter: null,
        tiktok: null,
        podcast: null,
        swaps: null,
        lastSwapEp: null,
    });

    const deleteLeagueRequest = useApi(apiRoutes.DELETE_LEAGUE(id), {
        onSuccess: (response: string) => {
            toast.success(response);
            setDeleting(false);
            history.push("/myleagues");
        },
        onFailure: () => {
            setDeleting(false);
        },
        responseKey: "message",
    });
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id), {
        onSuccess: onLeagueLoaded,
    });
    const saveLeagueRequest = useApi(apiRoutes.SAVE_LEAGUE(id), {
        onSuccess: (response: string) => {
            setSubmitting(false);
            toast.success(response);
            history.push(`/myleagues/view/${id}`);
        },
        onFailure: () => setSubmitting(false),
        responseKey: "message",
    });

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function onLeagueLoaded(response: {
        league: League;
        socials: Socials;
        leagueUser: LeaguesLeagueUser;
        scoring: LeagueScoring[];
        questions: ChallengeQuestion[];
        show: FullShow;
        canViewLeague: boolean;
    }) {
        if (response.canViewLeague && response.leagueUser.commissioner) {
            setScoring(response.scoring);
            setQuestionScoring(response.questions);

            setInitialValues({
                show: response.show.show,
                leagueName: response.league.name,
                episodeStarted: response.league.episode_started,
                draftType: response.league.draft_type,
                img: response.league.img ?? null,
                leagueType: response.league.sponsored
                    ? "Sponsored"
                    : response.league.public
                    ? "Public"
                    : "Private",
                leagueSize: response.league.league_size
                    ? response.league.league_size
                    : undefined,
                playersPerWeek: response.league.players_per_team,
                playerLimit: response.league.player_limit,
                playersPerTeam: response.league.players_per_team,
                startersPerTeam: response.league.starters_per_week,
                rookieLimit: response.league.rookie_limit,
                maleLimit: response.league.male_limit,
                femaleLimit: response.league.female_limit,
                allowTrading: response.league.allow_trading,
                numPicks: response.league.num_picks
                    ? response.league.num_picks.toString()
                    : null,
                scores: [...response.scoring],
                questions: [...response.questions],
                includeQuestions: response.league.include_questions,
                instagram: response.socials ? response.socials.instagram : null,
                twitter: response.socials ? response.socials.twitter : null,
                tiktok: response.socials ? response.socials.tiktok : null,
                podcast: response.socials ? response.socials.podcast : null,
                swaps: response.league.swaps,
                lastSwapEp: response.league.last_swap_ep,
            });
            const availableCast = response.show.cast.filter((cast: Cast) => {
                return cast.eliminated === false;
            });
            setCastCount(availableCast.length);
            setLeague(response.league);
            if (response.show.show_limits) {
                setShowLimits({
                    max_players_per_week:
                        response.show.show_limits?.max_players_per_week ?? 10,
                    max_starters_per_team:
                        response.show.show_limits?.max_starters_per_team ?? 10,
                    max_players_per_team:
                        response.show.show_limits?.max_players_per_team ?? 10,
                    max_player_limit:
                        response.show.show_limits?.max_player_limit ?? 5,
                });
            }
            setShow(response.show);
        } else {
            window.location.href = "/";
        }
    }

    const episodeRange = (start: number, end: number) => {
        let episodes = [];
        for (var i = start; i <= end; i++) {
            if (i === show.current_episode) {
                episodes.push({ id: i, value: `${i} (current)` });
            } else {
                episodes.push({ id: i, value: i });
            }
        }
        return episodes;
    };

    return (
        <PageTemplate
            header="Edit League"
            headerSecondary={league ? league.name : ""}
            loading={leagueRequest.loading}
            skeleton={<ArticleTextSkeleton size={3} />}
        >
            <>
                {!leagueRequest.loading && show && league && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setFieldError }) => {
                            if (
                                league.draft_type !== "Team" ||
                                (validGenderLimits(
                                    values.maleLimit,
                                    values.femaleLimit,
                                    values.playersPerTeam
                                ) &&
                                    validRookieLimit(
                                        values.rookieLimit,
                                        values.playersPerTeam
                                    ))
                            ) {
                                setFieldError("maleLimit", undefined);
                                setFieldError("femaleLimit", undefined);
                                setFieldError("rookieLimit", undefined);
                                const headers = {
                                    "content-type": "multipart/form-data",
                                };
                                const data = new FormData();
                                data.append("show", values.show);
                                data.append("leagueName", values.leagueName);
                                if (values.episodeStarted)
                                    data.append(
                                        "episodeStarted",
                                        values.episodeStarted.toString()
                                    );
                                data.append("draftType", values.draftType);
                                data.append("leagueType", values.leagueType);
                                data.append("img", values.img);
                                data.append("leagueSize", values.leagueSize);
                                data.append(
                                    "allowTrading",
                                    values.allowTrading.toString()
                                );
                                if (values.playersPerWeek)
                                    data.append(
                                        "playersPerWeek",
                                        values.playersPerWeek.toString()
                                    );
                                data.append("playerLimit", values.playerLimit);
                                data.append("rookieLimit", values.rookieLimit);
                                data.append("maleLimit", values.maleLimit);
                                data.append("femaleLimit", values.femaleLimit);
                                data.append("numPicks", values.numPicks);
                                if (values.playersPerTeam)
                                    data.append(
                                        "playersPerTeam",
                                        values.playersPerTeam.toString()
                                    );
                                if (values.startersPerTeam)
                                    data.append(
                                        "startersPerTeam",
                                        values.startersPerTeam.toString()
                                    );
                                if (values.instagram)
                                    data.append("instagram", values.instagram);
                                if (values.twitter)
                                    data.append("twitter", values.twitter);
                                if (values.tiktok)
                                    data.append("tiktok", values.tiktok);
                                if (values.podcast)
                                    data.append("podcast", values.podcast);
                                data.append(
                                    "scores",
                                    JSON.stringify(values.scores)
                                );
                                if (values.swaps)
                                    data.append(
                                        "swaps",
                                        values.swaps.toString()
                                    );
                                if (values.lastSwapEp)
                                    data.append(
                                        "lastSwapEp",
                                        values.lastSwapEp.toString()
                                    );
                                data.append(
                                    "includeQuestions",
                                    values.includeQuestions.toString()
                                );
                                data.append(
                                    "questions",
                                    values.includeQuestions
                                        ? JSON.stringify(values.questions)
                                        : JSON.stringify([])
                                );
                                saveLeagueRequest.request(data, headers);
                                setSubmitting(true);
                            } else {
                                if (
                                    league.draft_type === "Team" &&
                                    !validGenderLimits(
                                        values.maleLimit,
                                        values.femaleLimit,
                                        values.playersPerTeam
                                    )
                                ) {
                                    setFieldError(
                                        "maleLimit",
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                    setFieldError(
                                        "femaleLimit",
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                    toast.error(
                                        "Male Limit and Female Limit cannot combine to be more than Players Per Team"
                                    );
                                }
                                if (
                                    league.draft_type === "Team" &&
                                    league.rookie_limit &&
                                    !validRookieLimit(
                                        values.rookieLimit,
                                        values.playersPerTeam
                                    )
                                ) {
                                    setFieldError(
                                        "rookieLimit",
                                        "Rookie Limit cannot be more than Players Per Team"
                                    );
                                    toast.error(
                                        "Rookie Limit cannot be more than Players Per Team"
                                    );
                                }
                            }
                        }}
                    >
                        {({ values, handleSubmit, isValid, setFieldValue }) => (
                            <>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!isValid) {
                                            toast.error(
                                                "Please fix the errors"
                                            );
                                        }
                                        if (
                                            JSON.stringify(values.scores) !==
                                                JSON.stringify(scoring) ||
                                            JSON.stringify(questionScoring) !==
                                                JSON.stringify(values.questions)
                                        ) {
                                            setScoreChangeVisible(true);
                                        } else if (
                                            league.draft_type === "Team" &&
                                            values.startersPerTeam &&
                                            values.startersPerTeam !==
                                                league.starters_per_week
                                        ) {
                                            setStartersVisible(true);
                                        } else if (
                                            league.draft_type ===
                                                "Weekly Redraft" &&
                                            values.playersPerWeek &&
                                            values.playersPerWeek !==
                                                league.players_per_team
                                        ) {
                                            setWeeklyVisible(true);
                                        } else {
                                            handleSubmit(e);
                                        }
                                    }}
                                >
                                    <SectionHeader>General</SectionHeader>
                                    <div className="mb-5">
                                        <Input
                                            name="leagueName"
                                            label="League Name:"
                                            placeholder="League Name"
                                        />
                                        {values.leagueType === "Sponsored" && (
                                            <SponsoredLeagueForm
                                                values={values}
                                                setFieldValue={setFieldValue}
                                            />
                                        )}
                                        {!league.sponsored && (
                                            <RadioButtons
                                                buttons={["Private", "Public"]}
                                                name="leagueType"
                                                label="League Type"
                                                value={values.leagueType}
                                                setFieldValue={setFieldValue}
                                                formText={
                                                    values.leagueType ===
                                                    "Public"
                                                        ? strings.createLeague
                                                              .public
                                                        : strings.createLeague
                                                              .private
                                                }
                                            />
                                        )}
                                        {(values.leagueType === "Public" ||
                                            values.leagueType ===
                                                "Sponsored") && (
                                            <Input
                                                label="League Size"
                                                placeholder="League Size"
                                                name={`leagueSize`}
                                                type="number"
                                                formText={
                                                    strings.createLeague
                                                        .leagueSize
                                                }
                                            />
                                        )}
                                        <Select
                                            name="episodeStarted"
                                            label={
                                                show.type === "week"
                                                    ? `Week Started`
                                                    : "Episode Started"
                                            }
                                            items={episodeRange(
                                                1,
                                                show.episodes
                                            )}
                                            selectKey="id"
                                            selectValue="value"
                                        />
                                        {values.draftType === "Full Draft" && (
                                            <LeagueFullDraftForm
                                                drafted={league.drafted}
                                                castCount={castCount}
                                                numPicksChange={(value) => {
                                                    setFieldValue(
                                                        "numPicks",
                                                        value
                                                    );
                                                }}
                                                numPick={values.numPicks}
                                            />
                                        )}
                                        {(values.draftType ===
                                            "Weekly Redraft" ||
                                            values.draftType ===
                                                "Weekly Budget") && (
                                            <LeagueWeeklyRedraftForm
                                                showLimits={showLimits}
                                                budget={
                                                    values.draftType ===
                                                    "Weekly Budget"
                                                }
                                            />
                                        )}
                                        {values.draftType === "Team" && (
                                            <LeagueTeamForm
                                                showLimits={showLimits}
                                                edit={true}
                                                show={show}
                                            />
                                        )}
                                        {show && show.questions.length > 0 && (
                                            <Switch
                                                name="includeQuestions"
                                                label="Include Challenge Questions?"
                                                formText={
                                                    strings.createLeague
                                                        .challengeQuestions
                                                }
                                            />
                                        )}
                                        {show &&
                                            show.questions.length > 0 &&
                                            values.includeQuestions && (
                                                <QuestionsForm
                                                    questions={values.questions}
                                                    show={show.show}
                                                />
                                            )}
                                    </div>
                                    {show &&
                                        league.draft_type !==
                                            "Confidence Pool" &&
                                        league.draft_type !==
                                            "Eliminated Confidence Pool" && (
                                            <ScoringForm
                                                scores={values.scores}
                                                show={values.show}
                                                edit
                                            />
                                        )}
                                    <div className="mt-4 text-center">
                                        <SubmitButton
                                            className="mr-2 mb-2"
                                            title="Update League"
                                            submitText="Updating"
                                            submitting={submitting}
                                            disabled={deleting}
                                        />
                                        <Button
                                            className="mr-2 mb-2"
                                            onClick={() => setShowDelete(true)}
                                            variant="danger"
                                            disabled={submitting || deleting}
                                        >
                                            Delete League
                                        </Button>
                                        <Button
                                            className="mb-2"
                                            onClick={() =>
                                                history.push(
                                                    `/myleagues/view/${
                                                        league.id
                                                    }${
                                                        league.id === "0" ||
                                                        league.id === 0 ||
                                                        league.id === "team" ||
                                                        league.id === "budget"
                                                            ? `/${league.show_id}`
                                                            : ""
                                                    }`
                                                )
                                            }
                                            variant="light"
                                            disabled={submitting}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                                <AreYouSureModal
                                    title="Update Scoring Settings"
                                    submitting={submitting}
                                    submitText={"Saving"}
                                    showModal={scoreChangeVisible}
                                    body={`Adjusting the scoring settings will recalculate all previous and upcoming scores in your league. Do you want to continue?`}
                                    setShowModal={setScoreChangeVisible}
                                    onSuccess={handleSubmit}
                                    primaryButton="Save"
                                />
                                <AreYouSureModal
                                    title="Update Starters Per Team"
                                    submitting={submitting}
                                    submitText={"Saving"}
                                    showModal={startersVisible}
                                    body={`You're adjusting starters per week from ${
                                        league.starters_per_week
                                    } to ${values.startersPerTeam}. ${
                                        values.startersPerTeam <
                                        league.starters_per_week
                                            ? `This will move ${
                                                  league.starters_per_week -
                                                  values.startersPerTeam
                                              } starter(s) from each team in your league to their bench.`
                                            : ``
                                    }${
                                        values.startersPerTeam >
                                        league.starters_per_week
                                            ? `This will move ${
                                                  values.startersPerTeam -
                                                  league.starters_per_week
                                              } bench player(s) from each team in your league to starting spots.`
                                            : ``
                                    } Please inform your league members to check their lineups. Do you want to continue?`}
                                    setShowModal={setStartersVisible}
                                    onSuccess={handleSubmit}
                                    primaryButton="Save"
                                />
                                <AreYouSureModal
                                    title="Update Players Per Week"
                                    submitting={submitting}
                                    submitText={"Saving"}
                                    showModal={weeklyVisible}
                                    body={`You're adjusting players per week from ${
                                        league.players_per_team
                                    } to ${values.playersPerWeek}. ${
                                        values.playersPerWeek <
                                        league.players_per_team
                                            ? `This will remove ${
                                                  league.players_per_team -
                                                  values.playersPerWeek
                                              } contestant(s) from each team's selection in your league this week.`
                                            : ``
                                    }${
                                        values.playersPerWeek >
                                        league.players_per_team
                                            ? `This will add ${
                                                  values.playersPerWeek -
                                                  league.players_per_team
                                              } blank spot(s) to each team's selections in your league this week.`
                                            : ``
                                    } Please inform your league members to check their lineups. Do you want to continue?`}
                                    setShowModal={setWeeklyVisible}
                                    onSuccess={handleSubmit}
                                    primaryButton="Save"
                                />
                            </>
                        )}
                    </Formik>
                )}
                <AreYouSureModal
                    showModal={showDelete}
                    setShowModal={setShowDelete}
                    body={`Are you sure you want to delete this league? This cannot be undone!`}
                    onSuccess={() => {
                        setDeleting(true);
                        deleteLeagueRequest.request();
                    }}
                    submitText="Deleting"
                    submitting={deleting}
                />
            </>
        </PageTemplate>
    );
};

export default EditLeagueForm;
