import { Avatar } from "@mui/material";
import React from "react";
import { useTheme } from "styled-components";

import { Col, Row } from "react-bootstrap";

import { imageUrl } from "../../config";
import { Cast } from "../../models/Cast";

import { ErrorText, Strikethrough } from "../atoms";
import CastTeam from "./CastTeam";

interface AnalyticsListItemProps {
    player: Cast;
    rightText: string;
    currentEpisode: number;
}

const AnalyticsListItem: React.FC<AnalyticsListItemProps> = ({
    player,
    currentEpisode,
    rightText,
}) => {
    const theme = useTheme();

    return (
        <Row className="p-3 align-items-center text-center">
            <Col className="pl-4">
                <Row className="align-items-center justify-content-center flex-column">
                    <div>
                        <Avatar
                            style={{
                                height: 60,
                                width: 60,
                            }}
                            src={`${imageUrl}${player.pic}`}
                        />
                    </div>
                    <div className="pl-2">
                        {player.episode_eliminated !== null &&
                        player.episode_eliminated < currentEpisode ? (
                            <ErrorText>
                                <Strikethrough>{`${
                                    player.first_name && player.first_name
                                } ${
                                    player.last_name && player.last_name
                                }`}</Strikethrough>{" "}
                                (Eliminated)
                            </ErrorText>
                        ) : (
                            <span
                                style={{
                                    color: theme.textColor,
                                }}
                            >{`${player.first_name && player.first_name} ${
                                player.last_name && player.last_name
                            }`}</span>
                        )}
                        <CastTeam
                            size={10}
                            team={player.team}
                            partner={player.partner}
                        />
                    </div>
                </Row>
            </Col>
            <Col className="pl-5">
                <b>{rightText}</b>
            </Col>
        </Row>
    );
};

export default AnalyticsListItem;
