import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import PageTemplate from "../templates/PageTemplate";
import Input from "../atoms/Input";
import TextArea from "../atoms/TextArea";
import SubmitButton from "../atoms/SubmitButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
    message: yup.string().required("Message is required"),
});

interface Values {
    name: string;
    email: string;
    message: string;
}

const ContactUsForm: React.FC = () => {
    const formikRef = useRef<any>(); //don't like this, but it will have to do
    const [submitting, setSubmitting] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.user);
    const contact = useApi(apiRoutes.CONTACT(), {
        onSuccess: onContact,
        onFailure: () => setSubmitting(false),
        responseKey: "message",
    });

    const initialValues: Values = {
        name: "",
        email: "",
        message: "",
    };

    function onContact(message: string) {
        toast.success(message);
        formikRef.current?.resetForm();
        setSubmitting(false);
    }

    return (
        <PageTemplate header="Contact Us" headerSecondary="RealTVFantasy">
            <h3>Is there something you would like? Send us a message!</h3>
            <Alert variant="warning">
                If you're having trouble with the contact form, send an email
                directly to team@realtvfantasy.com
            </Alert>
            <Formik
                initialValues={initialValues}
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSubmitting(true);
                    contact.request({ ...values, userCode: user.user_code });
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    submitCount,
                    errors,
                    isValid,
                }) => (
                    <Form
                        onSubmit={(e) => {
                            handleSubmit(e);
                            if (!isValid) {
                                toast.error("Please fix the errors");
                            }
                        }}
                    >
                        <Input
                            name="name"
                            label="Full Name:"
                            placeholder="Full Name"
                        />
                        <Input
                            name="email"
                            label="Email Address:"
                            placeholder="Email Address"
                            type="email"
                        />
                        <TextArea
                            name="message"
                            label="Message:"
                            placeholder="Message"
                            rows={10}
                        />
                        <div className="text-center">
                            <SubmitButton
                                title="Send Message"
                                submitText="Sending"
                                submitting={submitting}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default ContactUsForm;
