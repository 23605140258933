import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { ModalStyled } from "../atoms/index";
import { ListItem } from "./styles/DraftOrderModal.styled";

interface DraftOrderModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    onSuccess: (leagueUsers: LeaguesLeagueUser[]) => void;
    league: League;
}

const DraftOrderModal: React.FC<DraftOrderModalProps> = ({
    showModal,
    setShowModal,
    onSuccess,
    league,
}) => {
    const [leagueUsers, setLeagueUsers] = useState<LeaguesLeagueUser[]>([]);

    const leagueUsersRequest = useApi(
        apiRoutes.GET_LEAGUE_USERS_BY_LEAGUE(league.id.toString()),
        {
            responseKey: "leagueUsers",
            onSuccess: (response: LeaguesLeagueUser[]) => {
                setLeagueUsers(response);
            },
        }
    );

    useEffect(() => {
        if (showModal) {
            leagueUsersRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    const randomOrder = () => {
        let temp = leagueUsers.slice();

        for (let i = temp.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [temp[i], temp[j]] = [temp[j], temp[i]];
        }
        setLeagueUsers(temp);
    };

    const SortableList = SortableContainer(
        ({ items }: { items: LeaguesLeagueUser[] }) => {
            return (
                <div>
                    {items.map((leagueUser, index) => (
                        <SortableItem
                            key={leagueUser.id}
                            index={index}
                            teamName={leagueUser.team_name}
                            name={`${
                                leagueUser.first_name && leagueUser.first_name
                            } ${leagueUser.last_name && leagueUser.last_name}`}
                        />
                    ))}
                </div>
            );
        }
    );

    const SortableItem = SortableElement(
        ({ teamName, name }: { name: string; teamName: string }) => (
            <ListItem tabIndex={0}>
                {teamName} <small>{name}</small>
            </ListItem>
        )
    );

    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const items = arrayMove(leagueUsers, oldIndex, newIndex);
        setLeagueUsers(items);
    };

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Choose Draft Order (Drag and Drop)</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3">
                    {leagueUsers && leagueUsers.length > 0 && (
                        <SortableList
                            helperClass="sortableHelper"
                            items={leagueUsers}
                            onSortEnd={onSortEnd}
                        />
                    )}
                    <div className="mt-3">
                        <Button variant="primary" onClick={randomOrder}>
                            Generate Random Order
                        </Button>{" "}
                        <Button
                            variant="primary"
                            onClick={() => onSuccess(leagueUsers)}
                        >
                            Set Order
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default DraftOrderModal;
