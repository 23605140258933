import { ApiReturnType } from "./";

const leagueUserRoutes = {
    ADD_CO_OWNER: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/addCoOwner/${id}`,
            method: "post",
        };
    },
    CHECK_SET_LINEUP: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/checkSetLineup/${id}`,
            method: "get",
        };
    },
    DELETE_CO_OWNER: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/deleteCoOwner/${id}`,
            method: "post",
        };
    },
    GET_LEAGUE_USER: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/get/${id}`,
            method: "get",
        };
    },
    GET_LEAGUE_USERS_BY_LEAGUE: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/byLeague/${leagueId}`,
            method: "get",
        };
    },
    GET_LEAGUE_USERS_BY_SHOW: (showId: number): ApiReturnType => {
        return {
            url: `v2/leagueUsers/byShow/${showId}`,
            method: "get",
        };
    },
    LEAVE_LEAGUE: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/leaveLeague/${id}`,
            method: "post",
        };
    },
    SAVE_LEAGUE_USER: (id: string): ApiReturnType => {
        return {
            url: `v2/leagueUsers/save/${id}`,
            method: "post",
        };
    },
};

export default leagueUserRoutes;
