import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { CastTeam } from "../../models/Cast";

import Input from "../atoms/Input";

interface TeamInfoFormProps {
    index: number;
    team: CastTeam;
}

const TeamsInfoForm: React.FC<TeamInfoFormProps> = ({ index, team }) => {
    return (
        <Row className="mb-2" style={{ alignItems: "center" }} key={team.id}>
            <Col xs={6} md={6}>
                <Input
                    name={`teams.${index}.team_name`}
                    label="Team Name:"
                    placeholder="Team Name"
                />
            </Col>
            <Col xs={6} md={6}>
                <Input
                    name={`teams.${index}.color`}
                    label="Color:"
                    placeholder="Color"
                />
            </Col>
        </Row>
    );
};

export default TeamsInfoForm;
