import React from "react";

import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import { Cast, CastTeam as CastTeamModel } from "../../models/Cast";

import CastImage from "./CastImage";
import CastTeam from "../molecules/CastTeam";

interface LiveCastRowProps {
    name: string;
    total: number;
    pic?: string;
    team?: CastTeamModel;
    partner?: Cast;
}

const LiveCastRow: React.FC<LiveCastRowProps> = ({
    name,
    total,
    pic,
    team,
    partner,
}) => {
    return (
        <Row className="align-items-center ml-1 mb-3 mt-3">
            <Col xs="10">
                <Row className="align-items-center">
                    <CastImage src={pic} />
                    <div className="ml-2">
                        <div>{name}</div>
                        <CastTeam
                            size={10}
                            left
                            team={team}
                            partner={partner}
                        />
                    </div>
                </Row>
            </Col>
            <Col xs="2">
                <div className="font-weight-bold text-right mr-2">{total}</div>
            </Col>
        </Row>
    );
};

export default React.memo(LiveCastRow);
