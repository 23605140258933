import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";

import useQuery from "../../hooks/useQuery";

import PageTemplate from "../templates/PageTemplate";

const HowItWorks: React.FC = () => {
    const query = useQuery();
    const type = query.get("type") ? query.get("type") : "site";

    return (
        <PageTemplate header="How It Works">
            <Col md={12}>
                <div>
                    <h3 className="mt-3 font-weight-bold">League Types</h3>
                    <div>
                        <b>Private:</b>
                        <br />
                        <ul>
                            <li>Only invited users can join.</li>
                            <li>
                                Members join using a unique league code assigned
                                after creation.
                            </li>
                        </ul>
                        <b>Public:</b>
                        <br />
                        <ul>
                            <li>Open for anyone to join.</li>
                            <li>
                                A league size limit can be set to restrict the
                                number of participants.
                            </li>
                        </ul>
                        <b>Sponsored:</b>
                        <br />
                        <ul>
                            <li>Customize with a unique league image.</li>
                            <li>
                                Featured at the top of search results for public
                                leagues.
                            </li>
                            <li>
                                Add social links to promote a podcast or social
                                media.
                            </li>
                            <li>Open for anyone to join.</li>
                            <li>
                                A league size limit can be set to restrict the
                                number of participants.
                            </li>
                        </ul>
                    </div>
                    <h3 className="mt-5 font-weight-bold">Draft Types</h3>
                    <div>
                        <b>Weekly Redraft League</b>:<br />
                        <ul>
                            <li>
                                Select contestants for your team each week (1-10
                                contestants).
                            </li>
                            <li>
                                Once the episode starts, lineups are locked.
                            </li>
                            <li>
                                Points are earned only for that week's selected
                                contestants.
                            </li>
                            <li>
                                Optional player limit (1-5) restricts how often
                                a contestant can be chosen throughout the
                                season. (Set to "No Limit" for unrestricted
                                selections.)
                            </li>
                        </ul>
                        <b>Full Draft League</b>:<br />
                        <ul>
                            <li>
                                League members draft contestants in a
                                snake-style draft at a scheduled time.
                            </li>
                            <li>
                                The draft ends when all contestants are picked
                                or when the commissioner finalizes it.
                            </li>
                            <li>
                                Points accumulate weekly based on drafted
                                contestants until they are eliminated.
                            </li>
                            <li>
                                Trades between teams are allowed (see{" "}
                                <Link to="/tradingguide">Trading Guide</Link>{" "}
                                for details).
                            </li>
                        </ul>
                        <b>Team League</b>:<br />
                        <ul>
                            <li>
                                Choose a team at the beginning of the season.
                            </li>
                            <li>
                                Each week, set a starting lineup from your
                                selected team.
                            </li>
                            <li>
                                The number of contestants per team (1-10) and
                                weekly starters (1-10) is customizable.
                            </li>
                            <li>
                                Multiple users can have the same contestants,
                                but scores vary based on weekly lineup choices.
                            </li>
                            <li>
                                Lineups lock when the episode airs, and points
                                are based on that week's starters.
                            </li>
                            <li>
                                Optional rookie and gender limits can enforce
                                minimum selections for rookies or male/female
                                contestants.
                            </li>
                            <li>
                                (Optional) Allow team swaps if contestants are
                                eliminated, with a customizable limit and cutoff
                                episode.
                            </li>
                        </ul>
                        <b>Weekly Budget League</b>
                        :<br />
                        <ul>
                            <li>
                                Receive $100 (fake currency) each week to draft
                                contestants.
                            </li>
                            <li>
                                Contestants are priced based on their fantasy
                                performance.
                            </li>
                            <li>
                                Lineups lock when the episode airs, and points
                                are based on that week's selections.
                            </li>
                        </ul>
                        <b>Confidence Pool League</b>
                        :<br />
                        <ul>
                            <li>
                                Rank contestants weekly based on confidence they
                                will survive.
                            </li>
                            <li>
                                The higher-ranked surviving contestants earn
                                more points.
                            </li>
                            <li>Lineups lock when the episode airs.</li>
                        </ul>
                        <b>Eliminated Confidence Pool League</b>
                        :<br />
                        <ul>
                            <li>
                                Rank contestants weekly based on confidence they
                                will survive.
                            </li>
                            <li>
                                Points are awarded based on correctly predicting
                                eliminations—the lower you rank an eliminated
                                contestant, the higher the score.
                            </li>
                            <li>Lineups lock when the episode airs.</li>
                        </ul>
                    </div>
                    {type === "app" ? (
                        <>
                            <h3 className="mt-5 font-weight-bold">
                                Creating a League
                            </h3>
                            <div>
                                To create a league, go to the "My Leagues" tab
                                and click "Create" in the top-left corner.
                                You'll be prompted to fill out a form with
                                details about your league, including:
                                <ul>
                                    <li>The show</li>
                                    <li>League name</li>
                                    <li>Your team name</li>
                                    <li>League type and settings</li>
                                    <li>
                                        Scoring categories and points values
                                        (which can be adjusted later)
                                    </li>
                                </ul>
                                Once you complete the form, your league will be
                                ready to go!
                            </div>
                            <h3 className="mt-5 font-weight-bold">
                                Joining & Inviting Friends
                            </h3>
                            <div>
                                After creating a league, you can invite friends
                                to join. Simply go to your league and click
                                "Invite Friends." A popup will display a league
                                code, which you can copy and share with others.
                                <br />
                                <br />
                                To join a league:
                                <ul>
                                    <li>
                                        Log in and go to the "My Leagues" tab.
                                    </li>
                                    <li>
                                        Click "Join" in the top-right corner.
                                    </li>
                                    <li>
                                        Enter the league code and select a team
                                        name.
                                    </li>
                                </ul>
                                Once you join, you'll all set to start playing!
                            </div>
                        </>
                    ) : (
                        <>
                            <h3 className="mt-5 font-weight-bold">
                                Creating a League
                            </h3>
                            <ol>
                                <li>Navigate to the top menu.</li>
                                <li>
                                    Select the show you want to create a league
                                    for.
                                </li>
                                <li>
                                    Click Create New League and complete the
                                    form, including:
                                </li>
                                <ul>
                                    <li>League name</li>
                                    <li>Your team name</li>
                                    <li>League type and settings</li>
                                    <li>
                                        Scoring categories and point values
                                        (modifiable later)
                                    </li>
                                </ul>

                                <li>
                                    Once submitted, your league is ready to go!
                                </li>
                            </ol>
                            <h3 className="mt-5 font-weight-bold">
                                Joining & Inviting Friends
                            </h3>
                            <ul>
                                <li>
                                    After creating a league, invite friends
                                    using the Invite Friends button.
                                </li>
                                <li>You will receive:</li>
                                <ul>
                                    <li>
                                        A direct invite link that guides friends
                                        through signup.
                                    </li>
                                    <li>
                                        A league code that friends can enter
                                        under My Leagues {">"} Join Private
                                        League to join.
                                    </li>
                                </ul>
                            </ul>
                        </>
                    )}
                    <h3 className="mt-5 font-weight-bold">
                        Drafting & Setting Lineup
                    </h3>
                    <div>
                        <ul>
                            <li>
                                <b>Weekly Redraft:</b>
                            </li>
                            <ul>
                                <li>
                                    Select 1-10 contestants each week via Set
                                    Lineup {">"} Save Lineup.
                                </li>
                                <li>
                                    Lineups lock lock when the episode airs.
                                </li>
                            </ul>
                            <li>
                                <b>Full Draft</b>
                            </li>
                            <ul>
                                <li>
                                    The commissioner starts the draft and sets
                                    the order.
                                </li>
                                <li>
                                    Snake-style picks continue until all
                                    contestants are selected or the commissioner
                                    finalizes it.
                                </li>
                                <li>
                                    Drafting is live, but refreshing the page
                                    may be needed for updates.
                                </li>
                            </ul>
                            <li>
                                <b>Team Select:</b>
                            </li>
                            <ul>
                                <li>
                                    Users pick an initial team upon joining.
                                </li>
                                <li>
                                    Weekly lineup adjustments are made via Set
                                    Team {">"} Save Team.
                                </li>
                                <li>Starters lock when the episode airs.</li>
                            </ul>
                            <li>
                                <b>Weekly Budget:</b>
                            </li>
                            <ul>
                                <li>
                                    Choose 2-5 contestants weekly within the
                                    $100 budget.
                                </li>
                                <li>Lineups lock when the episode airs.</li>
                            </ul>
                            <li>
                                <b>
                                    Confidence Pool & Eliminated Confidence
                                    Pool:
                                </b>
                            </li>
                            <ul>
                                <li>Rank contestants weekly via Make Picks.</li>
                                <li>Picks lock when the episode airs</li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </Col>
        </PageTemplate>
    );
};

export default HowItWorks;
