import { ApiReturnType } from "./";

const podcastRoutes = {
    GET_PODCASTS: (): ApiReturnType => {
        return {
            url: `v2/podcasts`,
            method: "get",
        };
    },
};

export default podcastRoutes;
