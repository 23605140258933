import React from "react";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Row } from "react-bootstrap";

import { imageUrl } from "../../config";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { ConfidenceCast } from "../../models/ConfidencePool";

import TableTeam from "../atoms/TableTeam";
import CastTeam from "./CastTeam";

interface ConfidenceListItemProps {
    player: ConfidenceCast;
    points: number;
}

const ConfidenceListItem: React.FC<ConfidenceListItemProps> = ({
    player,
    points,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <TableTeam style={{ cursor: "move" }}>
            <tbody>
                <tr>
                    <td className="pl-4">
                        <Row className="align-items-center">
                            <Avatar
                                style={{ height: 60, width: 60 }}
                                src={`${imageUrl}${player.pic}`}
                            />
                            <div className="pl-2">
                                <span
                                    style={{ color: theme.textColor }}
                                >{`${player.name}`}</span>
                                <CastTeam
                                    left
                                    size={10}
                                    team={
                                        player.cast
                                            ? player.cast.team
                                            : player.team
                                    }
                                    partner={
                                        player.cast
                                            ? player.cast.partner
                                            : player.partner
                                    }
                                />
                            </div>
                        </Row>
                        {subscription && hasPremiumSub(subscription.name) && (
                            <div
                                className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none"
                                style={{ fontSize: 14 }}
                            >
                                Average Rank:{" "}
                                <b>
                                    {parseFloat(player.average) > 0
                                        ? player.average
                                        : "-"}
                                </b>
                            </div>
                        )}
                    </td>
                    {subscription && hasPremiumSub(subscription.name) && (
                        <td
                            className="d-none d-md-table-cell"
                            style={{
                                color: theme.textColor,
                            }}
                        >
                            Average Rank:{" "}
                            <b>
                                {parseFloat(player.average) > 0
                                    ? player.average
                                    : "-"}
                            </b>
                        </td>
                    )}
                    <td className="text-right" style={{ width: 120 }}>
                        <Row
                            className="align-items-center"
                            style={{
                                justifyContent: "flex-end",
                                marginRight: 15,
                            }}
                        >
                            <span className="pr-3" style={{ fontSize: 20 }}>
                                {points}
                            </span>
                            <DragHandleIcon />
                        </Row>
                    </td>
                </tr>
            </tbody>
        </TableTeam>
    );
};

export default ConfidenceListItem;
