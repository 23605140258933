import React from "react";
import { Modal } from "react-bootstrap";

import { ModalStyled } from "../atoms";

interface LimitInfoProps {
    maleLimit: null | number;
    femaleLimit: null | number;
    rookieLimit: null | number;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const LimitInfo: React.FC<LimitInfoProps> = ({
    maleLimit,
    femaleLimit,
    rookieLimit,
    showModal,
    setShowModal,
}) => {
    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Team Limits</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    The commissioner of the league has set limitations on
                    selecting teams. Your team must meet the limitations below:
                </p>
                <div>
                    {maleLimit ? (
                        <p>
                            Your team must include {maleLimit} male
                            {maleLimit > 1 ? "s" : ""}
                        </p>
                    ) : null}
                    {femaleLimit ? (
                        <p>
                            Your team must include {femaleLimit} female
                            {femaleLimit > 1 ? "s" : ""}
                        </p>
                    ) : null}
                    {rookieLimit ? (
                        <p>
                            Your team must include {rookieLimit} rookie
                            {rookieLimit > 1 ? "s" : ""}
                        </p>
                    ) : null}
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default LimitInfo;
