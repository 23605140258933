import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Cast, DraftList as DL } from "../../models/Cast";
import { Activity } from "../../models/Activity";
// import { Chat } from "../../models/Chat";

import AreYouSureModal from "../atoms/AreYouSureModal";
import { Well } from "../atoms";
import { TableSkeleton, CardSkeleton } from "../atoms/Skeletons";
import DraftList from "../molecules/DraftList";
import DraftPlayerModal from "../molecules/DraftPlayerModal";
import DraftOrderModal from "../molecules/DraftOrderModal";
import DraftTeams from "../molecules/DraftTeams";
import LeagueChat from "../organisms/LeagueChat";
import DraftOrder from "../organisms/DraftOrder";
import PageTemplate from "../templates/PageTemplate";
import DraftHeader from "../molecules/DraftHeader";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import GoogleAd from "../atoms/GoogleAd";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import echo from "../../config/echo";
import { LeagueTeams } from "../../models/LeagueTeams";

interface ParamTypes {
    id: string;
}

const Draft: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [league, setLeague] = useState<League>();
    const [leagueUser, setLeagueUser] = useState<LeaguesLeagueUser>();
    const [draftList, setDraftList] = useState<DL>();
    const [startDraftOrder, setStartDraftOrder] =
        useState<LeaguesLeagueUser[]>();
    const [draftOrder, setDraftOrder] = useState<LeaguesLeagueUser[]>([]);
    const [draftee, setDraftee] = useState<LeaguesLeagueUser>();
    const [drafting, setDrafting] = useState<boolean>(false);
    const [selectedPlayer, setSelectedPlayer] = useState<Cast>();
    const [draftedPlayerId, setDraftedPlayerId] = useState<number>(0);
    const [showDraftPlayerModal, setShowDraftPlayerModal] =
        useState<boolean>(false);
    const [showDraftOrderModal, setShowDraftOrderModal] =
        useState<boolean>(false);
    const [showAysModal, setShowAysModal] = useState<boolean>(false);
    const [showAysReset, setShowAysReset] = useState<boolean>(false);
    const [showAysFinish, setShowAysFinish] = useState<boolean>(false);
    const [sort, setSort] = useState<string>("name");
    const [reload, setReload] = useState<boolean>(false);
    const [draftingRandom, setDraftingRandom] = useState<boolean>(false);
    const [showDraftRandom, setShowDraftRandom] = useState<boolean>(false);
    const [recentDrafted, setRecentDrafted] = useState<LeagueTeams>();

    const draftOrderRequest = useApi(apiRoutes.DRAFT_ORDER(id), {
        onSuccess: (response: {
            draftee: LeaguesLeagueUser;
            order: LeaguesLeagueUser[];
        }) => {
            setDraftee(response.draftee);
            setDraftOrder(response.order);
            setLoading(false);
        },
    });
    const draftListRequest = useApi(apiRoutes.GET_DRAFT_LIST(id), {
        onSuccess: (response: {
            list: DL;
            league: League;
            leagueUser: LeaguesLeagueUser;
            draftee: LeaguesLeagueUser;
            order: LeaguesLeagueUser[];
        }) => {
            if (response.league.drafted) {
                window.location.href = `/myleagues/view/${response.league.id}`;
            }
            setLeague(response.league);
            setDraftList([...response.list]);
            setLeagueUser(response.leagueUser);
            setDraftee(response.draftee);
            setDraftOrder(response.order);
            setLoaded(true);
            setDrafting(false);
            setLoading(false);
        },
    });
    const draftPlayerRequest = useApi(apiRoutes.DRAFT_PLAYER(id), {
        onSuccess: (response: {
            message: string;
            player: Cast;
            log: Activity;
        }) => {
            setShowDraftPlayerModal(false);
            setDraftedPlayerId(response.player.id);
            setDraftingRandom(false);
            setShowDraftRandom(false);
            draftListRequest.request({
                league_user_id: leagueUser.id,
                sort: sort,
            });
        },
        onFailure: () => {
            setDrafting(false);
            setDraftingRandom(false);
            setShowDraftRandom(false);
        },
    });
    const startDraftRequest = useApi(apiRoutes.START_DRAFT(id), {
        onSuccess: (response: { message: string; log: Activity }) => {
            setLeague({ ...league, draft_started: true });
            draftOrderRequest.request();
        },
    });
    const resetDraftRequest = useApi(apiRoutes.RESET_DRAFT(id), {
        onSuccess: (response: { message: string; log: Activity }) => {
            setLoaded(false);
            setDraftOrder([]);
            setLeague({ ...league, draft_started: false });
            draftListRequest.request({ sort: sort });
        },
    });
    const finishDraftRequest = useApi(apiRoutes.FINISH_DRAFT(id), {
        responseKey: "message",
        onSuccess: (response: string) => {
            history.push(`/myleagues/view/${league.id}`);
        },
    });

    const finishDraft = () => {
        finishDraftRequest.request();
    };

    useEffect(() => {
        draftListRequest.request({ sort: sort });

        //could add this back in whenever I feel like I can fit it in pusher limits
        const leagueTeamChannel = echo.channel(`draft.${id}`);

        //register socket events
        leagueTeamChannel.stopListening(".LeagueTeamDrafted");
        leagueTeamChannel.listen(
            ".LeagueTeamDrafted",
            (data: { leagueTeam: LeagueTeams; league: League }) => {
                setRecentDrafted(data.leagueTeam);
            }
        );

        return () => {
            leagueTeamChannel.stopListening(".LeagueChatCreated");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (reload) {
            setReload(false);
            setLoaded(false);
            draftListRequest.request({
                league_user_id: leagueUser.id,
                sort: sort,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, reload]);

    const onTeamDrafted = (leagueTeam: LeagueTeams) => {
        setShowDraftPlayerModal(false);

        //remove that player from wishlist and list
        let newWishlist: Cast[] = [];
        let newList: Cast[] = [];
        const wishlist = draftList?.filter((wl) => {
            return wl.title === "Wishlist";
        })[0];
        const list = draftList?.filter((l) => {
            return l.title === "Players";
        })[0];
        if (wishlist && wishlist.data.length > 0) {
            newWishlist = wishlist.data.filter((nwl) => {
                return nwl.id !== leagueTeam.cast_id;
            });
        }
        if (list && list.data.length > 0) {
            newList = list.data.filter((nl) => {
                return nl.id !== leagueTeam.cast_id;
            });
        }

        //if this person was in the list before, update. If not, you don't need to update
        if (
            newWishlist.length !== wishlist?.data.length ||
            newList.length !== list?.data.length
        ) {
            //adjust the draft order and set new draftee
            if (draftOrder) {
                const newDraftOrder = draftOrder.slice(1);
                setDraftOrder(newDraftOrder);
                if (newDraftOrder.length > 0) {
                    setDraftee(newDraftOrder[0]);
                } else {
                    setDraftee(undefined);
                }
            }
        }

        setDraftList([
            { title: "Wishlist", data: newWishlist },
            { title: "Players", data: newList },
        ]);
        setDrafting(false);
        setShowDraftRandom(false);
    };

    useEffect(() => {
        if (recentDrafted) {
            onTeamDrafted(recentDrafted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentDrafted]);

    const draftPlayer = () => {
        setDrafting(true);
        const draftedPlayer = selectedPlayer;
        if (draftedPlayer) {
            draftPlayerRequest.request({ player_id: draftedPlayer.id });
        } else {
            toast.error("There was an issue drafting player");
        }
    };

    return (
        <PageTemplate
            header="Live Draft"
            headerSecondary={league ? league.name : ""}
            headerButtons={
                league &&
                leagueUser && (
                    <DraftHeader
                        league={league}
                        leagueUser={leagueUser}
                        leagueRequest={draftListRequest}
                        setShowDraftOrderModal={setShowDraftOrderModal}
                        setShowAysFinish={setShowAysFinish}
                        setShowAysReset={setShowAysReset}
                    />
                )
            }
            skeleton={
                <>
                    <Row>
                        <Col lg={8}>
                            <Well>
                                <TableSkeleton size={3} />
                            </Well>
                        </Col>
                        <Col lg={4}>
                            <CardSkeleton />
                        </Col>
                    </Row>
                </>
            }
            loading={!loaded || loading}
        >
            {loaded && (
                <>
                    {leagueUser &&
                        !leagueUser.commissioner &&
                        !league.draft_started && (
                            <Alert variant="warning">
                                Draft Has Not Started Yet. Contact Your
                                Commissioner To Start The Draft.
                            </Alert>
                        )}
                    {leagueUser &&
                        league.draft_note &&
                        league.draft_note.length > 0 && (
                            <Alert
                                style={{ fontSize: 16 }}
                                className="mt-3"
                                variant="warning"
                            >
                                <b>Draft Note:</b> {league.draft_note}
                            </Alert>
                        )}
                    <GoogleAd slot="7237766115" height={90} />
                    <DraftOrder order={draftOrder} />
                    {draftee &&
                    draftee.id !== leagueUser.id &&
                    leagueUser.commissioner &&
                    league.draft_started &&
                    !loading ? (
                        <Row className="mt-2 mb-2 justify-content-center">
                            <Button onClick={() => setShowDraftRandom(true)}>
                                Make pick for {draftee.team_name}
                            </Button>
                        </Row>
                    ) : (
                        <></>
                    )}
                    <Row>
                        <Col className="order-last order-md-first" md={8}>
                            <Well>
                                {subscription &&
                                    hasPremiumSub(subscription.name) && (
                                        <AnalyticsSelect
                                            secondary="Avg Draft Position"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                let newCast;
                                                if (value === "secondary") {
                                                    newCast = draftList.map(
                                                        (dl) => {
                                                            return {
                                                                ...dl,
                                                                data: dl.data.sort(
                                                                    (a, b) =>
                                                                        parseFloat(
                                                                            b.average_draft_position
                                                                        ) <
                                                                        parseFloat(
                                                                            a.average_draft_position
                                                                        )
                                                                            ? 1
                                                                            : -1
                                                                ),
                                                            };
                                                        }
                                                    );
                                                } else {
                                                    newCast = draftList.map(
                                                        (dl) => {
                                                            return {
                                                                ...dl,
                                                                data: dl.data.sort(
                                                                    (a, b) =>
                                                                        b.name <
                                                                        a.name
                                                                            ? 1
                                                                            : -1
                                                                ),
                                                            };
                                                        }
                                                    );
                                                }
                                                setDraftList([...newCast]);
                                            }}
                                        />
                                    )}
                                <DraftList
                                    noPicksLeft={
                                        league.draft_started &&
                                        draftOrder.length === 0 &&
                                        league.num_picks !== null
                                    }
                                    disabled={drafting || loading}
                                    draftList={draftList}
                                    league={league}
                                    leagueUser={leagueUser}
                                    draftee={draftee}
                                    finishDraft={finishDraft}
                                    setSelectedPlayer={setSelectedPlayer}
                                    setShowDraftPlayerModal={
                                        setShowDraftPlayerModal
                                    }
                                />
                            </Well>
                        </Col>
                        <Col md={4}>
                            <DraftTeams
                                selectedPlayerId={draftedPlayerId}
                                leagueUser={leagueUser}
                                id={id}
                            />
                            <GoogleAd slot="2424423133" height={280} />
                        </Col>
                    </Row>
                    <DraftOrderModal
                        showModal={showDraftOrderModal}
                        setShowModal={setShowDraftOrderModal}
                        onSuccess={(response: LeaguesLeagueUser[]) => {
                            setShowDraftOrderModal(false);
                            setShowAysModal(true);
                            setStartDraftOrder(response);
                        }}
                        league={league}
                    />
                    <AreYouSureModal
                        showModal={showAysModal}
                        setShowModal={setShowAysModal}
                        body={`Are you sure you want to start the draft?`}
                        primaryButton="Start Draft"
                        onSuccess={() => {
                            setShowAysModal(false);
                            setLoading(true);
                            startDraftRequest.request({
                                league_users: startDraftOrder,
                            });
                        }}
                    />
                    <DraftPlayerModal
                        showModal={showDraftPlayerModal}
                        setShowModal={setShowDraftPlayerModal}
                        player={selectedPlayer}
                        draftPlayer={draftPlayer}
                        drafting={drafting}
                    />
                    <AreYouSureModal
                        title="Reset Draft"
                        showModal={showAysReset}
                        setShowModal={setShowAysReset}
                        body={`Are you sure you want to reset the draft? This will remove all the already drafted players from their teams.`}
                        onSuccess={() => {
                            setShowAysReset(false);
                            resetDraftRequest.request();
                        }}
                    />
                    <AreYouSureModal
                        title="Finish Draft"
                        showModal={showAysFinish}
                        setShowModal={setShowAysFinish}
                        body={`Are you sure you want to finish the draft? All contestants not drafted won't be on a team, but you can add these contestants to teams any time you want.`}
                        onSuccess={() => {
                            setShowAysFinish(false);
                            finishDraft();
                        }}
                    />
                    <AreYouSureModal
                        title={`Draft for ${draftee?.team_name}`}
                        showModal={showDraftRandom}
                        setShowModal={setShowDraftRandom}
                        submitText="Drafting"
                        submitting={draftingRandom}
                        body={`Are you sure you want to draft for ${draftee?.team_name}? This will draft a random contestant for them.`}
                        onSuccess={() => {
                            setDraftingRandom(true);
                            draftPlayerRequest.request({
                                commissioner: true,
                                draft_user_id: draftee?.user_id,
                            });
                        }}
                    />
                    {leagueUser && (
                        <LeagueChat
                            reloadDraft={() => setReload(true)}
                            draft
                            leagueId={id}
                        />
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default Draft;
