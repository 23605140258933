import React from "react";

import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Col, Row } from "react-bootstrap";

interface StatsTableHeaderProps {
    league: League;
    categories: string[];
    leagueUser: LeaguesLeagueUser;
}

const StatsTableHeader: React.FC<StatsTableHeaderProps> = ({
    league,
    categories,
    leagueUser,
}) => {
    return (
        <div className="p-3">
            <Row className="header" style={{ fontWeight: "bold" }}>
                <Col className="text-center">Player</Col>
                {league.draft_type === "Full Draft" && leagueUser && (
                    <>
                        {league.drafted ? (
                            <Col className="text-center d-none d-md-table-cell">
                                Team
                            </Col>
                        ) : (
                            <Col className="text-center d-none d-md-table-cell">
                                Wishlist
                            </Col>
                        )}
                    </>
                )}
                {categories &&
                    categories.map((category: string) => {
                        return (
                            <Col
                                key={category}
                                className="text-center d-none d-md-table-cell"
                            >
                                {category}
                            </Col>
                        );
                    })}
                <Col className="text-center">Total</Col>
            </Row>
        </div>
    );
};

export default StatsTableHeader;
