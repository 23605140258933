import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import LinkList from "../molecules/LinkList";
import PageTemplate from "../templates/PageTemplate";

const AdminLiveScoresList: React.FC = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const getScoresList = useApi(apiRoutes.GET_LIVE_SCORES_LIST(), {
        responseKey: "list",
        onSuccess: (response) => {
            setLoading(false);
            setList(response);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        getScoresList.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageTemplate
            header="Manage Live Scores"
            loading={loading}
            skeleton={<TableSkeleton size={2} />}
        >
            {list.length > 0 &&
                list.map((item) => {
                    return (
                        <div className="mb-4" key={item.id}>
                            <h4 className="text-capitalize">{item.title}:</h4>
                            {item.episodes.length > 0 ? (
                                <LinkList
                                    list={item.episodes}
                                    buttonTitle="Log More Live Scores"
                                    buttonLink={`/admin/liveScores/${item.id}`}
                                />
                            ) : (
                                <>
                                    <span>
                                        No live scores logged for {item.title}
                                    </span>
                                    <br />
                                    <Button
                                        className="mt-2"
                                        href={`/admin/liveScores/${item.id}`}
                                    >
                                        Log More Live Scores
                                    </Button>
                                </>
                            )}
                        </div>
                    );
                })}
        </PageTemplate>
    );
};

export default AdminLiveScoresList;
