import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Formik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";
import { LeagueUser, Team, TeamResponse } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { ModalStyled } from "../atoms";
import { InputSkeleton } from "../atoms/Skeletons";
import Select from "../atoms/Select";
import AreYouSureModal from "../atoms/AreYouSureModal";
import LimitInfo from "../molecules/LimitInfo";

interface SwapPlayerModalProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    teamId: number;
    swaps: number;
}

const validationSchema = yup.object({
    removed: yup
        .number()
        .moreThan(0, "Please Select Player From Your Team")
        .required("Please Select Player From Your Team"),
    added: yup
        .number()
        .moreThan(0, "Please Select Available Player")
        .required("Please Select Available Player"),
});

const SwapPlayerModal: React.FC<SwapPlayerModalProps> = ({
    visible,
    setVisible,
    teamId,
    swaps,
}) => {
    const [league, setLeague] = useState<League>();
    const [swapsMade, setSwapsMade] = useState<number>(0);
    const [swapMessage, setSwapMessage] = useState<string>("");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showLimit, setShowLimit] = useState<boolean>(false);
    const [data, setData] = useState<{
        removed: { id: number; name: string };
        added: { id: number; name: string };
    }>({
        removed: {
            id: 0,
            name: "",
        },
        added: {
            id: 0,
            name: "",
        },
    });
    const [players, setPlayers] = useState<Cast[]>();
    const [team, setTeam] = useState<Team[]>();
    const [error, setError] = useState<string>("");

    const availableList = useApi(apiRoutes.GET_PLAYER_LIST(teamId.toString()), {
        onSuccess: (response: {
            league: League;
            list: Cast[];
            error?: string;
            leagueUser: LeagueUser;
            message: string;
        }) => {
            setSwapsMade(response.leagueUser.swaps_made);
            setLeague(response.league);
            setSwapMessage(response.message);
            if (response.error) {
                setError(response.error);
            } else {
                if (response.list.length > 0) {
                    if (
                        response.league &&
                        response.league.draft_type === "Team" &&
                        response.league.rookie_limit !== null &&
                        response.league.rookie_limit > 0
                    ) {
                        response.list = response.list.map((t) => {
                            if (t.rookie) {
                                return { ...t, name: `${t.name} - Rookie` };
                            } else {
                                return { ...t };
                            }
                        });
                    }

                    setPlayers(response.list);
                    setError("");
                } else {
                    setError("No Cast Available");
                }
            }
        },
    });
    const teamRequest = useApi(apiRoutes.GET_TEAM(teamId.toString()), {
        onSuccess: onTeamLoaded,
    });
    const swapPlayerRequest = useApi(apiRoutes.SWAP_PLAYER(teamId.toString()), {
        onSuccess: (response: string) => {
            setSubmitting(false);
            setVisible(false);
            setShowModal(false);
            toast.success(response);
        },
        onFailure: () => {
            setSubmitting(false);
            setShowModal(false);
        },
        responseKey: "message",
    });

    function onTeamLoaded(response: TeamResponse) {
        if (
            response.league &&
            response.league.draft_type === "Team" &&
            response.league.rookie_limit !== null &&
            response.league.rookie_limit > 0
        ) {
            response.currentTeam = response.currentTeam.map((t) => {
                if (t.rookie) {
                    return { ...t, name: `${t.name} - Rookie` };
                } else {
                    return { ...t };
                }
            });
        }
        setTeam(response.currentTeam);
    }

    useEffect(() => {
        if (visible) {
            availableList.request({ swap: true });
            teamRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const initialValues = {
        removed: 0,
        added: 0,
    };

    const hasLimits = useMemo(() => {
        return (
            league?.female_limit || league?.rookie_limit || league?.male_limit
        );
    }, [league]);

    return (
        <>
            <ModalStyled
                show={visible}
                onHide={() => setVisible(false)}
                size="lg"
                className="text-center"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Swap Player</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            const removedPlayer = team.filter((t) => {
                                return (
                                    values.removed.toString() ===
                                    t.cast_id.toString()
                                );
                            })[0];
                            const addedPlayer = players.filter((p) => {
                                return (
                                    values.added.toString() === p.id.toString()
                                );
                            })[0];

                            setData({
                                removed: {
                                    id: values.removed,
                                    name: removedPlayer.name.split("-")[0],
                                },
                                added: {
                                    id: values.added,
                                    name: addedPlayer.name.split("-")[0],
                                },
                            });
                            setVisible(false);
                            setShowModal(true);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                            >
                                {error.length > 0 && (
                                    <Alert variant="danger">{error}</Alert>
                                )}
                                {(teamRequest.loading ||
                                    availableList.loading) && <InputSkeleton />}
                                {!teamRequest.loading &&
                                    !availableList.loading &&
                                    players &&
                                    error.length === 0 && (
                                        <>
                                            <div className="mb-3">
                                                Swaps Remaining:{" "}
                                                <span className="font-weight-bold">
                                                    {swaps - swapsMade}
                                                </span>
                                                {hasLimits && (
                                                    <div>
                                                        <small>
                                                            Team Limits:{" "}
                                                            {league.rookie_limit
                                                                ? `Rookie (${league.rookie_limit}) `
                                                                : null}
                                                            {league.male_limit
                                                                ? `Male (${league.male_limit}) `
                                                                : null}
                                                            {league.female_limit
                                                                ? `Female (${league.female_limit})`
                                                                : null}{" "}
                                                            <InfoIcon
                                                                fontSize="small"
                                                                onClick={() => {
                                                                    setShowLimit(
                                                                        true
                                                                    );
                                                                    setVisible(
                                                                        false
                                                                    );
                                                                }}
                                                            />
                                                        </small>
                                                    </div>
                                                )}
                                                {swapMessage.length > 0 && (
                                                    <Alert
                                                        variant="warning"
                                                        className="mt-1"
                                                    >
                                                        {swapMessage}
                                                    </Alert>
                                                )}
                                            </div>
                                            <Select
                                                name="removed"
                                                defaultKey={0}
                                                defaultValue="Choose Player from Team to Swap"
                                                selectKey="cast_id"
                                                selectValue="name"
                                                items={team}
                                            />
                                            <SwapHorizIcon
                                                className="mb-3"
                                                fontSize="large"
                                            />
                                            <Select
                                                name="added"
                                                defaultKey={0}
                                                defaultValue="Choose Available Player to Swap"
                                                selectKey="id"
                                                selectValue="name"
                                                items={players}
                                            />
                                            <Button
                                                variant="success"
                                                disabled={
                                                    submitting ? true : false
                                                }
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                            >
                                                Swap
                                            </Button>{" "}
                                        </>
                                    )}
                                <Button
                                    variant="light"
                                    onClick={() => setVisible(false)}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </ModalStyled>
            <AreYouSureModal
                showModal={showModal}
                setShowModal={setShowModal}
                submitting={submitting}
                body={`Are you sure you want to swap <b>${
                    data.removed.name
                }</b> for <b>${data.added.name}</b>? You will have <b>${
                    swaps - swapsMade - 1
                }</b> swap${swaps - swapsMade === 1 ? "" : "s"} remaining.`}
                onSuccess={() => {
                    setSubmitting(true);
                    swapPlayerRequest.request({
                        removed: data.removed.id,
                        added: data.added.id,
                    });
                }}
                onCancel={() => setVisible(true)}
            />
            <LimitInfo
                maleLimit={league?.male_limit}
                femaleLimit={league?.female_limit}
                rookieLimit={league?.rookie_limit}
                setShowModal={(show: boolean) => {
                    setShowLimit(show);
                    setVisible(!show);
                }}
                showModal={showLimit}
            />
        </>
    );
};

export default SwapPlayerModal;
