import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { FullShow } from "../../models/Shows";

import Input from "../atoms/Input";
import PageTemplate from "../templates/PageTemplate";
import SubmitButton from "../atoms/SubmitButton";
import Select from "../atoms/Select";
import { TableSkeleton } from "../atoms/Skeletons";

const validationSchema = yup.object({
    showId: yup.mixed().required("Show ID is required"),
    leagueType: yup.string().required("League Type is required"),
    title: yup.string().required("Title is required"),
    body: yup.string().required("Body is required"),
});

const SendPushNotification: React.FC = () => {
    const leagueTypes = [
        { id: "All" },
        { id: "Weekly Redraft" },
        { id: "Team" },
        { id: "Full Draft" },
        { id: "Weekly Budget" },
    ];
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [shows, setShows] = useState<{ show: string; id: number | string }[]>(
        [{ show: "All", id: "all" }]
    );

    const activeShowsRequest = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        responseKey: "shows",
        onSuccess: (response: FullShow[]) => {
            const showList = response.map((show) => {
                return { show: show.show, id: show.id };
            });
            setShows([...shows, ...showList]);
            setLoading(false);
        },
        onFailure: () => setLoading(false),
    });
    const sendPushNotification = useApi(apiRoutes.SEND_PUSH_NOTIFICATION(), {
        onSuccess: (response: string) => {
            toast.success(response);
            setSubmitting(false);
        },
        onFailure: () => {
            setSubmitting(false);
        },
        responseKey: "message",
    });

    useEffect(() => {
        activeShowsRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        showId: "all",
        leagueType: "all",
        title: "",
        body: "",
    };

    return (
        <PageTemplate
            header="Send Push Notification"
            loading={loading}
            skeleton={<TableSkeleton />}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    setSubmitting(true);
                    const headers = { "content-type": "multipart/form-data" };
                    const data = new FormData();
                    data.append("showId", values.showId);
                    data.append("title", values.title);
                    data.append("body", values.body);
                    if (values.leagueType === "All") {
                        data.append("leagueType", "all");
                    } else {
                        data.append("leagueType", values.leagueType);
                    }
                    sendPushNotification.request(data, headers);
                    resetForm();
                }}
            >
                {({ values, handleSubmit, setFieldValue }) => (
                    <Form
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <Select
                            name="show"
                            label="Show:"
                            items={shows}
                            selectKey="id"
                            selectValue="show"
                            handleChange={(id: number | string) =>
                                setFieldValue("showId", id)
                            }
                        />
                        <Select
                            name="leagueType"
                            label="League Type:"
                            items={leagueTypes}
                            selectKey="id"
                            selectValue="id"
                            handleChange={(id: number | string) =>
                                setFieldValue("leagueType", id)
                            }
                        />
                        <Input
                            name="title"
                            label="Title:"
                            placeholder="Title"
                        />
                        <Input name="body" label="Body:" placeholder="Body" />
                        <div className="text-center">
                            <SubmitButton
                                title="Send Push Notification"
                                submitText="Sending"
                                submitting={submitting}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default SendPushNotification;
