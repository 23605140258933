import React, { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fab from "@mui/material/Fab";
import DeleteIcon from "@mui/icons-material/Delete";

import { Cast } from "../../models/Cast";

import ImageUpload from "../atoms/ImageUpload";
import Input from "../atoms/Input";
import TextArea from "../atoms/TextArea";
import { ShowFormFields } from "../pages/AdminShow";
import AreYouSureModal from "../atoms/AreYouSureModal";
import Switch from "../atoms/Switch";
import Select from "../atoms/Select";

interface CastInfoFormProps {
    values: ShowFormFields;
    index: number;
    player: Cast;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const gender = ["N/A", "F", "M"];

const CastInfoForm: React.FC<CastInfoFormProps> = ({
    values,
    index,
    player,
    setFieldValue,
}) => {
    const [showDeleteCast, setShowDeleteCast] = useState<boolean>(false);
    const [selectedCast, setSelectedCast] = useState<null | number>(null);

    const showDeleteCastModal = (id: number) => {
        setShowDeleteCast(true);
        setSelectedCast(id);
    };

    const deleteCast = (
        cast: Cast[],
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        if (selectedCast) {
            const newCast = cast.filter((player) => player.id !== selectedCast);

            setShowDeleteCast(false);
            setSelectedCast(null);
            setFieldValue("cast", newCast);
        } else {
            toast.error("No cast selected");
        }
    };

    const onCastImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        field: string,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue(field, files[0]);
        }
    };

    return (
        <>
            <Row
                className="mb-2"
                style={{ alignItems: "center" }}
                key={player.id}
            >
                <Col md={12}>
                    <ImageUpload
                        name={`cast.${index}.pic`}
                        label="Pic"
                        image={values.cast[index].pic}
                        handleChange={(e) =>
                            onCastImageChange(
                                e,
                                `cast.${index}.pic`,
                                setFieldValue
                            )
                        }
                        width={150}
                    />
                </Col>
                <Col md={6}>
                    <Select
                        field
                        name={`cast.${index}.partner_id`}
                        label="Partner:"
                        items={values.cast}
                        selectKey="id"
                        selectValue="first_name"
                        selectValue2="last_name"
                        defaultValue="None"
                        defaultKey={0}
                    />
                </Col>
                {values.teams.length > 0 && (
                    <Col md={6}>
                        <Select
                            field
                            name={`cast.${index}.team_id`}
                            label="Team:"
                            items={values.teams}
                            selectKey="id"
                            selectValue="team_name"
                        />
                    </Col>
                )}
                {values.genderLimits && (
                    <Col md={12}>
                        <Select
                            name={`cast.${index}.gender`}
                            label="Gender:"
                            items={gender}
                        />
                    </Col>
                )}
                {values.rookieLimits && (
                    <Col md={12}>
                        <Switch label="Rookie" name={`cast.${index}.rookie`} />
                    </Col>
                )}
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.first_name`}
                        label="First Name:"
                        placeholder="First Name"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.last_name`}
                        label="Last Name:"
                        placeholder="Last Name"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.socials.instagram`}
                        label="Instagram:"
                        placeholder="Instagram"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={`cast.${index}.socials.twitter`}
                        label="X:"
                        placeholder="X"
                    />
                </Col>
                <Col xs={10} md={11}>
                    <TextArea
                        name={`cast.${index}.description`}
                        label="Description:"
                        placeholder="Description"
                    />
                </Col>
                <Col xs={2} md={1}>
                    <Fab
                        size="small"
                        onClick={() => showDeleteCastModal(player.id)}
                    >
                        <DeleteIcon />
                    </Fab>
                </Col>
            </Row>
            <AreYouSureModal
                showModal={showDeleteCast}
                setShowModal={setShowDeleteCast}
                body={
                    "Are you sure you want to delete this player?\n\nThis will delete all scores logged for this player and will be deleted from teams if they were already drafted!"
                }
                onSuccess={() => deleteCast(values.cast, setFieldValue)}
            />
        </>
    );
};

export default CastInfoForm;
