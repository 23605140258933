import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import useApi from "../../hooks/useApi";
import { League as LeagueModel } from "../../models/Leagues";
import { Show } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";
import { imageUrl } from "../../config";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import useQuery from "../../hooks/useQuery";

import { TabsWrapper } from "../atoms";
import LoginModal from "../molecules/LoginModal";
import LeagueScoring from "../molecules/LeagueScoring";
import Overview from "../organisms/Overview";
import LeagueChat from "../organisms/LeagueChat";
import Stats from "../organisms/Stats";
import LeagueActivity from "../organisms/LeagueActivity";
import LeagueActions from "../organisms/LeagueActions";
import LeagueTemplate from "../templates/LeagueTemplate";
import { Socials } from "../../models/Socials";
import { Cast } from "../../models/Cast";
import CastList from "../molecules/CastList";

interface ParamTypes {
    id: string;
    showId?: string;
}

const League: React.FC = () => {
    const query = useQuery();
    const view = query.get("view") ? query.get("view") : "overview";
    const [show, setShow] = useState<Show>();
    const [league, setLeague] = useState<LeagueModel>();
    const [draftType, setDraftType] = useState<string>("");
    const [leagueUser, setLeagueUser] = useState<LeaguesLeagueUser>();
    const [teamLeagueSetUp, setTeamLeagueSetUp] = useState<boolean>(false);
    const [showSelectTeam, setShowSelectTeam] = useState<boolean>(false);
    const [tradeCount, setTradeCount] = useState<number>(0);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [socials, setSocials] = useState<Socials | null | undefined>();
    const [winner, setWinner] = useState<string>("");
    const [commissionerNote, setCommissionerNote] = useState<string | null>("");
    const [cast, setCast] = useState<Cast[]>([]);

    const { id, showId } = useParams<ParamTypes>();
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id, showId), {
        onSuccess,
    });

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const reloadLeague = () => {
        leagueRequest.request();
    };

    function onSuccess(response: {
        show: Show;
        cast: Cast[];
        socials: Socials | null;
        league: LeagueModel;
        leagueUser: LeaguesLeagueUser;
        setUpTeamLeague: boolean;
        tradeOffers: number;
        tradePending: number;
        canViewLeague: boolean;
        winners: string;
    }) {
        document.body.style.overflow = "auto";
        if (response.canViewLeague) {
            setCast(response.cast);
            setShow(response.show);
            setLeague(response.league);
            setDraftType(response.league.draft_type);
            setCommissionerNote(response.league.commissioner_note);
            setLeagueUser(response.leagueUser);
            setTradeCount(response.tradeOffers + response.tradePending);
            if (response.setUpTeamLeague) {
                setTeamLeagueSetUp(true);
                setShowSelectTeam(true);
            }
            setWinner(response.winners);
            setSocials(response.socials);
        } else {
            window.location.href = "/";
        }
    }

    const determineWarnings = () => {
        const warnings = [];
        if (show && league && show.locked && !league.custom) {
            warnings.push(
                `Scores are currently being logged for ${
                    show.type === "week" ? "week" : "episode"
                } ${
                    show.current_episode
                }. Please allow up to 48 hours for scores to be logged.`
            );
        }
        if (show && league && leagueUser && show.locked && league.custom) {
            if (!leagueUser.commissioner) {
                warnings.push(
                    `Scores are currently being logged for ${
                        show.type === "week" ? "week" : "episode"
                    } ${
                        show.current_episode
                    }. Please contact your commissioner for details.`
                );
            } else {
                warnings.push(
                    `Your league is currently locked. Leagues lock automatically based on the show time set in your league settings (accessible via Edit League under the Settings icon). To unlock your league, you can record the scores for the episode (Log Scores under the Settings icon). If your league locked unintentionally, you can manually unlock it using the Unlock League option in the Settings menu`
                );
            }
        }
        if (league && league.scores_updating) {
            warnings.push(
                `Updating scores for your league. This may take a couple minutes. When this message goes away, please reload the page to get updated scores.`
            );
        }
        if (show && show.scores_updating) {
            warnings.push(
                `Updating scores for ${show.show}. This may take a couple minutes. When this message goes away, please reload the page to get updated scores.`
            );
        }

        return warnings;
    };

    return (
        <LeagueTemplate
            header={league ? league.name : ""}
            loading={leagueRequest.loading}
            league={league ? league : null}
            show={show ? show : null}
            image={
                show
                    ? `${imageUrl}${show.img
                          .replace(/ /g, "%20")
                          .replace(/\(/g, "%28")
                          .replace(/\)/g, "%29")}`
                    : ""
            }
            skeleton={true}
            warnings={determineWarnings()}
            leagueActions={
                !leagueRequest.loading &&
                league &&
                show && (
                    <LeagueActions
                        socials={socials}
                        league={league}
                        leagueUser={leagueUser}
                        show={show}
                        winner={winner}
                        tradeCount={tradeCount}
                        showSelectTeam={showSelectTeam}
                        teamLeagueSetUp={teamLeagueSetUp}
                        reloadLeague={reloadLeague}
                        commissionerNote={commissionerNote}
                    />
                )
            }
            commissionerNote={commissionerNote}
            setCommissionerNote={setCommissionerNote}
            reloadLeague={reloadLeague}
            leagueUser={leagueUser}
        >
            <TabsWrapper className="col-md-12">
                <Tabs
                    defaultActiveKey={view === "chat" ? "overview" : view}
                    className="col-md-12 pl-0 pr-0"
                >
                    <Tab
                        tabClassName={"col-3 text-center"}
                        eventKey="overview"
                        title={window.innerWidth < 450 ? "Over..." : "Overview"}
                    >
                        {show && (
                            <Overview
                                show={show}
                                leagueId={id}
                                league={league}
                                leagueUser={leagueUser}
                                onDeleteTeam={() => reloadLeague()}
                            />
                        )}
                    </Tab>
                    <Tab
                        tabClassName="col-3 text-center"
                        eventKey="stats"
                        title={
                            draftType !== "Confidence Pool" &&
                            draftType !== "Eliminated Confidence Pool"
                                ? "Stats"
                                : "Cast"
                        }
                    >
                        {show && (
                            <>
                                {draftType === "Confidence Pool" ||
                                draftType === "Eliminated Confidence Pool" ? (
                                    <CastList
                                        currentEpisode={show.current_episode}
                                        cast={cast}
                                    />
                                ) : (
                                    <Stats
                                        show={show}
                                        league={league}
                                        leagueUser={leagueUser}
                                    />
                                )}
                            </>
                        )}
                    </Tab>
                    <Tab
                        tabClassName={"col-3 text-center min-wpx-90"}
                        eventKey="scoring"
                        title="Scoring"
                    >
                        {show && league && (
                            <LeagueScoring
                                leagueId={id}
                                showId={show.id}
                                draftType={league.draft_type}
                            />
                        )}
                    </Tab>
                    <Tab
                        tabClassName={"col-3 text-center"}
                        eventKey="log"
                        title="Log"
                        mountOnEnter={true}
                    >
                        {show && (
                            <LeagueActivity leagueId={id} showId={show.id} />
                        )}
                    </Tab>
                </Tabs>
            </TabsWrapper>
            {leagueUser && <LeagueChat leagueId={id} open={view === "chat"} />}
            <LoginModal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                message={"You must login to join league"}
            />
        </LeagueTemplate>
    );
};

export default League;
