import React from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { setTheme } from "../../store/theme/actions";
import darkTheme from "../../themes/dark";
import lightTheme from "../../themes/light";

import { FooterContainer, FooterLink } from "./styles/Footer.styled";
import SocialLinks from "../atoms/SocialLinks";

const Footer: React.FC = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    return (
        <FooterContainer className="py-5">
            <p style={{ marginBottom: -10 }}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://apps.apple.com/us/app/realtvfantasy/id1513607707?mt=8"
                >
                    <Image
                        style={{ height: 120, marginTop: -30 }}
                        alt="Download on the App Store"
                        src="/images/app-store.png"
                    />
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.realtvfantasy.realtvfantasy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                    <Image
                        style={{ height: 60, marginTop: -30 }}
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                </a>
            </p>
            <div>
                <SocialLinks
                    socials={{
                        facebook: "https://www.facebook.com/realtvfantasy/",
                        instagram: "https://www.instagram.com/realtvfantasy/",
                        twitter: "https://x.com/RealTVFantasy",
                        tiktok: "https://tiktok.com/@realtvfantasy",
                    }}
                    size="large"
                />
            </div>
            <p>
                <FooterLink
                    className="footer-link"
                    to="/about"
                    onClick={() =>
                        dispatch(
                            setTheme(user.dark_mode ? darkTheme : lightTheme)
                        )
                    }
                >
                    About Us
                </FooterLink>
                |
                <FooterLink
                    className="footer-link"
                    to="/contact"
                    onClick={() =>
                        dispatch(
                            setTheme(user.dark_mode ? darkTheme : lightTheme)
                        )
                    }
                >
                    Contact Us
                </FooterLink>
                |
                <FooterLink
                    className="footer-link"
                    to="/terms"
                    onClick={() =>
                        dispatch(
                            setTheme(user.dark_mode ? darkTheme : lightTheme)
                        )
                    }
                >
                    Terms of Service
                </FooterLink>
                |
                <FooterLink
                    className="footer-link"
                    to="/media"
                    onClick={() =>
                        dispatch(
                            setTheme(user.dark_mode ? darkTheme : lightTheme)
                        )
                    }
                >
                    In the Media
                </FooterLink>
            </p>
            <p>Copyright &copy; RealTVFantasy {new Date().getFullYear()}</p>
        </FooterContainer>
    );
};

export default Footer;
