import React from "react";

import { League } from "../../models/Leagues";
import { Team } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";

import PlayerRow from "./PlayerRow";
import PlayerTableHeader from "./PlayerTableHeader";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";
import { Col } from "react-bootstrap";

interface FullDraftTableProps {
    league: League;
    team: Team[];
    categories: string[];
    commissioner: boolean;
    show: Show;
    setSelectedPlayer: (player: Team) => void;
    setShowDeleteModal: (show: boolean) => void;
}

const FullDraftTeamTable: React.FC<FullDraftTableProps> = ({
    league,
    team,
    show,
    categories,
    commissioner,
    setSelectedPlayer,
    setShowDeleteModal,
}) => {
    return (
        <LeagueTableStyled>
            <PlayerTableHeader categories={categories} />
            <div className="tbody">
                {team.length > 0 &&
                    team.map((player: Team) => {
                        return (
                            <PlayerRow
                                key={player.id}
                                currentEpisode={show.current_episode}
                                player={player}
                                league={league}
                                commissioner={commissioner}
                                onDeleteClick={(e: any) => {
                                    e.stopPropagation();
                                    setSelectedPlayer(player);
                                    setShowDeleteModal(true);
                                }}
                            />
                        );
                    })}
                {team.length <= 0 && (
                    <Col className="text-center p-4">No Players Found</Col>
                )}
            </div>
        </LeagueTableStyled>
    );
};

export default FullDraftTeamTable;
