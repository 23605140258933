import React from "react";

import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

import { useCountdown } from "../../hooks/useCountdown";

import DateTimeDisplay from "../atoms/DateTimeDisplay";

interface ShowCounterProps {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    live?: boolean;
}

const ShowCounter: React.FC<ShowCounterProps> = ({
    days,
    hours,
    minutes,
    seconds,
    live,
}) => {
    return (
        <Alert variant="warning">
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
                {live ? (
                    <div>Live scoring starts in </div>
                ) : (
                    <div>Lineups lock in </div>
                )}
                <DateTimeDisplay
                    value={days}
                    type={"days"}
                    isDanger={days <= 3}
                />
                <DateTimeDisplay
                    value={hours}
                    type={"hours"}
                    isDanger={false}
                />
                <DateTimeDisplay
                    value={minutes}
                    type={"mins"}
                    isDanger={false}
                />
                <DateTimeDisplay
                    value={seconds}
                    type={"secs"}
                    isDanger={false}
                />
            </Row>
        </Alert>
    );
};

interface CountdownTimerProps {
    targetDate: number | null;
    live?: boolean;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
    targetDate,
    live,
}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    const totalTime = days + hours + minutes + seconds;

    // Early return if countdown is over
    if (totalTime <= 0) return null;

    // Check if it's episode 1 or within 5 days for other episodes
    const shouldShowCounter = days <= 5 || days > 7;

    if (shouldShowCounter) {
        return (
            <ShowCounter
                live={live}
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    } else {
        return <></>;
    }
};

export default CountdownTimer;
