import React from "react";

import { QuestionScoreItem } from "../../models/QuestionScores";

import { Row } from "react-bootstrap";

import CastImage from "../atoms/CastImage";
import { PlayerName } from "./styles/EliminatedPlayers.styled";
import CastTeam from "./CastTeam";

interface ChallengeQuestionResultsProps {
    results: QuestionScoreItem[];
}

const ChallengeQuestionResults: React.FC<ChallengeQuestionResultsProps> = ({
    results,
}) => {
    return (
        <div className="mb-5">
            <h5>Results:</h5>
            {results.map((player) => {
                return (
                    <Row
                        key={player.cast_id}
                        className="align-items-center mb-2"
                    >
                        <CastImage src={player.pic} />
                        <div className="ml-2">
                            <PlayerName>
                                {`${player.first_name} ${
                                    player.last_name ? player.last_name : ""
                                }`}
                            </PlayerName>
                            <CastTeam
                                left
                                team={player.team}
                                partner={player.partner}
                                size={10}
                            />
                        </div>
                    </Row>
                );
            })}
        </div>
    );
};

export default ChallengeQuestionResults;
