import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";

import Button from "react-bootstrap/Button";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import { RootState } from "../../store";
import { League } from "../../models/Leagues";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import {
    ConfidenceCast,
    ConfidenceCastList,
} from "../../models/ConfidencePool";

import { ListContainer, VerticalPadding } from "../atoms";
import { Body, Footer, Header } from "./styles/SetConfidencePool.styled";
import { TableSkeleton } from "../atoms/Skeletons";
import ConfidenceListItem from "../molecules/ConfidenceListItem";
import AnalyticsSelect from "../atoms/AnalyticsSelect";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import AreYouSureModal from "../atoms/AreYouSureModal";
import LineupHeader from "../molecules/LineupHeader";
import { handleSortChange } from "../../helpers/analyticsSorter";

interface SetConfidencePoolProps {
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const SetConfidencePool: React.FC<SetConfidencePoolProps> = ({
    open,
    setOpen,
    league,
    leagueUser,
    show,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [cast, setCast] = useState<ConfidenceCastList>([]);
    const [sort, setSort] = useState<string>("name");
    const [showModal, setShowModal] = useState<boolean>(false);

    const confidencePoolRequest = useApi(
        apiRoutes.GET_CURRENT_CONFIDENCE_POOL(leagueUser.id.toString()),
        {
            onSuccess: (cast: ConfidenceCastList) => {
                setCast(cast);
                setLoading(false);
            },
            onFailure: () => setLoading(false),
            responseKey: "cast",
        }
    );

    const saveRequest = useApi(
        apiRoutes.SAVE_CONFIDENCE_POOL(leagueUser.id.toString()),
        {
            onSuccess: (message: string) => {
                setSubmitting(false);
                toast.success(message);
                closePanel();
            },
            onFailure: () => {
                setSubmitting(false);
            },
            responseKey: "message",
        }
    );

    useEffect(() => {
        if (open) {
            setLoading(true);
            document.body.style.overflow = "hidden";
            confidencePoolRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, show]);

    const closeWithoutSave = () => {
        document.body.style.overflow = "auto";
        if (show.locked) {
            closePanel();
        } else {
            setShowModal(true);
        }
    };

    const closePanel = () => {
        setShowModal(false);
        setOpen(false);
        document.body.style.overflow = "auto";
    };

    const SortableList = SortableContainer(
        ({ items }: { items: ConfidenceCastList }) => {
            const playerCount = items.length;
            let multiplier = 1;
            if (playerCount <= 3) {
                multiplier = 4;
            } else if (playerCount <= 5) {
                multiplier = 3;
            } else if (playerCount <= 10) {
                multiplier = 2;
            }

            return (
                <div>
                    {items.map((player, index) => (
                        <SortableItem
                            key={index}
                            index={index}
                            player={player}
                            points={
                                league.draft_type ===
                                "Eliminated Confidence Pool"
                                    ? index + 1
                                    : (playerCount - index) * multiplier
                            }
                        />
                    ))}
                </div>
            );
        }
    );

    const SortableItem = SortableElement(
        ({ player, points }: { player: ConfidenceCast; points: number }) => (
            <ConfidenceListItem player={player} points={points} />
        )
    );

    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const items = arrayMove(cast, oldIndex, newIndex);
        setCast(items);
    };

    const savePicks = () => {
        setSubmitting(true);
        saveRequest.request({
            picks: cast,
            episode: show.current_episode,
            eliminated:
                league.draft_type === "Eliminated Confidence Pool"
                    ? true
                    : false,
        });
    };

    return (
        <SlidingPanel
            type={"left"}
            isOpen={open}
            size={90}
            backdropClicked={closeWithoutSave}
        >
            <div className="overflow-hidden">
                <Header style={isMobile ? { height: 110 } : {}}>
                    <h5>
                        Make Picks{" "}
                        <small>
                            {show.type === "week" ? "Week" : "Episode"}{" "}
                            {show.current_episode}
                        </small>
                    </h5>
                    {isMobile && (
                        <>
                            <VerticalPadding />
                            <Button
                                disabled={
                                    submitting ||
                                    show.locked ||
                                    show.current_episode > show.episodes ||
                                    show.current_episode <
                                        league.episode_started
                                }
                                variant="success"
                                onClick={savePicks}
                            >
                                {submitting ? (
                                    <>
                                        Saving{" "}
                                        <CircularProgress
                                            size={12}
                                            color="inherit"
                                        />
                                    </>
                                ) : (
                                    "Save Picks"
                                )}
                            </Button>{" "}
                            <Button variant="light" onClick={closeWithoutSave}>
                                Cancel
                            </Button>
                        </>
                    )}
                </Header>
                <Body
                    style={
                        isMobile
                            ? {
                                  height: "calc(100% - 110px)",
                                  top: 110,
                                  paddingBottom: 100,
                                  borderBottomRightRadius: 20,
                              }
                            : {}
                    }
                >
                    {loading ? (
                        <TableSkeleton size={4} />
                    ) : (
                        <ListContainer>
                            <LineupHeader show={show} league={league} />
                            <h6 className="text-center">
                                Order contestants by how confident you are that
                                they will survive this week.
                                <br />
                                {league.draft_type ===
                                "Eliminated Confidence Pool" ? (
                                    <>
                                        The points they will receive if a
                                        contestant is eliminated is displayed on
                                        the right.
                                    </>
                                ) : (
                                    <>
                                        The points they will receive if correct
                                        are displayed on the right.
                                    </>
                                )}
                            </h6>
                            {subscription &&
                                hasPremiumSub(subscription.name) && (
                                    <div className="ml-3 mr-3">
                                        <AnalyticsSelect
                                            defaultValue="-"
                                            secondary="Average Rank"
                                            value={sort}
                                            onChange={(value: string) => {
                                                setSort(value);
                                                handleSortChange(
                                                    value,
                                                    cast,
                                                    setCast,
                                                    "confidence",
                                                    true
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            <Divider />
                            <SortableList
                                helperClass="sortableHelper"
                                onSortStart={(_, event) =>
                                    event.preventDefault()
                                }
                                items={cast}
                                onSortEnd={onSortEnd}
                            />
                        </ListContainer>
                    )}
                </Body>
                {!isMobile && (
                    <Footer>
                        <Button
                            disabled={
                                submitting ||
                                show.locked ||
                                show.current_episode > show.episodes ||
                                show.current_episode < league.episode_started
                            }
                            variant="success"
                            onClick={savePicks}
                        >
                            {submitting ? (
                                <>
                                    Saving{" "}
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                </>
                            ) : (
                                "Save Lineup"
                            )}
                        </Button>{" "}
                        <Button variant="light" onClick={closeWithoutSave}>
                            Cancel
                        </Button>
                    </Footer>
                )}
                <AreYouSureModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    title={`Are you sure you want to exit your picks?`}
                    body={`Your changes have not been saved.`}
                    onSuccess={() => closePanel()}
                />
            </div>
        </SlidingPanel>
    );
};

export default SetConfidencePool;
