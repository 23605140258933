import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import { Button, Col, Row } from "react-bootstrap";

import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import useApi from "../../hooks/useApi";
import { LeagueOverviewAnalytics } from "../../models/Analytics";
import { League } from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";
import { Show } from "../../models/Shows";

import { FormGroupWrapper } from "../atoms";
import PageTemplate from "../templates/PageTemplate";
import AnalyticsListItem from "../molecules/AnalyticsListItem";
import { TableSkeleton } from "../atoms/Skeletons";
import EpisodeAnalyticsSelect from "../atoms/EpisodeAnalyticsSelect";
import { LeagueTableTeamStyled } from "../atoms/styles/Table.styled";

interface ParamTypes {
    id: string;
    show_id?: string;
}

const Analytics: React.FC = () => {
    const { id, show_id } = useParams<ParamTypes>();
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);
    const [type, setType] = useState<string>("site");
    const [analyticsType, setAnalyticsType] =
        useState<string>("avgDraftPosition");
    const [league, setLeague] = useState<League>();
    const [showId, setShowId] = useState<string>(show_id);
    const [show, setShow] = useState<Show>();
    const [episode, setEpisode] = useState<number>();
    const [leagueSet, setLeagueSet] = useState<boolean>(false);
    const [analytics, setAnalytics] = useState<LeagueOverviewAnalytics>();
    const [loading, setLoading] = useState<boolean>(true);

    const draftType = useMemo(() => {
        switch (analyticsType) {
            case "avgDraftPosition":
                return "Full Draft";
            case "avgRank":
                return "Confidence Pool";
            case "weeklyStarting":
                return "Weekly Redraft";
            case "weeklyBudgetStarting":
                return "Weekly Budget";
            case "teamStarting":
                return "Team";
            case "teamOwned":
                return "Team";
            default:
                return "Full Draft";
        }
    }, [analyticsType]);

    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id, show_id), {
        onSuccess: (response: { league: League; show: Show }) => {
            setLeague(response.league);
            setShowId(response.league.show_id.toString());
            setShow(response.show);
            setEpisode(response.show.current_episode);
            setLeagueSet(true);
        },
    });
    const leagueOverviewRequest = useApi(apiRoutes.LEAGUE_OVERVIEW(id), {
        onSuccess: (response: LeagueOverviewAnalytics) => {
            setLoading(false);
            setAnalytics(response);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const siteOverviewRequest = useApi(apiRoutes.SITE_OVERVIEW(showId), {
        onSuccess: (response: LeagueOverviewAnalytics) => {
            setLoading(false);
            setAnalytics(response);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (
            !subscription ||
            (subscription && !hasPremiumSub(subscription.name))
        ) {
            window.location.href = "/";
        }
    }, [subscription]);

    useEffect(() => {
        if (leagueSet) {
            if (type === "site") {
                setLoading(true);
                siteOverviewRequest.request({ episode: episode });
            } else {
                setLoading(true);
                leagueOverviewRequest.request({
                    league: true,
                    episode: episode,
                    show_id: show_id,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, leagueSet, episode, show_id]);

    const analyticsOptions = [
        {
            value: "avgDraftPosition",
            label: "Average Draft Position (Full Draft Leagues)",
        },
        { value: "avgRank", label: "Average Rank (Confidence Pool Leagues)" },
        {
            value: "weeklyBudgetStarting",
            label: "Weekly Budget Starting Percentage (Weekly Budget Leagues)",
        },
        {
            value: "weeklyStarting",
            label: "Weekly Redraft Starting Percentage (Weekly Redraft Leagues)",
        },
        {
            value: "teamStarting",
            label: "Team Starting Percentage (Team Leagues)",
        },
        { value: "teamOwned", label: "Team Owned Percentage (Team Leagues)" },
    ];

    const tableData = {
        avgDraftPosition: {
            title: "Avg Draft Position",
            data: analytics?.avgDraftPosition ?? [],
            valueKey: "average_draft_position",
        },
        avgRank: {
            title: "Avg Rank",
            data: analytics?.avgRank ?? [],
            valueKey: "average",
        },
        weeklyBudgetStarting: {
            title: "Weekly Budget Starting %",
            data: analytics?.weeklyBudgetStarted ?? [],
            valueKey: "weekly_started",
            filter: (position) =>
                league?.draft_type === "Weekly Budget" || type === "site",
        },
        weeklyStarting: {
            title: "Weekly Redraft Starting %",
            data: analytics?.weeklyRedraftStarted ?? [],
            valueKey: "weekly_started",
            filter: (position) =>
                league?.draft_type === "Weekly Redraft" || type === "site",
        },
        teamStarting: {
            title: "Team Starting %",
            data: analytics?.teamStarted ?? [],
            valueKey: "team_started",
            filter: (position) =>
                league?.draft_type === "Team" || type === "site",
        },
        teamOwned: {
            title: "Team Owned %",
            data: analytics?.teamOwned ?? [],
            valueKey: "team_owned",
        },
    };

    return (
        <PageTemplate
            header="Analytics"
            headerSecondary={league && league.name}
        >
            <>
                {league && (
                    <Link
                        className="mb-5"
                        to={`/myleagues/view/${league.id}${
                            league.id === 0 ||
                            league.id === "0" ||
                            league.id === "team" ||
                            league.id === "budget"
                                ? `/${league.show_id}`
                                : ""
                        }`}
                    >
                        <Button variant="light">Back</Button>
                    </Link>
                )}
                <FormGroupWrapper className="mt-3">
                    <Form.Control
                        as="select"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setAnalyticsType(event.target.value);
                        }}
                        value={analyticsType}
                    >
                        {analyticsOptions.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </Form.Control>
                </FormGroupWrapper>
                {analyticsType !== "avgDraftPosition" &&
                    analyticsType !== "avgRank" && (
                        <>
                            {league && league.draft_type === draftType && (
                                <FormGroupWrapper>
                                    <Form.Control
                                        as="select"
                                        onChange={(
                                            event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setType(event.target.value);
                                        }}
                                        value={type}
                                    >
                                        <option value="site">Site Wide</option>
                                        <option value="league">
                                            This League
                                        </option>
                                    </Form.Control>
                                </FormGroupWrapper>
                            )}
                            {analyticsType !== "teamOwned" && (
                                <EpisodeAnalyticsSelect
                                    show={show}
                                    episode={episode}
                                    onChange={(event) =>
                                        setEpisode(parseInt(event.target.value))
                                    }
                                />
                            )}
                        </>
                    )}
                {loading && <TableSkeleton size={2} />}
                {!loading && (
                    <>
                        {analyticsType in tableData && (
                            <LeagueTableTeamStyled>
                                <Row
                                    className="header text-center font-weight-bold p-3"
                                    style={{ backgroundColor: theme.primary }}
                                >
                                    <Col style={{ border: "none" }}>Player</Col>
                                    <Col
                                        style={{ border: "none" }}
                                        className="pl-5"
                                    >
                                        {tableData[analyticsType].title}
                                    </Col>
                                </Row>
                                <div className="tbody">
                                    {tableData[analyticsType].data.map(
                                        (position) => (
                                            <AnalyticsListItem
                                                key={position.id}
                                                currentEpisode={
                                                    show?.current_episode
                                                }
                                                player={position}
                                                rightText={
                                                    position[
                                                        tableData[analyticsType]
                                                            .valueKey
                                                    ]
                                                        ? `${
                                                              position[
                                                                  tableData[
                                                                      analyticsType
                                                                  ].valueKey
                                                              ]
                                                          }${
                                                              analyticsType !==
                                                                  "avgRank" &&
                                                              analyticsType !==
                                                                  "avgDraftPosition"
                                                                  ? "%"
                                                                  : ""
                                                          }`
                                                        : "N/A"
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            </LeagueTableTeamStyled>
                        )}
                    </>
                )}
            </>
        </PageTemplate>
    );
};

export default Analytics;
