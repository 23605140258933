import React, { useEffect, useState } from "react";
import { BsChatFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import Badge from "@mui/material/Badge";
import Grow from "@mui/material/Grow";

import echo from "../../config/echo";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Chat, ChatResponse } from "../../models/Chat";
import { RootState } from "../../store";

import { Spinner } from "../atoms";
import SentChat from "../molecules/SentChat";
import ReceivedChat from "../molecules/ReceivedChat";
import {
    ChatWrapper,
    MessagesWrapper,
    Footer,
    MessageInput,
    ChatIcon,
    ChatButtonWrapper,
    SendIcon,
} from "./styles/LeagueChat.styled";
import { toast } from "react-toastify";
import moment from "moment";
import { imageUrl } from "../../config";

interface LeagueChatProps {
    leagueId: string;
    draft?: boolean;
    reloadDraft?: () => void;
    open?: boolean;
}

const LeagueChat: React.FC<LeagueChatProps> = ({
    leagueId,
    open,
    draft,
    reloadDraft,
}) => {
    const pageSize = 50;

    const [loaded, setLoaded] = useState<boolean>(false);
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [chatOpen, setChatOpen] = useState<boolean>(open ? open : false);
    const [messages, setMessages] = useState<Chat[] | []>([]);
    const [message, setMessage] = useState<string>("");
    const [sending, setSending] = useState<boolean>(false);
    const [liking, setLiking] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const userId = useSelector((state: RootState) => state.user.id);
    const user = useSelector((state: RootState) => state.user);

    const chat = useApi(apiRoutes.GET_LEAGUE_CHAT(leagueId), { onSuccess });
    const deleteNotifications = useApi(
        apiRoutes.DELETE_NOTIFICATIONS_BY_LEAGUE(leagueId),
        { onSuccess: () => setNotificationCount(0) }
    );
    const sendRequest = useApi(apiRoutes.SEND_CHAT(leagueId), {
        responseKey: "chat",
        onSuccess: (response: Chat) => {
            const updatedChat = [response, ...messages];
            setSending(false);
            setMessages(updatedChat);
            setMessage("");
        },
        onFailure: () => {
            if (messages.length > 0) {
                const updatedChat = messages.slice(1);
                setMessages(updatedChat);
            }
            setSending(false);
        },
    });
    const likeRequest = useApi(apiRoutes.LIKE_CHAT(), {
        responseKey: "chat",
        onSuccess: (response: Chat) => {
            setLiking(false);
            const updatedChat = [...messages];
            const teamIndex = updatedChat.findIndex(
                (value: Chat) => response.id === value.id
            );
            updatedChat[teamIndex] = response;
            setMessages(updatedChat);
        },
        onFailure: () => {
            setLiking(false);
        },
    });

    useEffect(() => {
        if (leagueId !== "team" && leagueId !== "0" && leagueId !== "budget") {
            chat.request();

            if (draft) {
                //could add this back in whenever I feel like I can fit it in pusher limits
                const leagueChatChannel = echo.channel(`chat.${leagueId}`);

                //register socket events
                leagueChatChannel.stopListening(".LeagueChatCreated");
                leagueChatChannel.listen(".LeagueChatCreated", (ev: Chat) => {
                    if (ev.user_id !== userId) {
                        if (draft && ev.user_id === 0) {
                            toast.success(ev.message);
                            if (
                                ev.message.includes("reset the draft") ||
                                ev.message.includes("started the draft") ||
                                ev.message === "Draft Finished"
                            ) {
                                if (reloadDraft) {
                                    reloadDraft();
                                }
                            }
                        }
                        chat.request();
                    }
                });

                return () => {
                    leagueChatChannel.stopListening(".LeagueChatCreated");
                };
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId, draft]);

    function onSuccess(response: ChatResponse) {
        if (messages.length === 0) {
            setMessages(response.chat);
        } else {
            setMessages([...messages, ...response.chat]);
        }
        setHasMore(response.has_more);
        setPage(page + 1);
        setNotificationCount(response.notification_count);
        setLoaded(true);
    }

    const fetchNextData = () => {
        chat.request({ page: page, limit: pageSize });
    };

    const handleChange = (event: any) => {
        setMessage(event.target.value);
    };

    const sendChat = () => {
        if (message.length > 0) {
            if (!sending) {
                setSending(true);
                const updatedChat = [
                    {
                        id: 0,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        user_id: user.id,
                        profile_pic:
                            user.profile_pic &&
                            user.profile_pic.includes("https")
                                ? user.profile_pic
                                : `${imageUrl}${user.profile_pic}`,
                        message: message,
                        comment: message,
                        count: 0,
                        liked: false,
                        likers: [],
                        team_name: null,
                        created_at: moment().toDate(),
                        updated_at: moment().toDate(),
                        league_user_id: null,
                        league_id: parseFloat(leagueId),
                        subscription: null,
                    },
                    ...messages,
                ];
                setMessages(updatedChat);
                sendRequest.request({ message });
                setMessage("");
            }
        }
    };

    const likeChat = (id: number, like: number) => {
        if (!liking) {
            setLiking(true);
            likeRequest.request({ id, league_id: leagueId, like });
        }
    };

    return (
        <>
            {loaded && (
                <>
                    <Grow in={chatOpen}>
                        <ChatWrapper>
                            <MessagesWrapper id="scrollableDivChat">
                                {messages.length > 0 && (
                                    <InfiniteScroll
                                        dataLength={messages.length} //This is important field to render the next data
                                        next={fetchNextData}
                                        hasMore={hasMore}
                                        loader={<Spinner />}
                                        inverse={true}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column-reverse",
                                            paddingTop: 20,
                                        }} //To put endMessage and loader to the top.
                                        scrollableTarget="scrollableDivChat"
                                    >
                                        {messages.map((message: Chat) => {
                                            if (message.user_id !== userId) {
                                                return (
                                                    <ReceivedChat
                                                        key={message.id}
                                                        message={message}
                                                        likeChat={likeChat}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <SentChat
                                                        key={message.id}
                                                        message={message}
                                                        likeChat={likeChat}
                                                    />
                                                );
                                            }
                                        })}
                                    </InfiniteScroll>
                                )}
                                {messages.length === 0 && (
                                    <div className="text-center">
                                        There are no messages yet
                                    </div>
                                )}
                            </MessagesWrapper>
                            <Footer>
                                <MessageInput
                                    value={message}
                                    onChange={handleChange}
                                    placeholder="Send Message..."
                                    rows={1}
                                />
                                <SendIcon
                                    onClick={() => sendChat()}
                                    color={
                                        sending || message.length === 0
                                            ? "#ccc"
                                            : "#007bff"
                                    }
                                    size={20}
                                />
                            </Footer>
                        </ChatWrapper>
                    </Grow>
                    <ChatButtonWrapper>
                        <Badge badgeContent={notificationCount} color="error">
                            <ChatIcon
                                style={{
                                    backgroundColor: chatOpen
                                        ? "#fff"
                                        : "#007bff",
                                }}
                                onClick={() => {
                                    setChatOpen(!chatOpen);
                                    deleteNotifications.request();
                                }}
                            >
                                {chatOpen && (
                                    <IoMdClose color={"#000"} size={40} />
                                )}
                                {!chatOpen && (
                                    <BsChatFill color={"#fff"} size={40} />
                                )}
                            </ChatIcon>
                        </Badge>
                    </ChatButtonWrapper>
                </>
            )}
        </>
    );
};

export default LeagueChat;
