import React from "react";
import { useSelector } from "react-redux";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { RootState } from "../../store";
import { Show } from "../../models/Shows";
import {
    ConfidenceTeam,
    LeagueUser,
    WeeklyConfidenceTeam,
} from "../../models/LeagueUsers";

import PlayerTableHeader from "./PlayerTableHeader";
import { AccordionStyled } from "../atoms";
import ConfidencePlayerRow from "./ConfidencePlayerRow";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";
import { Col, Row } from "react-bootstrap";

interface WeeklyConfidenceTeamItemProps {
    confidencePool: WeeklyConfidenceTeam[];
    show: Show;
    leagueUser: LeagueUser;
    eliminatedOnly?: boolean;
}

const WeeklyConfidenceTeamItem: React.FC<WeeklyConfidenceTeamItemProps> = ({
    confidencePool,
    show,
    leagueUser,
    eliminatedOnly,
}) => {
    const user = useSelector((state: RootState) => state.user);

    const teamHidden = (item: WeeklyConfidenceTeam) => {
        const coOwners =
            leagueUser.co_owners !== null
                ? leagueUser.co_owners.split(",")
                : [];
        if (show.current_episode === item.episode && !show.locked) {
            if (coOwners.some((co) => user.id.toString() === co)) {
                return false;
            } else if (leagueUser.user_id.toString() === user.id.toString()) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const playerSurvived = (player: ConfidenceTeam) => {
        if (
            show.current_episode > player.episode &&
            (player.episode_eliminated === null ||
                player.episode_eliminated > player.episode)
        ) {
            return true;
        }

        return false;
    };

    const calculateTotal = (players: ConfidenceTeam[]): number => {
        let total = 0;

        if (eliminatedOnly) {
            players.forEach((player) => {
                if (
                    show.current_episode !== player.episode &&
                    !playerSurvived(player)
                )
                    total += player.points;
            });
        } else {
            players.forEach((player) => {
                if (
                    show.current_episode !== player.episode &&
                    playerSurvived(player)
                )
                    total += player.points;
            });
        }

        return total;
    };

    return (
        <>
            {confidencePool.length > 0 &&
                confidencePool.map((item: WeeklyConfidenceTeam) => {
                    return (
                        <AccordionStyled
                            defaultExpanded={
                                item.episode === show.current_episode
                            }
                            key={item.episode}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                {show.type === "week" ? "Week" : "Episode"}{" "}
                                {item.episode}{" "}
                                {/* TODO: Add in way for admin to fix wrong lineups */}
                                <div
                                    style={{ flex: 1, fontSize: 18 }}
                                    className="text-right pr-3"
                                >
                                    {calculateTotal(item.players)}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LeagueTableStyled>
                                    {!teamHidden(item) &&
                                        item.players.length !== 0 && (
                                            <PlayerTableHeader
                                                totalOverride="Points"
                                                categories={[]}
                                            />
                                        )}
                                    <div className="tbody">
                                        {item.players.length > 0 && (
                                            <>
                                                {teamHidden(item) ? (
                                                    <Row>
                                                        <Col className="text-center p-4">
                                                            Picks are hidden
                                                            until teams are
                                                            locked
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    item.players.map(
                                                        (
                                                            player: ConfidenceTeam
                                                        ) => {
                                                            return (
                                                                <ConfidencePlayerRow
                                                                    key={
                                                                        player.id
                                                                    }
                                                                    player={
                                                                        player
                                                                    }
                                                                    currentEpisode={
                                                                        show.current_episode
                                                                    }
                                                                    eliminatedOnly={
                                                                        eliminatedOnly
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )
                                                )}
                                            </>
                                        )}
                                        {item.players.length <= 0 &&
                                            teamHidden(item) && (
                                                <Row>
                                                    <Col className="text-center p-4">
                                                        Picks are hidden until
                                                        teams are locked
                                                    </Col>
                                                </Row>
                                            )}
                                        {item.players.length <= 0 &&
                                            teamHidden(item) === false && (
                                                <Row>
                                                    <Col className="text-center p-4">
                                                        No picks were made
                                                    </Col>
                                                </Row>
                                            )}
                                    </div>
                                </LeagueTableStyled>
                            </AccordionDetails>
                        </AccordionStyled>
                    );
                })}
            {confidencePool.length === 0 && (
                <div className="text-center">No picks were made</div>
            )}
        </>
    );
};

export default WeeklyConfidenceTeamItem;
