import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";

import { RootState } from "../../store";
import { imageUrl } from "../../config";
import { League } from "../../models/Leagues";
import { Show } from "../../models/Shows";
import { Starters } from "../../models/TeamLeagueTeams";
import { LeagueUser, Team, WeeklyTeam } from "../../models/LeagueUsers";

import PlayerTableHeader from "./PlayerTableHeader";
import PlayerRow from "./PlayerRow";
import PlayerRowData from "../atoms/PlayerRowData";
import { AccordionStyled, Strikethrough } from "../atoms";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";
import { Col, Row } from "react-bootstrap";
import CastTeam from "./CastTeam";

interface WeeklyTeamItemProps {
    league: League;
    show: Show;
    leagueUser: LeagueUser;
    team: WeeklyTeam[];
    categories: string[];
    commissioner: boolean;
    starters: Starters[];
    setSelectedEpisode: (episode: number) => void;
    setShowAddPlayer: (show: boolean) => void;
    setSelectedPlayer: (player: Team) => void;
    setShowDeleteModal: (show: boolean) => void;
}

const WeeklyTeamItem: React.FC<WeeklyTeamItemProps> = ({
    league,
    show,
    leagueUser,
    team,
    categories,
    starters,
    commissioner,
    setSelectedPlayer,
    setSelectedEpisode,
    setShowAddPlayer,
    setShowDeleteModal,
}) => {
    const history = useHistory();
    const user = useSelector((state: RootState) => state.user);

    const showFAB = (item: WeeklyTeam) => {
        if (
            commissioner &&
            league.active &&
            ((!show.locked && item.episode < show.current_episode) ||
                (show.locked && item.episode <= show.current_episode))
        ) {
            return false;
        } else {
            return true;
        }
    };

    const teamHidden = (item: WeeklyTeam) => {
        const coOwners =
            leagueUser.co_owners !== null
                ? leagueUser.co_owners.split(",")
                : [];
        if (show.current_episode === item.episode && !show.locked) {
            if (coOwners.some((item) => user.id.toString() === item)) {
                return false;
            } else if (leagueUser.user_id.toString() === user.id.toString()) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    const calculateTotal = (players: Team[]): number => {
        let total = 0;

        players.forEach((player) => {
            total += player.total;
        });

        return total;
    };

    return (
        <>
            {team.length > 0 &&
                team.map((item: WeeklyTeam) => {
                    return (
                        <AccordionStyled
                            defaultExpanded={
                                item.episode === show.current_episode
                            }
                            key={item.episode}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                {show.type === "week" ? "Week" : "Episode"}{" "}
                                {item.episode}{" "}
                                <Fab
                                    hidden={showFAB(item)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedEpisode(item.episode);
                                        setShowAddPlayer(true);
                                    }}
                                    className="ml-3"
                                    size="small"
                                    style={{
                                        background: "#198754",
                                        color: "#fff",
                                    }}
                                >
                                    <AddIcon />
                                </Fab>
                                <div
                                    style={{ flex: 1, fontSize: 18 }}
                                    className="text-right pr-3"
                                >
                                    {calculateTotal(item.players)}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LeagueTableStyled>
                                    {!teamHidden(item) &&
                                        (item.players.length !== 0 ||
                                            starters.length !== 0) && (
                                            <PlayerTableHeader
                                                categories={categories}
                                            />
                                        )}
                                    <div className="tbody">
                                        {item.players.length > 0 && (
                                            <>
                                                {teamHidden(item) ? (
                                                    <Row>
                                                        <Col className="text-center p-4">
                                                            Team Hidden Until
                                                            Teams Are Locked
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    item.players.map(
                                                        (
                                                            player: Team,
                                                            i: number
                                                        ) => {
                                                            return (
                                                                <PlayerRow
                                                                    key={i}
                                                                    episode={
                                                                        player.episode ??
                                                                        player.episode_id
                                                                    }
                                                                    player={
                                                                        player
                                                                    }
                                                                    league={
                                                                        league
                                                                    }
                                                                    currentEpisode={
                                                                        show.current_episode
                                                                    }
                                                                    commissioner={
                                                                        commissioner
                                                                    }
                                                                    onDeleteClick={(
                                                                        e: any
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setSelectedEpisode(
                                                                            item.episode
                                                                        );
                                                                        setSelectedPlayer(
                                                                            player
                                                                        );
                                                                        setShowDeleteModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    )
                                                )}
                                            </>
                                        )}
                                        {item.players.length <= 0 &&
                                            show.current_episode ===
                                                item.episode &&
                                            !teamHidden(item) &&
                                            starters.length > 0 &&
                                            starters.map((starter) => (
                                                <Row
                                                    className="p-3 align-items-center"
                                                    key={starter.cast_id}
                                                    onClick={() =>
                                                        history.push(
                                                            `/shows/castscores/${starter.cast_id}?league_id=${league.id}`
                                                        )
                                                    }
                                                >
                                                    <Col>
                                                        <Avatar
                                                            src={`${imageUrl}${starter.pic}`}
                                                        />
                                                        <PlayerRowData
                                                            player={starter}
                                                            eliminated={
                                                                starter.episode_eliminated !==
                                                                    null &&
                                                                starter.episode_eliminated <
                                                                    show.current_episode
                                                            }
                                                            value={starter.name}
                                                            league={league}
                                                            errorOverride={
                                                                <>
                                                                    <Strikethrough>
                                                                        {
                                                                            starter.name
                                                                        }
                                                                    </Strikethrough>{" "}
                                                                    (Eliminated){" "}
                                                                </>
                                                            }
                                                        />
                                                        <CastTeam
                                                            left
                                                            size={10}
                                                            team={
                                                                starter.cast
                                                                    ? starter
                                                                          .cast
                                                                          .team
                                                                    : undefined
                                                            }
                                                            partner={
                                                                starter.cast
                                                                    ? starter
                                                                          .cast
                                                                          .partner
                                                                    : undefined
                                                            }
                                                        />
                                                    </Col>
                                                    {categories.map(
                                                        (category: string) => (
                                                            <Col
                                                                key={category}
                                                                className="text-center d-none d-md-table-cell"
                                                            >
                                                                <PlayerRowData
                                                                    player={
                                                                        starter
                                                                    }
                                                                    eliminated={
                                                                        starter.episode_eliminated !==
                                                                            null &&
                                                                        starter.episode_eliminated <
                                                                            show.current_episode
                                                                    }
                                                                    value={0}
                                                                    league={
                                                                        league
                                                                    }
                                                                />
                                                            </Col>
                                                        )
                                                    )}
                                                    <Col className="text-right pr-5">
                                                        <PlayerRowData
                                                            player={starter}
                                                            eliminated={
                                                                starter.episode_eliminated !==
                                                                    null &&
                                                                starter.episode_eliminated <
                                                                    show.current_episode
                                                            }
                                                            value={0}
                                                            league={league}
                                                        />
                                                    </Col>
                                                </Row>
                                            ))}
                                        {item.players.length <= 0 &&
                                            teamHidden(item) && (
                                                <Row>
                                                    <Col className="text-center p-4">
                                                        Team Hidden Until Teams
                                                        Are Locked
                                                    </Col>
                                                </Row>
                                            )}
                                        {item.players.length <= 0 &&
                                            starters.length <= 0 &&
                                            teamHidden(item) === false && (
                                                <Row>
                                                    <Col className="text-center p-4">
                                                        No Team Chosen
                                                    </Col>
                                                </Row>
                                            )}
                                    </div>
                                </LeagueTableStyled>
                            </AccordionDetails>
                        </AccordionStyled>
                    );
                })}
            {team.length === 0 && (
                <div className="text-center">No Weekly Scoring</div>
            )}
        </>
    );
};

export default WeeklyTeamItem;
