import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LoggedScoresReturn } from "../../models/ScoringLog";
import { QuestionAnswers } from "../../models/QuestionAnswers";
import { QuestionAnswerCast } from "../../models/Questions";

import Form from "react-bootstrap/Form";

import { TableSkeleton } from "../atoms/Skeletons";
import Switch from "../atoms/Switch";
import SubmitButton from "../atoms/SubmitButton";
import LogScoresEpisodeSelect from "../atoms/LogScoresEpisodeSelect";
import LogScoresAccordion from "../molecules/LogScoresAccordion";
import PageTemplate from "../templates/PageTemplate";
import LogQuestions from "../molecules/LogQuestions";

const validationSchema = yup.object({
    log: yup.array().of(
        yup.object().shape({
            id: yup.number(),
            name: yup.string(),
            eliminated: yup.boolean(),
            scores: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    description: yup.string(),
                    amount: yup.number(),
                    value: yup.number(),
                })
            ),
        })
    ),
    sendEmail: yup.boolean().optional().nullable(),
});

interface ParamTypes {
    showId: string;
    episodeParam?: string;
}

const AdminLogScores: React.FC = () => {
    const history = useHistory();
    const { showId, episodeParam } = useParams<ParamTypes>();

    const [show, setShow] = useState<Show>();
    const [loading, setLoading] = useState<boolean>(true);
    const [log, setLog] = useState<LoggedScoresReturn[]>();
    const [questions, setQuestions] = useState<QuestionAnswers[]>([]);
    const episode = episodeParam ? episodeParam : 0;

    //could send through type to see if we need to grab custom or regular
    const [submitting, setSubmitting] = useState<boolean>(false);

    const showRequest = useApi(apiRoutes.GET_SHOW(showId), {
        responseKey: "show",
        onSuccess: (response: Show) => {
            setShow(response);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const loggedScoresRequest = useApi(apiRoutes.GET_LOGGED_SCORES(showId), {
        onSuccess: (response: {
            cast: LoggedScoresReturn[];
            show: Show;
            questions: QuestionAnswers[];
        }) => {
            setLog(response.cast);
            setShow(response.show);
            setQuestions(response.questions);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const saveLoggedScoresRequest = useApi(apiRoutes.SAVE_LOGGED_SCORES(), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setSubmitting(false);
            history.push(`/admin/scores`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });

    useEffect(() => {
        if (parseInt(episode.toString()) > 0) {
            loggedScoresRequest.request({ episode });
        } else {
            showRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [episode]);

    const initialValues = {
        log,
        questions,
        sendEmail: true,
    };

    return (
        <PageTemplate
            header="Log Scores"
            headerSecondary={show && show.show}
            loading={loading}
            skeleton={<TableSkeleton size={2} />}
        >
            {show && (
                <>
                    <LogScoresEpisodeSelect show={show} episode={episode} />
                    {parseInt(episode.toString()) > 0 && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                setSubmitting(true);
                                saveLoggedScoresRequest.request({
                                    data: values.log,
                                    questions: values.questions,
                                    episode: parseInt(episode.toString()),
                                    show_id: parseInt(showId),
                                    send_email: values.sendEmail,
                                });
                            }}
                        >
                            {({
                                values,
                                handleSubmit,
                                isValid,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            if (!isValid) {
                                                //toast.error("Please fix the errors");
                                            }
                                            handleSubmit(e);
                                        }}
                                    >
                                        <Switch
                                            name="sendEmail"
                                            label="Send Email"
                                        />
                                        <LogQuestions
                                            cast={log}
                                            questions={values.questions}
                                            values={values}
                                            setValues={(
                                                list: QuestionAnswerCast[],
                                                questionId: number
                                            ) => {
                                                const newQuestions =
                                                    values.questions.map((q) =>
                                                        q.question_id ===
                                                        questionId
                                                            ? {
                                                                  ...q,
                                                                  answers: list,
                                                              }
                                                            : { ...q }
                                                    );
                                                setFieldValue(
                                                    "questions",
                                                    newQuestions
                                                );
                                            }}
                                        />
                                        <LogScoresAccordion
                                            values={values}
                                            log={log}
                                        />
                                        <div className="text-center">
                                            <SubmitButton
                                                title="Submit Scores"
                                                submitting={submitting}
                                            />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default AdminLogScores;
