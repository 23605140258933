const sortArray = <T>(
    arr: T[],
    key: keyof T,
    isNumeric = false,
    isReverse = false
): T[] => {
    return [...arr].sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];

        if (isNumeric) {
            if (isReverse) {
                return (
                    (parseFloat(aValue as any) || 0) -
                    (parseFloat(bValue as any) || 0)
                );
            } else {
                return (
                    (parseFloat(bValue as any) || 0) -
                    (parseFloat(aValue as any) || 0)
                );
            }
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue);
        }

        return 0; // Default if types don't match
    });
};

export const handleSortChange = (
    value: string,
    data: any[],
    setData: (data: any[]) => void,
    type:
        | "confidence"
        | "weeklyCast"
        | "team"
        | "weeklyBudgetCast"
        | "selectTeam"
        | "draft",
    isReverse = false
) => {
    let key: string = "first_name"; // Default sorting key
    let isNumeric = false;

    switch (type) {
        case "confidence":
            if (value === "secondary") {
                key = "average";
                isNumeric = true;
            } else {
                key = "points";
                isNumeric = true;
                isReverse = false;
            }
            break;
        case "weeklyCast":
            if (value === "secondary") {
                key = "weekly_started";
                isNumeric = true;
            } else if (value === "tertiary") {
                key = "weekly_started_league";
                isNumeric = true;
            } else if (value === "fourth") {
                key = "average";
                isNumeric = true;
            }
            break;
        case "weeklyBudgetCast":
            if (value === "secondary") {
                key = "weekly_budget_started";
                isNumeric = true;
            } else if (value === "tertiary") {
                key = "weekly_budget_started_league";
                isNumeric = true;
            } else if (value === "fourth") {
                key = "average";
                isNumeric = true;
            } else if (value === "cost") {
                key = "price";
                isNumeric = true;
            }
            break;
        case "team":
            if (value === "secondary") {
                key = "team_started";
                isNumeric = true;
            } else if (value === "tertiary") {
                key = "team_started_league";
                isNumeric = true;
            } else if (value === "fourth") {
                key = "average";
                isNumeric = true;
            }
            break;
        case "selectTeam":
            if (value === "secondary") {
                key = "team_owned";
                isNumeric = true;
            } else if (value === "tertiary") {
                key = "team_owned_league";
                isNumeric = true;
            }
            break;
        case "draft":
            if (value === "secondary") {
                key = "average_draft_position";
                isNumeric = true;
            }
            break;
    }

    setData(
        sortArray(data, key as keyof (typeof data)[0], isNumeric, isReverse)
    );
};
