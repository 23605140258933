import React from "react";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Badge, Col } from "react-bootstrap";

import { imageUrl } from "../../config";
import { TeamLeagueTeams } from "../../models/TeamLeagueTeams";
import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";

import { ErrorText, Strikethrough } from "../atoms";
import { LeagueTableTeamStyled } from "../atoms/styles/Table.styled";
import CastTeam from "./CastTeam";
import { League } from "../../models/Leagues";

interface TeamLineupListItemProps {
    league: League;
    player: TeamLeagueTeams;
    swap: (id: number) => void;
    mainMovingId: number;
    secondaryMovingId: number;
    setMovingId: (id: number) => void;
    currentEpisode: number;
    locked?: boolean;
}

const TeamLineupListItem: React.FC<TeamLineupListItemProps> = ({
    league,
    player,
    swap,
    mainMovingId,
    secondaryMovingId,
    setMovingId,
    currentEpisode,
    locked,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <LeagueTableTeamStyled>
            <div className="tbody">
                <Row className="p-3 align-items-center">
                    <Col className="pl-4">
                        <Row className="align-items-center pl-3">
                            <Avatar
                                style={{
                                    height: 60,
                                    width: 60,
                                    marginRight: 10,
                                }}
                                src={`${imageUrl}${player.pic}`}
                            />
                            <div>
                                {player.episode_eliminated !== null &&
                                player.episode_eliminated < currentEpisode ? (
                                    <ErrorText>
                                        <Strikethrough>{`${
                                            player.first_name &&
                                            player.first_name
                                        } ${
                                            player.last_name && player.last_name
                                        }`}</Strikethrough>{" "}
                                        (Eliminated)
                                    </ErrorText>
                                ) : (
                                    <span
                                        style={{ color: theme.textColor }}
                                    >{`${
                                        player.first_name && player.first_name
                                    } ${
                                        player.last_name && player.last_name
                                    }`}</span>
                                )}
                                <div className="mb-1">
                                    {league?.rookie_limit !== undefined &&
                                        league.rookie_limit !== null &&
                                        league.rookie_limit !== 0 &&
                                        player?.rookie && (
                                            <Badge pill variant="dark">
                                                Rookie
                                            </Badge>
                                        )}
                                </div>
                                <CastTeam
                                    left
                                    size={10}
                                    team={player.team}
                                    partner={player.partner}
                                />
                            </div>
                        </Row>
                        {subscription && hasPremiumSub(subscription.name) && (
                            <div
                                className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none pt-2"
                                style={{ fontSize: 14 }}
                            >
                                {player.average && (
                                    <>
                                        Average Score:{" "}
                                        <b>{player.average.toFixed(2)}</b>
                                    </>
                                )}
                                <br />
                                Starting <small>(site wide)</small>:{" "}
                                <b>{player.team_started}%</b>
                                <br />
                                Starting <small>(this league)</small>:{" "}
                                <b>{player.team_started_league}%</b>
                            </div>
                        )}
                    </Col>
                    {subscription && hasPremiumSub(subscription.name) && (
                        <Col
                            className="d-none d-md-table-cell text-center"
                            style={{
                                color: theme.textColor,
                            }}
                        >
                            {player.average && (
                                <>
                                    Average Score:{" "}
                                    <b>{player.average.toFixed(2)}</b>
                                    <br />
                                </>
                            )}
                            Starting <small>(site wide)</small>:{" "}
                            <b>{player.team_started}%</b>
                            <br />
                            Starting <small>(this league)</small>:{" "}
                            <b>{player.team_started_league}%</b>
                        </Col>
                    )}
                    <Col className="text-right" style={{ width: 100 }}>
                        {mainMovingId > 0 ? (
                            <Button
                                disabled={locked}
                                onClick={() => swap(player.id)}
                            >
                                Here
                            </Button>
                        ) : (
                            <>
                                {secondaryMovingId === player.id && (
                                    <Button
                                        disabled={locked}
                                        onClick={() => setMovingId(0)}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                {secondaryMovingId !== player.id &&
                                    secondaryMovingId !== 0 && (
                                        <Button disabled>Move</Button>
                                    )}
                                {secondaryMovingId === 0 && (
                                    <Button
                                        disabled={locked}
                                        onClick={() => setMovingId(player.id)}
                                    >
                                        Move
                                    </Button>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </LeagueTableTeamStyled>
    );
};

export default TeamLineupListItem;
