import { ApiReturnType } from "./";

const questionRoutes = {
    GET_LEAGUE_QUESTIONS: (leagueId: string, showId: string): ApiReturnType => {
        return {
            url: `v2/questions/byLeague/${leagueId}/${showId}`,
            method: "get",
        };
    },
};

export default questionRoutes;
