import React from "react";

import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FaTiktok } from "react-icons/fa";

import { Socials } from "../../models/Socials";

interface SocialLinksProps {
    socials: Socials;
    size?: "inherit" | "large" | "medium" | "small";
}

const SocialLinks: React.FC<SocialLinksProps> = ({ socials, size }) => {
    function windowOpen(url: string) {
        if (!url.match(/^https?:\/\//i)) {
            url = "https://" + url;
        }
        return window.open(url);
    }
    return (
        <div>
            {socials.podcast && socials.podcast.length > 0 && (
                <PodcastsIcon
                    style={{
                        cursor: "pointer",
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                    fontSize={size}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        windowOpen(socials.podcast);
                    }}
                />
            )}
            {socials.facebook && socials.facebook.length > 0 && (
                <FacebookIcon
                    style={{
                        cursor: "pointer",
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                    fontSize={size}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        windowOpen(socials.facebook);
                    }}
                />
            )}
            {socials.instagram && socials.instagram.length > 0 && (
                <InstagramIcon
                    style={{
                        cursor: "pointer",
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                    fontSize={size}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        windowOpen(socials.instagram);
                    }}
                />
            )}
            {socials.twitter && socials.twitter.length > 0 && (
                <XIcon
                    style={{
                        cursor: "pointer",
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                    fontSize={size}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        windowOpen(socials.twitter);
                    }}
                />
            )}
            {socials.tiktok && socials.tiktok.length > 0 && (
                <FaTiktok
                    style={{
                        cursor: "pointer",
                        marginRight: 3,
                        marginLeft: 3,
                    }}
                    fontSize={
                        size === "large" ? 25 : size === "medium" ? 18 : 10
                    }
                    onClick={(e: any) => {
                        e.stopPropagation();
                        windowOpen(socials.tiktok);
                    }}
                />
            )}
        </div>
    );
};

export default React.memo(SocialLinks);
