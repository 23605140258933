import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";

import Select from "../atoms/Select";
import SubmitButton from "../atoms/SubmitButton";
import { InputSkeleton } from "../atoms/Skeletons";
import { ModalStyled } from "../atoms";

interface AddQuestionPlayerModalProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    showId: number;
    onSubmit: (cast: Cast) => void;
    submitting?: boolean;
}

const validationSchema = yup.object({
    playerId: yup
        .number()
        .moreThan(0, "Please Select Player")
        .required("Please Select Player"),
});

const AddQuestionPlayerModal: React.FC<AddQuestionPlayerModalProps> = ({
    visible,
    setVisible,
    showId,
    onSubmit,
    submitting,
}) => {
    const [players, setPlayers] = useState<Cast[]>();
    const [error, setError] = useState<string>("");

    const castRequest = useApi(apiRoutes.GET_ACTIVE_CAST(showId.toString()), {
        responseKey: "cast",
        onSuccess: (response: Cast[]) => {
            if (response.length > 0) {
                setPlayers(response);
            } else {
                setPlayers([]);
            }
        },
    });

    useEffect(() => {
        if (visible) {
            castRequest.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const initialValues = {
        playerId: 0,
    };

    return (
        <ModalStyled
            show={visible}
            onHide={() => setVisible(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Select Player</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        if (values.playerId > 0) {
                            const player = players.filter((p) => {
                                return (
                                    p.id.toString() ===
                                    values.playerId.toString()
                                );
                            })[0];
                            onSubmit(player);
                        } else {
                            setError("Please select a Player");
                        }
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            {error.length > 0 && (
                                <Alert variant="danger">{error}</Alert>
                            )}
                            {castRequest.loading && <InputSkeleton />}
                            {!castRequest.loading &&
                                players &&
                                error.length === 0 && (
                                    <>
                                        <Select
                                            name="playerId"
                                            defaultKey={0}
                                            defaultValue="Choose Player"
                                            selectKey="id"
                                            selectValue="name"
                                            items={players}
                                        />
                                        <SubmitButton
                                            submitting={submitting}
                                            submitText="Selecting"
                                            title="Select Player"
                                        />{" "}
                                    </>
                                )}
                            <Button
                                variant="light"
                                onClick={() => setVisible(false)}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default AddQuestionPlayerModal;
