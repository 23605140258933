import React from "react";

import { QuestionScore } from "../../models/QuestionScores";

import { Col, Row } from "react-bootstrap";

import CastImage from "../atoms/CastImage";
import { PlayerName } from "./styles/EliminatedPlayers.styled";
import CastTeam from "./CastTeam";

interface ChallengeQuestionPicksProps {
    score: QuestionScore;
}

const ChallengeQuestionPicks: React.FC<ChallengeQuestionPicksProps> = ({
    score,
}) => {
    return (
        <div>
            <h5>Your Picks:</h5>
            {score.picks.length > 0 ? (
                <>
                    {score.picks.map((player) => {
                        //check to see if this player was correct
                        const picked = score.scores.filter((scorePlayer) => {
                            return scorePlayer.cast_id === player.cast_id;
                        })[0];
                        return (
                            <Row
                                key={player.cast_id}
                                className="align-items-center"
                            >
                                <Col xs={8}>
                                    <Row className="align-items-center mb-2">
                                        <CastImage src={player.pic} />
                                        <div className="ml-2">
                                            <PlayerName>
                                                {` ${player.first_name} ${
                                                    player.last_name
                                                        ? player.last_name
                                                        : ""
                                                }`}
                                            </PlayerName>
                                            <CastTeam
                                                left
                                                size={10}
                                                team={
                                                    player.cast
                                                        ? player.cast.team
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    </Row>
                                </Col>
                                <Col className="text-right" xs={4}>
                                    {picked !== undefined ? score.score : 0}
                                </Col>
                            </Row>
                        );
                    })}
                </>
            ) : (
                <b>No picks made</b>
            )}
        </div>
    );
};

export default ChallengeQuestionPicks;
