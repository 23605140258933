import React, { useState } from "react";

import { League } from "../../models/Leagues";

import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

interface LeagueAlertsProps {
    commissionerNote: string | null;
    league: League;
    winner: string;
}

const LeagueAlerts: React.FC<LeagueAlertsProps> = ({
    commissionerNote,
    league,
    winner,
}) => {
    const [showMoreNote, setShowMoreNote] = useState<boolean>(false);

    return (
        <>
            {commissionerNote &&
                commissionerNote.length > 0 &&
                league.active && (
                    <Col>
                        <Alert variant={"warning"}>
                            {commissionerNote.length > 150 ? (
                                <>
                                    {showMoreNote ? (
                                        <>
                                            {commissionerNote}{" "}
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    color: "#0495ee",
                                                }}
                                                onClick={() =>
                                                    setShowMoreNote(false)
                                                }
                                            >
                                                show less
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {commissionerNote.substring(0, 150)}
                                            {"... "}
                                            <span
                                                style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    color: "#0495ee",
                                                }}
                                                onClick={() =>
                                                    setShowMoreNote(true)
                                                }
                                            >
                                                show more
                                            </span>
                                        </>
                                    )}
                                </>
                            ) : (
                                commissionerNote
                            )}
                        </Alert>
                    </Col>
                )}
            {!league.active && winner && (
                <Col>
                    <Alert variant="success">
                        This league has completed! Congratulations to{" "}
                        <b>{winner}</b> for winning!
                    </Alert>
                </Col>
            )}
        </>
    );
};

export default LeagueAlerts;
