import React from "react";
import { Link } from "react-router-dom";

import PageTemplate from "../templates/PageTemplate";

const About: React.FC = () => {
    return (
        <PageTemplate header="About RealTVFantasy">
            <p>
                RealTVFantasy enhances your reality TV experience by letting you
                play fantasy games with your friends while watching your
                favorite shows.
            </p>
            <p>
                Create a league for any of the shows available on our platform,
                customize the scoring categories to your liking, and earn points
                based on what happens in each episode. Scores are updated within
                24-48 hours after an episode airs.
            </p>
            <p>
                We're always looking to improve! If you have suggestions for new
                shows, scoring adjustments, or general site improvements, we'd
                love to hear from you — <Link to="/contact">contact us</Link>.
            </p>
        </PageTemplate>
    );
};

export default About;
