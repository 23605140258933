import React from "react";
import { useSelector } from "react-redux";

import { Question } from "../../models/Questions";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";
import { LeagueUser } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import { RootState } from "../../store";
import { League } from "../../models/Leagues";

import ChallengeQuestionListHeader from "../molecules/ChallengeQuestionListHeader";
import ChallengeQuestionRow from "../molecules/ChallengeQuestionRow";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";
import { Col, Row } from "react-bootstrap";

interface ChallengeQuestionListProps {
    questions: Question[];
    responses: QuestionAnswerResponse[];
    episode: number;
    leagueUser: LeagueUser;
    league: League;
    show: Show;
}

const ChallengeQuestionList: React.FC<ChallengeQuestionListProps> = ({
    questions,
    responses,
    episode,
    leagueUser,
    show,
    league,
}) => {
    const user = useSelector((state: RootState) => state.user);

    const teamHidden = (question: Question) => {
        const coOwners =
            leagueUser.co_owners !== null
                ? leagueUser.co_owners.split(",")
                : [];

        if (show.locked) return false;

        // Check if the current user is allowed to see the team
        const isUserAllowed = () =>
            coOwners.some((item) => user.id.toString() === item) ||
            leagueUser.user_id.toString() === user.id.toString();

        if (question.type === "Season") {
            const isBeforeNextEpisode =
                league.episode_started + 1 >= show.current_episode;
            return isBeforeNextEpisode && !isUserAllowed();
        } else {
            const isCurrentEpisode = show.current_episode === episode;
            return isCurrentEpisode && !isUserAllowed();
        }
    };

    return (
        <>
            {questions.map((q, index) => {
                const userAnswers = responses.filter((sq) => {
                    return sq.question_id === q.id && sq.episode === episode;
                });

                return (
                    <div className="mt-3" key={index}>
                        <ChallengeQuestionListHeader
                            question={q}
                            episode={episode}
                        />
                        <LeagueTableStyled>
                            <div className="header">
                                <Row>
                                    <Col className="text-center">Answer(s)</Col>
                                    <Col className="text-center">Points</Col>
                                </Row>
                            </div>
                            <div className="tbody">
                                {teamHidden(q) ? (
                                    <Row>
                                        <Col className="text-center p-3">
                                            Answers hidden until show is locked
                                            for the{" "}
                                            {show.type === "week"
                                                ? "week"
                                                : "episode"}
                                        </Col>
                                    </Row>
                                ) : (
                                    <>
                                        {userAnswers.length <= 0 && (
                                            <Row>
                                                <Col className="text-center p-3">
                                                    No answers
                                                </Col>
                                            </Row>
                                        )}
                                        {userAnswers.length > 0 &&
                                            userAnswers.map((ua, i) => {
                                                return (
                                                    <ChallengeQuestionRow
                                                        key={i}
                                                        score={q.score}
                                                        userAnswer={ua}
                                                        episode={episode}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                            </div>
                        </LeagueTableStyled>
                    </div>
                );
            })}
        </>
    );
};

export default ChallengeQuestionList;
