import React from "react";
import { useTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import { Col, Row } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { imageUrl } from "../../config";
import { League } from "../../models/Leagues";
import { Show } from "../../models/Shows";

import { WeeklyCast } from "../organisms/SetWeeklyLineup";
import { LeagueTableTeamStyled } from "../atoms/styles/Table.styled";
import CastTeam from "./CastTeam";

interface WeeklyListItemProps {
    player: WeeklyCast;
    startingCast: WeeklyCast[];
    league: League;
    cast?: WeeklyCast[];
    removePlayer: (id: number) => void;
    addPlayer: (player: WeeklyCast) => void;
    show: Show;
    budget?: boolean;
    remainingBudget?: number;
}

const WeeklyListItem: React.FC<WeeklyListItemProps> = ({
    startingCast,
    player,
    league,
    cast,
    removePlayer,
    addPlayer,
    show,
    remainingBudget,
    budget,
}) => {
    const theme = useTheme();
    const subscription = useSelector((state: RootState) => state.subscription);

    const isStarting = (id: number) => {
        const isStarting = startingCast.filter(
            (cast: WeeklyCast) => cast.id === id
        );
        if (isStarting.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    const playersPerTeam = (): number => {
        if (league.draft_type === "Weekly Redraft") {
            if (cast && league.players_per_team >= cast.length) {
                if (cast.length === 1) {
                    return 1;
                } else {
                    return cast.length - 1;
                }
            }
        }

        return league.players_per_team;
    };

    return (
        <LeagueTableTeamStyled
            className={isStarting(player.id) ? "selected" : ""}
        >
            <div className="tbody">
                <Row className="p-3 align-items-center">
                    <Col className="pl-4">
                        <Row className="align-items-center pl-3">
                            <Avatar
                                style={{
                                    height: 60,
                                    width: 60,
                                    marginRight: 10,
                                }}
                                src={`${imageUrl}${player.pic}`}
                            />
                            <div className="mb-2">
                                <div style={{ color: theme.textColor }}>{`${
                                    player.first_name && player.first_name
                                } ${
                                    player.last_name && player.last_name
                                }`}</div>
                                <CastTeam
                                    left
                                    size={10}
                                    team={player.team}
                                    partner={player.partner}
                                />
                                {league.player_limit ? (
                                    <div
                                        style={{
                                            color: theme.textColor,
                                            fontSize: 12,
                                        }}
                                    >{`Times Selected Remaining: ${player.remaining}`}</div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Row>
                        {subscription && hasPremiumSub(subscription.name) && (
                            <div
                                className="d-sm-block d-xs-block d-md-none d-lg-none d-xl-none pt-2"
                                style={{ fontSize: 14 }}
                            >
                                {player.average && (
                                    <>
                                        Average Score:{" "}
                                        <b>{player.average.toFixed(2)}</b>
                                        <br />
                                    </>
                                )}
                                Starting <small>(site wide)</small>:{" "}
                                <b>
                                    {budget
                                        ? player.weekly_budget_started
                                        : player.weekly_started}
                                    %
                                </b>
                                <br />
                                Starting <small>(this league)</small>:{" "}
                                <b>
                                    {budget
                                        ? player.weekly_budget_started_league
                                        : player.weekly_started_league}
                                    %
                                </b>
                            </div>
                        )}
                    </Col>
                    {subscription && hasPremiumSub(subscription.name) && (
                        <Col
                            className="d-none d-md-table-cell text-center"
                            style={{
                                color: theme.textColor,
                            }}
                        >
                            {player.average && (
                                <>
                                    Average Score:{" "}
                                    <b>{player.average.toFixed(2)}</b>
                                    <br />
                                </>
                            )}
                            Starting <small>(site wide)</small>:{" "}
                            <b>
                                {budget
                                    ? player.weekly_budget_started
                                    : player.weekly_started}
                                %
                            </b>
                            <br />
                            Starting <small>(this league)</small>:{" "}
                            <b>
                                {budget
                                    ? player.weekly_budget_started_league
                                    : player.weekly_started_league}
                                %
                            </b>
                        </Col>
                    )}
                    <Col className="text-right" style={{ width: 120 }}>
                        <Row
                            className="align-items-center"
                            style={{
                                justifyContent: "flex-end",
                                marginRight: 15,
                            }}
                        >
                            {league.draft_type === "Weekly Budget" && (
                                <>
                                    <FontAwesomeIcon
                                        fontSize={20}
                                        icon={faDollarSign}
                                    />
                                    <span
                                        className="pr-3"
                                        style={{ fontSize: 20 }}
                                    >
                                        {player.price}
                                    </span>
                                </>
                            )}
                            <Fab
                                disabled={
                                    (!isStarting(player.id) &&
                                        startingCast.length ===
                                            playersPerTeam()) ||
                                    (league.player_limit !== null &&
                                        player.remaining <= 0 &&
                                        !startingCast.filter(
                                            (e) => e.id === player.id
                                        )[0]) ||
                                    show.locked ||
                                    show.current_episode > show.episodes ||
                                    show.current_episode <
                                        league.episode_started ||
                                    (league.draft_type === "Weekly Budget" &&
                                        player.price > remainingBudget &&
                                        !isStarting(player.id))
                                }
                                onClick={() =>
                                    isStarting(player.id)
                                        ? removePlayer(player.id)
                                        : addPlayer(player)
                                }
                                size="small"
                                style={
                                    (!isStarting(player.id) &&
                                        startingCast.length ===
                                            playersPerTeam()) ||
                                    (league.player_limit !== null &&
                                        player.remaining <= 0 &&
                                        !startingCast.filter(
                                            (e) => e.id === player.id
                                        )[0]) ||
                                    show.locked ||
                                    show.current_episode > show.episodes ||
                                    show.current_episode <
                                        league.episode_started ||
                                    (league.draft_type === "Weekly Budget" &&
                                        player.price > remainingBudget &&
                                        !isStarting(player.id))
                                        ? {
                                              background: "#ccc",
                                          }
                                        : {
                                              background: isStarting(player.id)
                                                  ? "#dc3545"
                                                  : "#198754",
                                              color: "#fff",
                                          }
                                }
                            >
                                {isStarting(player.id) ? (
                                    <RemoveIcon />
                                ) : (
                                    <AddIcon />
                                )}
                            </Fab>
                        </Row>
                    </Col>
                </Row>
            </div>
        </LeagueTableTeamStyled>
    );
};

export default WeeklyListItem;
