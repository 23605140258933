import { ApiReturnType } from "./";

const commentRoutes = {
    GET_CONFIDENCE_POOL_TEAM: (id: number): ApiReturnType => {
        return {
            url: `v2/confidencePool/team/${id}`,
            method: "get",
        };
    },
    GET_CURRENT_CONFIDENCE_POOL: (id: string): ApiReturnType => {
        return {
            url: `v2/confidencePool/current/${id}`,
            method: "get",
        };
    },
    SAVE_CONFIDENCE_POOL: (id: string): ApiReturnType => {
        return {
            url: `v2/confidencePool/save/${id}`,
            method: "post",
        };
    },
};

export default commentRoutes;
