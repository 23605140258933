import React, { ChangeEvent } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ImageUpload from "../atoms/ImageUpload";
import Input from "../atoms/Input";

interface SponsoredLeagueFormProps {
    values: any;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const SponsoredLeagueForm: React.FC<SponsoredLeagueFormProps> = ({
    values,
    setFieldValue,
}) => {
    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("img", files[0]);
        }
    };

    return (
        <>
            <ImageUpload
                name="img"
                label="League Image"
                image={values.img}
                handleChange={(e) => onImageChange(e, setFieldValue)}
                formText="The best aspect ratio for league image is a 16:9 ratio so that it displays great for both the web and app."
            />
            <Row>
                <Col xs={6} md={6}>
                    <Input
                        name={"instagram"}
                        label="Instagram:"
                        placeholder="Instagram"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input name={"twitter"} label="X:" placeholder="X" />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={"tiktok"}
                        label="TikTok:"
                        placeholder="TikTok"
                    />
                </Col>
                <Col xs={6} md={6}>
                    <Input
                        name={"podcast"}
                        label="Podcast:"
                        placeholder="Podcast"
                    />
                </Col>
            </Row>
        </>
    );
};

export default SponsoredLeagueForm;
