import React from "react";
import { Col, Row } from "react-bootstrap";

interface PlayerTableHeaderProps {
    categories: string[];
    totalOverride?: string;
}

const PlayerTableHeader: React.FC<PlayerTableHeaderProps> = ({
    categories,
    totalOverride,
}) => {
    return (
        <div className="header font-weight-bold p-3">
            <Row>
                <Col>Player</Col>
                {categories.map((category: string) => {
                    return (
                        <Col
                            key={category}
                            className="text-center d-none d-md-table-cell"
                        >
                            {category}
                        </Col>
                    );
                })}
                <Col className="text-right pr-5">
                    {totalOverride ? totalOverride : "Total"}
                </Col>
            </Row>
        </div>
    );
};

export default PlayerTableHeader;
