import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Cast, CastTeam as CastTeamModel } from "../../models/Cast";

interface CastTeamProps {
    team?: CastTeamModel;
    partner?: Cast;
    size?: number;
    left?: boolean;
}

const CastTeam: React.FC<CastTeamProps> = ({ team, partner, size, left }) => {
    if (!team && !partner) return null;

    return (
        <div
            style={{ fontSize: size ?? 14, color: "#777" }}
            className={`mb-1 d-flex align-items-center ${
                !left && "justify-content-center"
            }`}
        >
            {team?.color && (
                <span
                    style={{
                        backgroundColor: team.color,
                        height: size ?? 14,
                        width: size ?? 14,
                        borderRadius: "25%",
                        display: "inline-block",
                        marginRight: 5,
                        boxShadow: "0px 0px 1px 0px #000",
                    }}
                />
            )}
            {team?.team_name || (
                <>
                    <AccountCircleIcon
                        className="mr-1"
                        style={{
                            height: size ? size + 2 : 15,
                            width: size ? size + 2 : 15,
                        }}
                    />
                    {partner?.first_name} {partner?.last_name}
                </>
            )}
        </div>
    );
};

export default CastTeam;
