import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";

import PageTemplate from "../templates/PageTemplate";

const TradingGuide: React.FC = () => {
    return (
        <PageTemplate header="Trading Guide">
            <Col md={12}>
                <p>
                    <b>How do I propose a trade?</b>
                </p>
                <ul>
                    <li>
                        To propose a trade, navigate to your league's homepage
                        and click Propose Trade. Keep in mind that trades cannot
                        be proposed while shows are locked and scores are still
                        being calculated. Once you click Propose Trade, you'll
                        be prompted to choose a team to trade with. After
                        selecting a team, you'll see both rosters displayed side
                        by side. Select the players from each team that you want
                        to include in the trade. When you're ready, click
                        Finalize Trade. This will send an email and notification
                        to the other team, informing them of your trade offer.
                    </li>
                </ul>
                <p>
                    <b>How do I cancel a proposed trade?</b>
                </p>
                <ul>
                    <li>
                        If you decide to cancel a proposed trade, you can do so
                        easily. Navigate to your league's homepage and click
                        Trades. The number above Trades indicates how many open
                        trades you currently have. On the Trades page, find the
                        trade you want to cancel under Pending Trades and click
                        Cancel. This will notify the other team via email and
                        notification that the trade has been canceled.
                    </li>
                </ul>
                <p>
                    <b>How do I Accept/Decline a trade?</b>
                </p>
                <ul>
                    <li>
                        To accept or decline a trade offer, navigate to your
                        league's homepage and click Trades. Under the Trade
                        Offers section, locate the trade you want to respond to
                        and choose either Accept or Decline. If you accept the
                        trade, an email will be sent to all league members
                        notifying them of the accepted trade. If you decline,
                        the person who proposed the trade will receive an email
                        letting them know it was declined.
                    </li>
                </ul>
                <p>
                    <b>
                        Once I trade a player away, how will their points be
                        reflected on my team?
                    </b>
                </p>
                <ul>
                    <li>
                        When you trade a player away, they will still appear on
                        your team page, but an asterisk (*) next to their name
                        will indicate that they are no longer part of your team.
                        You will retain all the points that player earned for
                        your team up until the time of the trade. However, you
                        will not receive any points they earn in future
                        episodes, as they are no longer on your team.
                    </li>
                </ul>
                <p>
                    <b>
                        Wait, I just accepted a trade and the player I received
                        says that they have 0 points?
                    </b>
                </p>
                <ul>
                    <li>
                        This would be correct. You will only earn points from
                        the player starting with the current episode and any
                        future episodes after the trade is accepted. You will
                        not receive any points they earned in episodes prior to
                        the trade.
                    </li>
                </ul>
                <p>
                    <b>
                        Can a player be traded multiple times during the season?
                    </b>
                </p>
                <ul>
                    <li>
                        Yes, a player can be traded multiple times throughout
                        the season. However, a player can only be traded once
                        per week. For example, if you acquire a player in a
                        trade, you cannot trade that player again during the
                        same week until after scores are recorded.
                    </li>
                </ul>
                <p>
                    <b>
                        Can I trade multiple players for one player and vice
                        versa?
                    </b>
                </p>
                <ul>
                    <li>
                        Yes, you can trade multiple players for one player or
                        even multiple players. The only rule is that each trade
                        must involve at least one player from each side—you
                        cannot trade nothing in exchange for a player.
                    </li>
                </ul>
                <p>
                    <b>Do trades expire?</b>
                </p>
                <ul>
                    <li>
                        Yes, all trades expire when the episode airs and the
                        league locks on the site. This means trades will expire
                        each week once the new episode begins.
                    </li>
                </ul>
                <p>
                    If you have any further questions, send us a message on our{" "}
                    <Link to="/contact">Contact Us</Link> page.
                </p>
            </Col>
        </PageTemplate>
    );
};

export default TradingGuide;
