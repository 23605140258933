import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { TableSkeleton } from "../atoms/Skeletons";
import LinkList from "../molecules/LinkList";
import PageTemplate from "../templates/PageTemplate";

interface List {
    title: string;
    link: string;
    custom: boolean;
}

interface ShowList {
    other: List[];
    active: List[];
}

const AdminShowsList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [list, setList] = useState<ShowList>();
    const [showCustom, setShowCustom] = useState<boolean>(false);
    const getShowsList = useApi(apiRoutes.GET_SHOWS_LIST(), {
        responseKey: "list",
        onSuccess: (response: ShowList) => {
            setList(response);
            setLoading(false);
        },
        onFailure: () => setLoading(false),
    });

    useEffect(() => {
        getShowsList.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredList = (shows: List[]) =>
        showCustom ? shows : shows.filter((show) => !show.custom);

    const renderShowSection = (title: string, shows: List[]) => (
        <div className="mb-4">
            <h4>{title}</h4>
            {shows.length > 0 ? (
                <LinkList list={filteredList(shows)} />
            ) : (
                <span>No {title.toLowerCase()}</span>
            )}
        </div>
    );

    return (
        <PageTemplate
            header="Manage Shows"
            loading={loading}
            skeleton={<TableSkeleton size={2} />}
        >
            <Form.Group>
                <Form.Check
                    id={`switch-custom`}
                    type={"switch"}
                    checked={showCustom}
                    onChange={() => setShowCustom((prev) => !prev)}
                    label="Show Custom Shows"
                />
            </Form.Group>
            {list && renderShowSection("Active Shows", list.active)}
            {list && renderShowSection("Completed Shows", list.other)}
            <Button href={`/admin/shows/add`}>Add New Show</Button>
        </PageTemplate>
    );
};

export default AdminShowsList;
