import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTheme } from "styled-components";

interface RoundHeaderProps {
    round: number;
}

const RoundHeader: React.FC<RoundHeaderProps> = ({ round }) => {
    const theme = useTheme();

    return (
        <Row>
            <Col
                style={{
                    backgroundColor: theme.primary,
                    fontWeight: "bold",
                }}
                className="text-center p-3"
            >
                Round {round}
            </Col>
        </Row>
    );
};

export default RoundHeader;
