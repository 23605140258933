import React, { useState } from "react";
import { toast } from "react-toastify";

import { TradePlayerList, TradesList } from "../../models/Trades";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import TradeTablePlayer from "../atoms/TradeTablePlayer";
import AreYouSureModal from "../atoms/AreYouSureModal";
import AcceptTradeModal from "./AcceptTradeModal";
import { TableDataLarge, TableDataSmall } from "./styles/Trade.styled";

interface TradeTableRowProps {
    data: TradesList;
    type: "offer" | "propose";
    closePanel: () => void;
    reload: () => void;
}

const TradeTableRow: React.FC<TradeTableRowProps> = ({
    data,
    type,
    closePanel,
    reload,
}) => {
    const [selectedTradeId, setSelectedTradeId] = useState<string>();
    const [yourSelectedTeam, setYourSelectedTeam] =
        useState<TradePlayerList[]>();
    const [theirSelectedTeam, setTheirSelectedTeam] =
        useState<TradePlayerList[]>();
    const [yourSelectedIds, setYourSelectedIds] = useState<number[]>();
    const [theirSelectedIds, setTheirSelectedIds] = useState<number[]>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showAccept, setShowAccept] = useState<boolean>(false);
    const [showAys, setShowAys] = useState<boolean>(false);
    const [aysBody, setAysBody] = useState<string>("");

    const removeTradeRequest = useApi(apiRoutes.REMOVE_TRADE(selectedTradeId), {
        responseKey: "message",
        onSuccess: (response: string) => {
            closePanel();
            toast.success(response);
            setSubmitting(false);
            reload();
        },
        onFailure: () => setSubmitting(false),
    });

    const processTradeRequest = useApi(
        apiRoutes.PROCESS_TRADE(selectedTradeId),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                setShowAccept(false);
                toast.success(response);
                setSubmitting(false);
                closePanel();
                reload();
            },
            onFailure: (message: string) => {
                setSubmitting(false);
                setShowAccept(false);
            },
        }
    );

    const acceptTrade = () => {
        setSubmitting(true);
        processTradeRequest.request({
            your_team_ids: yourSelectedIds,
            their_team_ids: theirSelectedIds,
            your_league_user_id: data.your_id,
            their_league_user_id: data.user_id,
        });
    };

    const removeTrade = () => {
        setSubmitting(true);
        if (aysBody === "Are you sure you want to cancel this trade?") {
            //cancelled
            removeTradeRequest.request({
                type: "cancel",
                league_user_id: data.your_id,
                opp_league_user_id: data.user_id,
            });
        } else {
            //declined
            removeTradeRequest.request({
                type: "decline",
                league_user_id: data.your_id,
                opp_league_user_id: data.user_id,
            });
        }
    };

    return (
        <tr>
            <TableDataSmall>{data.user}</TableDataSmall>
            <TableDataLarge>
                <Row>
                    <Col className="my-auto" xs="5">
                        {data.give.map((give, key) => {
                            return <TradeTablePlayer key={key} data={give} />;
                        })}
                    </Col>
                    <Col className="my-auto" xs="2">
                        <SwapHorizIcon />
                    </Col>
                    <Col className="my-auto" xs="5">
                        {data.receive.map((receive, key) => {
                            return (
                                <TradeTablePlayer key={key} data={receive} />
                            );
                        })}
                    </Col>
                </Row>
            </TableDataLarge>
            <TableDataSmall>
                {type === "offer" ? (
                    <>
                        <Button
                            className="mb-2"
                            variant="success"
                            onClick={() => {
                                setSelectedTradeId(data.id);
                                const yourTeam = data.give.map(function (el) {
                                    return el.cast_id;
                                });
                                const theirTeam = data.receive.map(function (
                                    el
                                ) {
                                    return el.cast_id;
                                });
                                setYourSelectedTeam(data.give);
                                setTheirSelectedTeam(data.receive);
                                setYourSelectedIds(yourTeam);
                                setTheirSelectedIds(theirTeam);
                                setShowAccept(true);
                            }}
                        >
                            Accept
                        </Button>
                        <br />
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowAys(true);
                                setAysBody(
                                    "Are you sure you want to decline this trade?"
                                );
                                setSelectedTradeId(data.id);
                            }}
                        >
                            Decline
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="danger"
                        onClick={() => {
                            setShowAys(true);
                            setAysBody(
                                "Are you sure you want to cancel this trade?"
                            );
                            setSelectedTradeId(data.id);
                        }}
                    >
                        Cancel
                    </Button>
                )}
            </TableDataSmall>
            <AreYouSureModal
                showModal={showAys}
                setShowModal={setShowAys}
                body={aysBody}
                onSuccess={removeTrade}
                submitting={submitting}
                submitText="Submitting"
            />
            <AcceptTradeModal
                yourTeam={yourSelectedTeam}
                theirTeam={theirSelectedTeam}
                showModal={showAccept}
                setShowModal={setShowAccept}
                submitting={submitting}
                onSuccess={acceptTrade}
            />
        </tr>
    );
};

export default TradeTableRow;
