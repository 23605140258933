import { ApiReturnType } from "./";

const chatRoutes = {
    DELETE_NOTIFICATIONS_BY_LEAGUE: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/chat/deleteNotifications/${leagueId}`,
            method: "post",
        };
    },
    GET_LEAGUE_CHAT: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/chat/league/${leagueId}`,
            method: "get",
        };
    },
    LIKE_CHAT: (): ApiReturnType => {
        return {
            url: `v2/chat/like`,
            method: "post",
        };
    },
    SEND_CHAT: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/chat/send/${leagueId}`,
            method: "post",
        };
    },
};

export default chatRoutes;
