import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "react-bootstrap/Alert";
import VerifiedIcon from "@mui/icons-material/Verified";

import { imageUrl } from "../../config";
import { colors } from "../../config/colors";
import { Show } from "../../models/Shows";
import { determineTimezone } from "../../helpers/timezones";
import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import {
    Leaderboard,
    LeaderboardList,
    LeaderboardPagination,
} from "../../models/Leaderboard";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { Well } from "../atoms";
import { TableSkeleton } from "../atoms/Skeletons";
import EpisodeFilterSelect from "../atoms/EpisodeFilterSelect";
import AreYouSureModal from "../atoms/AreYouSureModal";
import ProfileOverlay from "../atoms/ProfileOverlay";
import ViewProfile from "../molecules/ViewProfile";
import OverviewRank from "../atoms/OverviewRank";
import { Col, Row } from "react-bootstrap";
import { LeagueTableStyled } from "../atoms/styles/Table.styled";

interface OverviewProps {
    leagueId: string;
    league: League;
    leagueUser: LeaguesLeagueUser;
    show: Show;
    onDeleteTeam: () => void;
}

const PAGE_SIZE = 200;

const Overview: React.FC<OverviewProps> = ({
    leagueId,
    league,
    leagueUser,
    show,
    onDeleteTeam,
}) => {
    const history = useHistory();
    const theme = useTheme();
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );

    const [state, setState] = useState({
        loading: true,
        loaded: false,
        hasMore: false,
        leaderboard: [] as LeaderboardList,
        page: 1,
        episode: "0",
    });
    const [selectedTeam, setSelectedTeam] = useState<Leaderboard>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [profileId, setProfileId] = useState<number>(0);

    const { loading, loaded, hasMore, leaderboard, page, episode } = state;

    const leaderboardRequest = useApi(
        apiRoutes.GET_LEADERBOARD_BY_LEAGUE(leagueId),
        {
            onSuccess: (response: LeaderboardPagination) => {
                const updatedLeaderboard = !loaded ? [] : [...leaderboard];

                let lastRank =
                    updatedLeaderboard.length > 0
                        ? updatedLeaderboard[updatedLeaderboard.length - 1]
                              .rank ?? 0
                        : 0; // Last rank in the current leaderboard
                let lastPoints = updatedLeaderboard.length
                    ? updatedLeaderboard[updatedLeaderboard.length - 1].points
                    : null; // Last points in the current leaderboard

                let tieCount = 0;

                response.leaderboard.forEach((item) => {
                    if (lastPoints !== null && item.points === lastPoints) {
                        item.rank = lastRank;
                        tieCount++;
                    } else {
                        lastRank += tieCount > 1 ? tieCount : 1; // Skip ranks for ties
                        item.rank = lastRank;
                        tieCount = 1; // Reset tie count
                    }
                    lastPoints = item.points;
                    updatedLeaderboard.push(item);
                });

                setState((prev) => ({
                    ...prev,
                    leaderboard: updatedLeaderboard,
                    hasMore: response.has_more,
                    loading: false,
                    loaded: true,
                }));
            },
        }
    );

    const deleteTeamRequest = useApi(
        apiRoutes.DELETE_TEAM(selectedTeam ? selectedTeam.id.toString() : "0"),
        {
            onSuccess: (response: string) => {
                toast.success(response);
                setDeleting(false);
                setShowDeleteModal(false);
                onDeleteTeam();
            },
            onFailure: () => {
                setDeleting(false);
            },
            responseKey: "message",
        }
    );

    useEffect(() => {
        if (loading) {
            const timezones = determineTimezone(timezoneOffsets);
            leaderboardRequest.request({
                show_id: show.id,
                limit: PAGE_SIZE,
                ...(episode !== "0" && { episode }),
                ...timezones,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId, show.id, timezoneOffsets, episode]);

    const fetchNextOverviewData = (loaded: boolean, hasMore: boolean) => {
        if (loaded && hasMore) {
            const timezones = determineTimezone(timezoneOffsets);
            leaderboardRequest
                .request({
                    page: page + 1,
                    limit: PAGE_SIZE,
                    show_id: show.id,
                    episode: episode,
                    ...timezones,
                })
                .then(() => {
                    setState((prev) => ({ ...prev, page: prev.page + 1 }));
                });
        }
    };

    const handleDeleteTeam = () => {
        setDeleting(true);
        deleteTeamRequest.request();
    };

    const renderTableBody = useMemo(() => {
        if (leaderboard.length === 0 && episode === "0" && loaded) {
            return (
                <tr>
                    <td colSpan={4} className="text-center">
                        There are no players currently in this league. Be the
                        first one to join!
                    </td>
                </tr>
            );
        }

        if (episode !== "0" && leaderboard.length <= 0 && loaded) {
            return (
                <tr
                    style={{
                        color: theme.textColor,
                    }}
                    className="mt-4 text-center"
                >
                    <td
                        colSpan={
                            leagueUser &&
                            leagueUser.commissioner &&
                            league.active
                                ? 4
                                : 3
                        }
                    >
                        No Scores for{" "}
                        {show.type === "week" ? "Week" : "Episode"} {episode}
                    </td>
                </tr>
            );
        }

        return leaderboard.map((team: Leaderboard, key: number) => {
            const profilePic = team.profile_pic?.includes("https")
                ? team.profile_pic
                : `${imageUrl}${team.profile_pic}`;
            if (team.rank === undefined && episode === "0") {
                const morePoints = leaderboard.filter((lead: Leaderboard) => {
                    return lead.points > team.points;
                });
                team.rank = morePoints.length + 1;
            }
            const rankColor =
                team.rank === 1
                    ? colors.gold
                    : team.rank === 2
                    ? colors.silver
                    : team.rank === 3
                    ? colors.bronze
                    : colors.white;
            return (
                <Row
                    key={team.id}
                    onClick={() => history.push(`/myleagues/team/${team.id}`)}
                    className={
                        leagueUser?.id === team.id
                            ? "selected align-items-center p-3 row"
                            : "align-items-center p-3 row"
                    }
                >
                    <Col xs={2}>
                        <OverviewRank
                            rank={team.rank ?? key + 1}
                            previousRank={team.previous_rank}
                        />
                    </Col>
                    <Col xs={leagueUser?.commissioner && league.active ? 6 : 7}>
                        <div className="d-flex align-items-center">
                            <ProfileOverlay
                                img={profilePic}
                                showProfile={(visible: boolean) => {
                                    setShowProfile(visible);
                                    setProfileId(team.user_id);
                                }}
                                setLineup={team.set_lineup}
                            />
                            <div>
                                <Link to={`/myleagues/team/${team.id}`}>
                                    {team.team_name}
                                </Link>
                                <div className="d-flex align-items-center">
                                    {team.first_name}{" "}
                                    {team.last_name?.charAt(0)}{" "}
                                    {team.subscription && (
                                        <VerifiedIcon
                                            className="ml-1"
                                            fontSize="small"
                                            style={{
                                                color:
                                                    team.subscription ===
                                                    "Premium"
                                                        ? colors.gold
                                                        : colors.button,
                                            }}
                                        />
                                    )}
                                    {team.commissioner && (
                                        <LocalPoliceIcon
                                            className="ml-1"
                                            color="primary"
                                        />
                                    )}
                                    {!league.active &&
                                    team.rank > 0 &&
                                    team.rank < 4 ? (
                                        <FontAwesomeIcon
                                            className="ml-1"
                                            icon={faTrophy}
                                            color={rankColor}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={leagueUser?.commissioner && league.active ? 2 : 3}
                        className="text-right"
                    >
                        {Number(team.points).toFixed(0)}
                    </Col>
                    {leagueUser?.commissioner && league.active && (
                        <Col xs={2} className="text-right pl-1">
                            <DeleteIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedTeam(team);
                                    setShowDeleteModal(true);
                                }}
                            />
                        </Col>
                    )}
                </Row>
            );
        });
    }, [
        leaderboard,
        episode,
        history,
        leagueUser,
        league,
        loaded,
        theme,
        show,
    ]);

    return (
        <Well id="scrollableDivOverview">
            <EpisodeFilterSelect
                onChange={(e) =>
                    setState((prev) => ({
                        ...prev,
                        episode: e.target.value,
                        loaded: false,
                        page: prev.episode !== e.target.value ? 1 : prev.page,
                        loading: prev.episode !== e.target.value,
                    }))
                }
                episode={parseFloat(episode)}
                show={show}
            />
            {(episode === "0" &&
                show.current_episode < league.episode_started) ||
            (parseInt(episode) < league.episode_started && episode !== "0") ? (
                <Alert variant="warning">
                    This league{" "}
                    {show.current_episode > league.episode_started
                        ? "didn't"
                        : "doesn't"}{" "}
                    start until {show.type === "week" ? "week" : "episode"}{" "}
                    {league.episode_started}
                </Alert>
            ) : null}
            {loading ? (
                <TableSkeleton size={2} />
            ) : (
                <InfiniteScroll
                    dataLength={leaderboard.length}
                    hasMore={hasMore}
                    next={() => fetchNextOverviewData(loaded, hasMore)}
                    loader={<TableSkeleton />}
                >
                    {(parseInt(episode) >= league.episode_started ||
                        episode === "0") && (
                        <LeagueTableStyled>
                            <div className="p-3">
                                <Row
                                    className="header"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <Col xs={2}>Rank</Col>
                                    <Col
                                        xs={
                                            leagueUser?.commissioner &&
                                            league.active
                                                ? 6
                                                : 7
                                        }
                                    >
                                        Team Name
                                    </Col>
                                    <Col
                                        xs={
                                            leagueUser?.commissioner &&
                                            league.active
                                                ? 2
                                                : 3
                                        }
                                        className="text-right"
                                    >
                                        Points
                                    </Col>
                                    {leagueUser &&
                                        leagueUser.commissioner &&
                                        league.active && (
                                            <Col xs={2} className="pl-1" />
                                        )}
                                </Row>
                            </div>
                            <div className="tbody">{renderTableBody}</div>
                        </LeagueTableStyled>
                    )}
                </InfiniteScroll>
            )}
            <AreYouSureModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                body={`Are you sure you want to remove <b>${
                    selectedTeam && selectedTeam.team_name
                }</b> from your league?`}
                onSuccess={() => handleDeleteTeam()}
                submitting={deleting}
                submitText={"Removing"}
            />
            <ViewProfile
                showModal={showProfile}
                setShowModal={setShowProfile}
                userId={profileId}
            />
        </Well>
    );
};

export default Overview;
