import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";

import Input from "../atoms/Input";
import {
    ButtonGroupSkeleton,
    InputSkeleton,
    LabelSkeleton,
} from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import PageTemplate from "../templates/PageTemplate";

const validationSchema = yup.object({
    teamName: yup.string().required("Team Name is required"),
});

interface ParamTypes {
    id: string;
    showId?: string;
}

const JoinLeagueForm: React.FC = () => {
    const [league, setLeague] = useState<League>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const history = useHistory();
    const { id, showId } = useParams<ParamTypes>();
    const leagueRequest = useApi(apiRoutes.GET_LEAGUE(id, showId), {
        onSuccess: (response: League) => {
            setLeague(response);
        },
        responseKey: "league",
    });
    const joinLeague = useApi(apiRoutes.JOIN_LEAGUE(id, showId), {
        onSuccess: (response: League) => {
            setSubmitting(false);
            toast.success("Joined League Successfully!");
            if (showId) {
                history.push(
                    `/myleagues/view/${response.id}/${response.show_id}`
                );
            } else {
                history.push(`/myleagues/view/${response.id}`);
            }
        },
        onFailure: () => setSubmitting(false),
        responseKey: "league",
    });

    const initialValues = {
        teamName: "",
    };

    useEffect(() => {
        leagueRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <PageTemplate
            header={
                league
                    ? `Join ${league.name} ${
                          league.id !== "0" &&
                          league.id !== 0 &&
                          league.id !== "team" &&
                          league.id !== "budget"
                              ? "League"
                              : ""
                      }`
                    : ""
            }
            loading={leagueRequest.loading}
            skeleton={
                <>
                    <LabelSkeleton />
                    <InputSkeleton />
                    <ButtonGroupSkeleton size={2} />
                </>
            }
        >
            {!leagueRequest.loading && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        joinLeague.request({
                            team_name: values.teamName,
                        });
                        setSubmitting(true);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input
                                name="teamName"
                                label="Team Name:"
                                placeholder="Team Name"
                            />
                            <div className="text-center">
                                <SubmitButton
                                    title="Join League"
                                    submitText="Joining"
                                    submitting={submitting}
                                />{" "}
                                <Link to="/myleagues">
                                    <Button variant="light">Cancel</Button>
                                </Link>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </PageTemplate>
    );
};

export default JoinLeagueForm;
