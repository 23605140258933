import React from "react";
import { useTheme } from "styled-components";
import Select from "react-select";
import { VerticalPadding } from ".";

interface SearchSelectProps {
    placeholder: string;
    options: { value: number; label: string }[];
    onChange: (value: {
        value: number;
        label: string;
        type?: string;
        eliminated?: boolean;
        team?: boolean;
    }) => void;
    value?: { value: number; label: string };
}

const SearchSelect: React.FC<SearchSelectProps> = ({
    placeholder,
    options,
    onChange,
    value,
}) => {
    const theme = useTheme();

    return (
        <>
            <Select
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                styles={{
                    placeholder: (provided, state) => {
                        return {
                            ...provided,
                            color: theme.textColor,
                        };
                    },
                    menu: (provided, state) => {
                        return {
                            ...provided,
                            zIndex: 200,
                        };
                    },
                    indicatorsContainer: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        };
                    },
                    indicatorSeparator: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        };
                    },
                    menuList: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        };
                    },
                    option: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: state.isSelected
                                ? theme.secondary
                                : theme.primary,
                            "&:hover": {
                                backgroundColor: theme.secondary,
                            },
                        };
                    },
                    valueContainer: (provided, state) => {
                        return {
                            ...provided,
                            backgroundColor: theme.primary,
                            color: theme.textColor,
                        };
                    },
                    input: (provided, state) => {
                        return {
                            ...provided,
                            color: theme.textColor,
                        };
                    },
                    singleValue: (provided, state) => {
                        return {
                            ...provided,
                            color: theme.textColor,
                        };
                    },
                }}
                options={options}
            />
            <VerticalPadding />
        </>
    );
};

export default SearchSelect;
