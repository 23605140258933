import React, { ChangeEvent } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import ImageUpload from "../atoms/ImageUpload";
import { ShowFormFields } from "../pages/CreateCustomShow";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];
const years = [
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
];
const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
];
const minutes = [0, 15, 30, 45];

interface ShowInfoFormProps {
    values: ShowFormFields;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => void;
}

const CustomShowInfoForm: React.FC<ShowInfoFormProps> = ({
    values,
    setFieldValue,
}) => {
    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("showImage", files[0]);
        }
    };

    return (
        <Row>
            <Col md={12}>
                <Input
                    name="showName"
                    label="Show Name:"
                    placeholder="Show Name"
                />
            </Col>
            <Col md={12}>
                <ImageUpload
                    name="showImage"
                    label="Show Image"
                    image={values.showImage}
                    handleChange={(e) => onImageChange(e, setFieldValue)}
                />
            </Col>
            <Col xs={4} md={4}>
                <Select name="showDay" label="Show Day:" items={daysOfWeek} />
            </Col>
            <Col xs={4} md={4}>
                <Select
                    name="showHour"
                    label="Show Hour (Military Time CST):"
                    items={hours}
                />
            </Col>
            <Col xs={4} md={4}>
                <Select name="showMin" label="Show Minute:" items={minutes} />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateMonth" label="Start Month:" items={months} />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateDay" label="Start Day:" items={days} />
            </Col>
            <Col xs={4} md={4}>
                <Select name="dateYear" label="Start Year:" items={years} />
            </Col>
        </Row>
    );
};

export default CustomShowInfoForm;
