import { ApiReturnType } from "./";

const customScoringRoutes = {
    ADD_CUSTOM_CHALLENGE_QUESTIONS: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/customScoring/addQuestions/${leagueId}`,
            method: "post",
        };
    },
    ADD_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/customScoring/add/${leagueId}`,
            method: "post",
        };
    },
    GET_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/leagues/getLoggedCustomScores/${leagueId}`,
            method: "get",
        };
    },
    GET_CUSTOM_LOGGED_ANSWERS: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/leagues/getLoggedAnswers/${leagueId}`,
            method: "get",
        };
    },
    SAVE_CUSTOM_CHALLENGE_QUESTIONS: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/customScoring/questions/save/${leagueId}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_LOGGED_QUESTIONS: (
        leagueId: string,
        episode: string
    ): ApiReturnType => {
        return {
            url: `v2/leagues/saveLoggedCustomQuestions/${leagueId}/${episode}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_LOGGED_SCORES: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/leagues/saveLoggedCustomScores/${leagueId}`,
            method: "post",
        };
    },
    SAVE_CUSTOM_SCORING: (leagueId: string): ApiReturnType => {
        return {
            url: `v2/customScoring/save/${leagueId}`,
            method: "post",
        };
    },
};

export default customScoringRoutes;
