import React from "react";

import strings from "../../config/strings";
import NumPicksSelect from "../atoms/NumPicksSelect";

import Switch from "../atoms/Switch";

interface LeagueFullDraftFormProps {
    castCount?: number;
    numPicksChange?: (value) => void;
    numPick?: number | string;
    drafted?: boolean;
}

const LeagueFullDraftForm: React.FC<LeagueFullDraftFormProps> = ({
    castCount,
    numPicksChange,
    numPick,
    drafted = false,
}) => {
    return (
        <>
            <Switch
                name="allowTrading"
                label="Allow Trading?"
                formText={strings.createLeague.allowTrading}
            />
            {castCount && !drafted ? (
                <NumPicksSelect
                    onChange={numPicksChange}
                    numPicks={castCount}
                    selected={numPick}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default LeagueFullDraftForm;
