import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";

import { RootState } from "../../store";
import { LiveScore } from "../../models/LiveScores";
import { Show } from "../../models/Shows";
import { TopScorerList } from "../../models/Scores";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { ModalStyled } from "../atoms";
import { ScoreTeamListSkeleton } from "../atoms/Skeletons";
import LiveCastRow from "../atoms/LiveCastRow";

interface TopScorersModalProps {
    leagueId: string;
    episode: string;
    liveScores: LiveScore[];
    show: Show;
    showModal: boolean;
    setShowModal: (visible: boolean) => void;
}

const TopScorersModal: React.FC<TopScorersModalProps> = ({
    leagueId,
    episode,
    liveScores,
    show,
    showModal,
    setShowModal,
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [topScorers, setTopScorers] = useState<TopScorerList>([]);

    const getTopScorers = useApi(
        apiRoutes.GET_TOP_LIVE_SCORERS(show.slug, episode),
        {
            responseKey: "topScorers",
            onSuccess(response: TopScorerList) {
                setTopScorers(response);
                setLoading(false);
            },
            onFailure() {
                setLoading(false);
            },
        }
    );

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            const date = new Date();
            const offsetInHours = date.getTimezoneOffset() / 60;
            if (
                offsetInHours >= timezoneOffsets.hawaii &&
                show.hawaii_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    hawaii: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.alaska &&
                show.alaska_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    alaska: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.pacific &&
                show.pacific_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    pacific: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.mountain &&
                show.mountain_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    mountain: true,
                });
            } else {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, liveScores]);

    useEffect(() => {
        setLoading(true);
    }, [leagueId]);

    return (
        <ModalStyled
            show={showModal}
            onHide={() => setShowModal(false)}
            size="lg"
            className="text-center"
            centered
            style={{ zIndex: 10000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Top Scorers:</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="m-3">
                    {loading ? (
                        <ScoreTeamListSkeleton />
                    ) : (
                        <>
                            {topScorers.length > 0 ? (
                                <>
                                    {topScorers.map((topScorer, index) => {
                                        return (
                                            <LiveCastRow
                                                key={index}
                                                pic={topScorer.pic}
                                                total={topScorer.total}
                                                name={topScorer.name}
                                                team={
                                                    topScorer.cast
                                                        ? topScorer.cast.team
                                                        : undefined
                                                }
                                                partner={
                                                    topScorer.cast
                                                        ? topScorer.cast.partner
                                                        : undefined
                                                }
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <div className="text-center">
                                    There are currently no scores
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default TopScorersModal;
