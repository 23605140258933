import { ApiReturnType } from "./";

const showRoutes = {
    GET_SHOW: (id: number | string): ApiReturnType => {
        return {
            url: `v2/shows/${id}`,
            method: "get",
        };
    },
    GET_SHOW_BY_SLUG: (slug: string): ApiReturnType => {
        return {
            url: `v2/shows/bySlug/${slug}`,
            method: "get",
        };
    },
    GET_SHOWS_BY_TYPE: (type: string): ApiReturnType => {
        return {
            url: `v2/shows/type/${type}`,
            method: "get",
        };
    },
};

export default showRoutes;
