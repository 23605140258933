import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GroupsIcon from "@mui/icons-material/Groups";

import useApi from "../../hooks/useApi";
import { PublicLeague } from "../../models/Leagues";
import { FullShow } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";
import useQuery from "../../hooks/useQuery";

import { FormGroupWrapper } from "../atoms";
import PageTemplate from "../templates/PageTemplate";
import { MyleaguesListItem } from "../organisms/styles/MyleaguesList.styled";
import LeagueBadge from "../atoms/LeagueBadge";
import CastImage from "../atoms/CastImage";
import { LeaguesListItemSkeleton } from "../atoms/Skeletons";

const PublicLeagueSearch: React.FC = () => {
    const query = useQuery();
    const history = useHistory();
    const showId = query.get("showId") ? parseInt(query.get("showId")) : 0;
    const [search, setSearch] = useState<string>("");
    const [selectedShow, setSelectedShow] = useState<number>(showId ?? 0);
    const [activeShows, setActiveShows] = useState<FullShow[]>();
    const [draftType, setDraftType] = useState<string>("All");
    const [leagues, setLeagues] = useState<PublicLeague[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const activeShowsRequest = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        responseKey: "shows",
        onSuccess: (response: FullShow[]) => setActiveShows(response),
    });

    const publicShowsRequest = useApi(apiRoutes.SEARCH_PUBLIC_LEAGUES(), {
        onSuccess: (response: {
            leagues: PublicLeague[];
            officialLeagues: PublicLeague[];
            sponsoredLeagues: PublicLeague[];
            page: number;
            has_more: boolean;
            count: number;
        }) => {
            if (search.length > 0) {
                setTotal(response.count);
            } else {
                if (draftType === "All") {
                    setTotal(response.count + 3); // +2 bc of the official leagues
                } else if (
                    draftType === "Full Draft" ||
                    draftType === "Confidence Pool" ||
                    draftType === "Eliminated Confidence Pool"
                ) {
                    setTotal(response.count);
                } else {
                    setTotal(response.count + 1); // +1 bc of the official leagues
                }
            }
            const sponsored = response.leagues.filter(
                (league) => league.sponsored
            );
            const leagues = response.leagues.filter(
                (league) => !league.sponsored
            );

            setLeagues([...sponsored, ...response.officialLeagues, ...leagues]);
            setLoading(false);
        },
    });

    useEffect(() => {
        activeShowsRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedShow > 0) {
            setLoading(true);
            publicShowsRequest.request({
                show_id: selectedShow,
                value: search,
                draftType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShow]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(true);
            publicShowsRequest.request({
                show_id: selectedShow,
                value: search,
                draftType,
            });
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        setLoading(true);
        publicShowsRequest.request({
            show_id: selectedShow,
            value: search,
            draftType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftType]);

    const determineLeagueAction = (league: PublicLeague): boolean => {
        if (league.active) {
            if (league.league_size) {
                if (league.league_size - league.players > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (league.draft_type === "Full Draft" && league.drafted) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return false;
        }
    };

    return (
        <PageTemplate header="Find Public League">
            <FormGroupWrapper>
                <Form.Control
                    as="select"
                    value={selectedShow}
                    onChange={(e) => setSelectedShow(parseInt(e.target.value))}
                >
                    <option value={0}>Choose Show</option>
                    {activeShows &&
                        activeShows.map((show: FullShow) => {
                            return (
                                <option key={show.id} value={show.id}>
                                    {show.show}
                                </option>
                            );
                        })}
                </Form.Control>
            </FormGroupWrapper>
            {selectedShow > 0 && (
                <>
                    <FormGroupWrapper>
                        <Form.Label>Search by League Name:</Form.Label>
                        <Form.Control
                            placeholder="Search..."
                            value={search}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(ev) => setSearch(ev.target.value)}
                        />
                    </FormGroupWrapper>
                    <FormGroupWrapper>
                        <Form.Label>Filter by Draft Type:</Form.Label>
                        <Form.Control
                            as="select"
                            value={draftType}
                            onChange={(e) => setDraftType(e.target.value)}
                        >
                            <option value={"All"}>All</option>
                            <option value={"Full Draft"}>Full Draft</option>
                            <option value={"Weekly Redraft"}>
                                Weekly Redraft
                            </option>
                            <option value={"Team"}>Team</option>
                            <option value={"Weekly Budget"}>
                                Weekly Budget
                            </option>
                            <option value={"Confidence Pool"}>
                                Confidence Pool
                            </option>
                            <option value={"Eliminated Confidence Pool"}>
                                Eliminated Confidence Pool
                            </option>
                        </Form.Control>
                    </FormGroupWrapper>
                    {loading ? (
                        <LeaguesListItemSkeleton size={3} />
                    ) : (
                        <>
                            <p className="text-center">
                                Showing <b>{leagues.length}</b> of{" "}
                                <b>{total}</b> public leagues
                            </p>
                            {leagues.map((league) => {
                                if (determineLeagueAction(league)) {
                                    return (
                                        <MyleaguesListItem
                                            key={league.id}
                                            onClick={() =>
                                                history.push(
                                                    `/myleagues/view/${
                                                        league.id
                                                    }${
                                                        league.id === "0" ||
                                                        league.id === 0 ||
                                                        league.id === "team" ||
                                                        league.id === "budget"
                                                            ? `/${league.show_id}`
                                                            : ""
                                                    }`
                                                )
                                            }
                                        >
                                            <LeagueBadge league={league} />
                                            <Col xs={6}>
                                                <Row>
                                                    {league.img && (
                                                        <div className="mr-3">
                                                            <CastImage
                                                                src={league.img}
                                                            />
                                                        </div>
                                                    )}
                                                    <div>
                                                        <div className="font-weight-bold">
                                                            {league.name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            Draft Type:{" "}
                                                            {league.draft_type}
                                                        </div>
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            <GroupsIcon className="mr-1" />
                                                            <span>
                                                                {league.players}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col xs={6} className="text-right">
                                                <ArrowForwardIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: 3,
                                                    }}
                                                    fontSize="medium"
                                                />
                                            </Col>
                                        </MyleaguesListItem>
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default PublicLeagueSearch;
