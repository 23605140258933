import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { hasPremiumSub } from "../../helpers/checkSubscriptions";
import { imageUrl } from "../../config";
import { QuestionAnswerResponse } from "../../models/QuestionAnswers";

import { Avatar } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CastTeam from "./CastTeam";

interface ChallengeQuestionRowProps {
    episode: number;
    userAnswer: QuestionAnswerResponse;
    score: number;
}

const ChallengeQuestionRow: React.FC<ChallengeQuestionRowProps> = ({
    userAnswer,
    score,
}) => {
    const subscription = useSelector((state: RootState) => state.subscription);

    return (
        <Row className="p-3 align-items-center">
            <Col className="text-center">
                {userAnswer.cast ? (
                    <>
                        <Avatar
                            style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                            }}
                            src={`${imageUrl}${userAnswer.cast.pic}`}
                        />
                        <span>{`${userAnswer.cast.first_name} ${userAnswer.cast.last_name}`}</span>
                        <CastTeam
                            size={10}
                            team={userAnswer.cast.team}
                            partner={userAnswer.cast.partner}
                        />
                    </>
                ) : (
                    `No Answer`
                )}
            </Col>
            {subscription && hasPremiumSub(subscription.name) ? (
                <Col className="text-center">
                    {userAnswer.live_match > 0 || userAnswer.match ? score : 0}
                </Col>
            ) : (
                <Col className="text-center">
                    {userAnswer.match > 0 ? score : 0}
                </Col>
            )}
        </Row>
    );
};

export default ChallengeQuestionRow;
