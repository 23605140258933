import React from "react";

import { CurrentLeagues, PublicLeague } from "../../models/Leagues";

import { LeagueBadgeWrapper } from "./styles/LeagueBadge.styled";

interface LeagueBadgeProps {
    league: CurrentLeagues | PublicLeague;
}

const LeagueBadge: React.FC<LeagueBadgeProps> = ({ league }) => {
    return (
        <>
            {league.id === 0 ||
            league.id === "0" ||
            league.id === "team" ||
            league.id === "budget" ? (
                <LeagueBadgeWrapper style={{ backgroundColor: "black" }}>
                    RTVF Official
                </LeagueBadgeWrapper>
            ) : league.sponsored === true ? (
                <LeagueBadgeWrapper style={{ backgroundColor: "green" }}>
                    Sponsored
                </LeagueBadgeWrapper>
            ) : (
                league.custom === true && (
                    <LeagueBadgeWrapper style={{ backgroundColor: "gray" }}>
                        Custom
                    </LeagueBadgeWrapper>
                )
            )}
        </>
    );
};

export default LeagueBadge;
