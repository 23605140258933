import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { Avatar } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Button, Row } from "react-bootstrap";

import { colors } from "../../config/colors";
import { imageUrl } from "../../config";
import { RootState } from "../../store";
import { UserModel } from "../../models/User";

import { ProfileImageSkeleton, ProfileSkeleton } from "../atoms/Skeletons";
import { MainContainer, PageHeader, VerticalPadding, Well } from "../atoms";
import SocialLinks from "../atoms/SocialLinks";

interface ProfileTemplateProps {
    user?: UserModel;
    loading?: boolean;
    skeleton?: React.ReactElement;
    header?: boolean;
    edit?: boolean;
    subscription?: string;
    children: React.ReactNode;
}

interface ConditionalWrapProps {
    condition: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
}

const ProfileTemplate: React.FC<ProfileTemplateProps> = ({
    user,
    subscription,
    loading,
    skeleton,
    header,
    edit,
    children,
}) => {
    const history = useHistory();
    const loggedInUser = useSelector((state: RootState) => state.user);

    const ConditionalWrap = ({
        condition,
        wrapper,
        children,
    }: ConditionalWrapProps) => (condition ? wrapper(children) : children);

    return (
        <MainContainer>
            {header && (
                <PageHeader>
                    <h1>My Profile</h1>
                </PageHeader>
            )}
            <ConditionalWrap
                condition={edit === true}
                wrapper={(children) => (
                    <Well
                        style={{ position: "relative" }}
                        className="text-center"
                    >
                        {children}
                    </Well>
                )}
            >
                <>
                    {" "}
                    {edit && (
                        <Button
                            style={{ position: "absolute", right: 20, top: 20 }}
                            onClick={() => history.push(`/profile/edit`)}
                        >
                            Edit Profile
                        </Button>
                    )}
                    <div className="d-flex justify-content-center">
                        {user && !loading ? (
                            <Avatar
                                sx={{ width: 100, height: 100 }}
                                src={
                                    user.profile_pic &&
                                    user.profile_pic.includes("https://")
                                        ? user.profile_pic
                                        : `${imageUrl}${user.profile_pic}`
                                }
                            />
                        ) : (
                            <ProfileImageSkeleton />
                        )}
                    </div>
                    <VerticalPadding />
                    {loading ? (
                        <ProfileSkeleton />
                    ) : (
                        <>
                            {user && (
                                <>
                                    <h3 className="font-weight-bold">
                                        {`${user.first_name}${
                                            user.last_name &&
                                            ` ${
                                                loggedInUser.id === user.id
                                                    ? user.last_name
                                                    : user.last_name
                                                    ? user.last_name.substring(
                                                          0,
                                                          1
                                                      )
                                                    : ""
                                            }`
                                        }`}
                                    </h3>
                                    {subscription && (
                                        <Row
                                            className="mb-2"
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <VerifiedIcon
                                                className="mr-1"
                                                fontSize="medium"
                                                style={{
                                                    color:
                                                        subscription ===
                                                        "Premium"
                                                            ? colors.gold
                                                            : colors.button,
                                                }}
                                            />
                                            {`${subscription} Subscriber`}
                                        </Row>
                                    )}
                                    <div
                                        style={{ fontSize: 12 }}
                                        className="mb-3"
                                    >
                                        Joined:{" "}
                                        {moment(user.created_at).format(
                                            "MMM Do, YYYY"
                                        )}
                                    </div>
                                    <div>
                                        <SocialLinks
                                            size="medium"
                                            socials={{
                                                instagram: user.instagram,
                                                twitter: user.twitter,
                                                tiktok: user.tiktok,
                                                podcast: user.podcast,
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div>{loading ? skeleton : children}</div>
                </>
            </ConditionalWrap>
        </MainContainer>
    );
};

export default ProfileTemplate;
