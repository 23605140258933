import React from "react";
import { Helmet } from "react-helmet-async";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

import { MainContainer, PageHeader, Small, Spinner, Well } from "../atoms";
import {
    CardSkeleton,
    PageHeaderSkeleton,
    TextAreaSkeleton,
    CommentSkeleton,
} from "../atoms/Skeletons";
import { Link } from "react-router-dom";
import { Article } from "../../models/Articles";
import { imageUrl } from "../../config";
import { Show } from "../../models/Shows";
// import Ad from "../atoms/Ad";
import GoogleAd from "../atoms/GoogleAd";

interface ArticleWrapperProps {
    header: string;
    headerSecondary?: string | React.ReactElement;
    loading?: boolean;
    skeleton?: React.ReactElement;
    comments?: React.ReactElement;
    sideCards: React.ReactElement;
    article: Article;
    show: Show;
}

const ArticleTemplate: React.FC<ArticleWrapperProps> = ({
    header,
    headerSecondary,
    loading,
    skeleton,
    sideCards,
    comments,
    article,
    show,
    children,
}) => {
    return (
        <MainContainer>
            {loading && skeleton && (
                <>
                    <PageHeaderSkeleton />
                    <Row>
                        <Col lg={8}>
                            <Well>{skeleton}</Well>
                            <Well>
                                <TextAreaSkeleton />
                                <CommentSkeleton />
                            </Well>
                        </Col>
                        <Col lg={4}>
                            <CardSkeleton />
                            <CardSkeleton />
                            <CardSkeleton />
                        </Col>
                    </Row>
                </>
            )}
            {loading && !skeleton && <Spinner />}
            {!loading && article && (
                <>
                    <Helmet prioritizeSeoTags>
                        <title>{`${header} - RealTVFantasy`}</title>
                        <meta
                            property="og:url"
                            content={`https://realtvfantasy.com${window.location.pathname}`}
                        />
                        <meta
                            name="description"
                            content={`${article.description
                                .replace(/(<([^>]+)>)/gi, "")
                                .substr(0, 40)}...`}
                        />
                        <meta property="og:type" content="website" />
                        <meta
                            property="og:title"
                            content={`${article.title} - RealTVFantasy`}
                        />
                        <meta
                            property="og:description"
                            content={`${article.description
                                .replace(/(<([^>]+)>)/gi, "")
                                .substr(0, 40)}...`}
                        />
                        <meta
                            property="og:image"
                            content={`${imageUrl}${article.image}`}
                        />
                        <meta
                            property="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            property="twitter:title"
                            content={`${article.title} - RealTVFantasy`}
                        />
                        <meta
                            property="twitter:description"
                            content={`${article.description
                                .replace(/(<([^>]+)>)/gi, "")
                                .substr(0, 60)}...`}
                        />
                        <meta
                            property="twitter:image"
                            content={`${imageUrl}${article.image}`}
                        />
                    </Helmet>
                    <PageHeader>
                        <h1>
                            {header}{" "}
                            {headerSecondary ? (
                                <Small>{headerSecondary}</Small>
                            ) : (
                                ""
                            )}
                        </h1>
                    </PageHeader>
                    {!article.published && (
                        <Alert variant="warning">
                            Article is currently unpublished. Publish article{" "}
                            <Link
                                target="_blank"
                                to={`/admin/articles/edit/${article.id}`}
                            >
                                here
                            </Link>
                        </Alert>
                    )}
                    <Row>
                        <Col lg={8}>
                            <Well>{children}</Well>
                            {comments && <Well>{comments}</Well>}
                        </Col>
                        <Col lg={4}>{sideCards}</Col>
                    </Row>
                    <GoogleAd fixed slot="7455698336" height={90} />
                    {/* <Ad fixed /> */}
                </>
            )}
        </MainContainer>
    );
};

export default ArticleTemplate;
