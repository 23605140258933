import React from "react";

import Row from "react-bootstrap/Row";

import { Cast } from "../../models/Cast";

import { Well } from "../atoms";
import CastImage from "../atoms/CastImage";
import { PlayerName } from "./styles/EliminatedPlayers.styled";
import CastTeam from "./CastTeam";

interface EliminatedPlayersProps {
    players: Cast[];
}

const EliminatedPlayers: React.FC<EliminatedPlayersProps> = ({ players }) => {
    return (
        <>
            <h2>Eliminated Players:</h2>
            <Well className="text-center">
                {players.length > 0 ? (
                    players.map((player: Cast) => {
                        return (
                            <div key={player.id}>
                                <Row className="align-items-center justify-content-center">
                                    <CastImage src={player.pic} />
                                    <div className="ml-2">
                                        <PlayerName>
                                            {` ${player.first_name} ${
                                                player.last_name
                                                    ? player.last_name
                                                    : ""
                                            }`}
                                        </PlayerName>
                                        <CastTeam
                                            left
                                            team={player.team}
                                            partner={player.partner}
                                            size={10}
                                        />
                                    </div>
                                </Row>
                            </div>
                        );
                    })
                ) : (
                    <div>Nobody Eliminated</div>
                )}
            </Well>
        </>
    );
};

export default EliminatedPlayers;
