import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";

import CancelIcon from "@mui/icons-material/Cancel";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserModel } from "../../models/User";
import { UserBadge } from "../../models/UserBadges";
import { colors } from "../../config/colors";

import { VerticalPadding } from "../atoms";
import ProfileTemplate from "../templates/ProfileTemplate";
import BadgeList from "./BadgeList";
import { BadgeInfo } from "../atoms/styles/BadgeItem.styled";
import { ProfileHeader, ProfileWrapper } from "./styles/ViewProfile.styled";

interface ViewProfileProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    userId: number;
}

const ViewProfile: React.FC<ViewProfileProps> = ({
    showModal,
    setShowModal,
    userId,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [userBadges, setUserBadges] = useState<UserBadge[]>([]);
    const [profileUser, setProfileUser] = useState<UserModel>();
    const [subscription, setSubscription] = useState<string>();

    const getProfile = useApi(apiRoutes.GET_PROFILE(userId.toString()), {
        onSuccess: (response: {
            user: UserModel;
            userBadges: UserBadge[];
            subscription: string | null;
        }) => {
            setProfileUser(response.user);
            setUserBadges(response.userBadges);
            setSubscription(response.subscription);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        if (showModal) {
            setLoading(true);
            document.body.style.overflow = "hidden";
            getProfile.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal, userId]);

    const closePanel = () => {
        setShowModal(false);
        document.body.style.overflow = "auto";
    };

    return (
        <SlidingPanel
            type={"bottom"}
            isOpen={showModal}
            size={85}
            backdropClicked={() => closePanel()}
        >
            <ProfileWrapper>
                <ProfileHeader />
                <ProfileTemplate
                    loading={loading}
                    user={profileUser}
                    subscription={subscription}
                >
                    <BadgeInfo
                        style={{ top: 30, right: 30, cursor: "pointer" }}
                        onClick={() => closePanel()}
                    >
                        <CancelIcon htmlColor={colors.white} fontSize="large" />
                    </BadgeInfo>
                    <VerticalPadding />
                    <h5 className="text-center font-weight-bold">Badges:</h5>
                    <BadgeList userBadges={userBadges} />
                </ProfileTemplate>
            </ProfileWrapper>
        </SlidingPanel>
    );
};

export default ViewProfile;
