import { ApiReturnType } from "./";

const articleRoutes = {
    GET_ARTICLE: (article_id: string): ApiReturnType => {
        return {
            url: `v2/articles/${article_id}`,
            method: "get",
        };
    },
    GET_ARTICLES: (): ApiReturnType => {
        return {
            url: `v2/articles`,
            method: "get",
        };
    },
    GET_ARTICLES_BY_AUTHOR: (author_id: string): ApiReturnType => {
        return {
            url: `v2/articles/byAuthor/${author_id}`,
            method: "get",
        };
    },
    GET_ARTICLES_BY_SHOW: (slug: string): ApiReturnType => {
        return {
            url: `v2/articles/byShow/${slug}`,
            method: "get",
        };
    },
};

export default articleRoutes;
