import { ApiReturnType } from "./";

const authRoutes = {
    DELETE_ACCOUNT: (): ApiReturnType => {
        return {
            url: `v2/auth/delete`,
            method: "post",
        };
    },
    GET_USER: (): ApiReturnType => {
        return {
            url: `v2/auth/user`,
            method: "get",
        };
    },
    GET_USER_BADGES: (): ApiReturnType => {
        return {
            url: `v2/auth/userbadges`,
            method: "get",
        };
    },
    GET_USER_BY_EMAIL: (email: string): ApiReturnType => {
        return {
            url: `v2/auth/getUserByEmail/${email}`,
            method: "get",
        };
    },
    GET_USER_BY_TOKEN: (): ApiReturnType => {
        return {
            url: `v2/auth/loginByToken`,
            method: "post",
        };
    },
    LOGIN: (): ApiReturnType => {
        return {
            url: `v2/auth/login`,
            method: "post",
        };
    },
};

export default authRoutes;
