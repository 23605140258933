import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove"; // Dash icon
import { useTheme } from "styled-components";

interface OverviewRankProps {
    rank: number;
    previousRank?: number | null;
}

const OverviewRank: React.FC<OverviewRankProps> = ({ rank, previousRank }) => {
    const theme = useTheme();

    if (previousRank === undefined || previousRank === null) {
        return <div>{rank}</div>; // Don't show anything extra if previousRank isn't set
    }

    let icon = <RemoveIcon fontSize="small" className="ml-2" />; // Default to dash
    let color = theme.textColor;
    let difference = Math.abs(rank - previousRank);

    if (rank < previousRank) {
        icon = <ArrowDropUpIcon />;
        color = "green";
    } else if (rank > previousRank) {
        icon = <ArrowDropDownIcon />;
        color = "red";
    } else {
        difference = 0; // No change, show dash
    }

    return (
        <div className="d-flex align-items-center">
            {rank}
            {difference > 0 && (
                <span
                    className="font-weight-bold d-flex align-items-center ml-1"
                    style={{
                        fontSize: 10,
                        color: color,
                    }}
                >
                    {icon}
                    {difference > 0 ? difference : ""}
                </span>
            )}
        </div>
    );
};

export default OverviewRank;
