import React from "react";
import { useTheme } from "styled-components";

import { imageUrl } from "../../config";
import { Question } from "../../models/Questions";

import { Avatar } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import CastTeam from "./CastTeam";

interface ChallengeQuestionListHeaderProps {
    episode: number;
    question: Question;
}

const ChallengeQuestionListHeader: React.FC<
    ChallengeQuestionListHeaderProps
> = ({ episode, question }) => {
    const theme = useTheme();
    const answers = question.answers.filter((q) => {
        return q.episode === episode;
    });
    const liveAnswers = question.live_answers
        ? question.live_answers?.filter((q) => {
              return q.episode === episode;
          })
        : [];

    return (
        <div style={{ color: theme.textColor }}>
            <h5 className="text-center font-weight-bold">
                {question.description}
            </h5>
            <Col className="mb-2">
                <Row className="align-items-center justify-content-center mb-2">
                    {answers.length > 0 &&
                        answers.map((answer) => {
                            return (
                                <div
                                    className="m-2 text-center"
                                    key={answer.id}
                                >
                                    <Avatar
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                        src={`${imageUrl}${answer.pic}`}
                                    />
                                    <span>{`${answer.first_name} ${answer.last_name}`}</span>
                                    <CastTeam
                                        size={10}
                                        team={
                                            answer.cast
                                                ? answer.cast.team
                                                : answer.team
                                        }
                                        partner={
                                            answer.cast
                                                ? answer.cast.partner
                                                : answer.partner
                                        }
                                    />
                                </div>
                            );
                        })}
                    {liveAnswers.length > 0 &&
                        liveAnswers.map((answer) => {
                            return (
                                <div
                                    className="m-2 text-center"
                                    key={answer.id}
                                >
                                    <Avatar
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}
                                        src={`${imageUrl}${answer.pic}`}
                                    />
                                    <span>{`${answer.first_name} ${answer.last_name}`}</span>
                                    <CastTeam
                                        size={10}
                                        team={
                                            answer.cast
                                                ? answer.cast.team
                                                : answer.team
                                        }
                                        partner={
                                            answer.cast
                                                ? answer.cast.partner
                                                : answer.partner
                                        }
                                    />
                                </div>
                            );
                        })}
                    {answers.length <= 0 && liveAnswers.length <= 0 && <>-</>}
                </Row>
            </Col>
        </div>
    );
};

export default ChallengeQuestionListHeader;
