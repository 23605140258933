import { ApiReturnType } from "./";

const notificationRoutes = {
    GET_NOTIFICATIONS: (): ApiReturnType => {
        return {
            url: `v2/notifications`,
            method: "get",
        };
    },
    DELETE_NOTIFICATION: (id: string): ApiReturnType => {
        return {
            url: `v2/notifications/delete/${id}`,
            method: "post",
        };
    },
};

export default notificationRoutes;
