import React from "react";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import configs from "../../config/configs";
import strings from "../../config/strings";

import Input from "../atoms/Input";
import Select from "../atoms/Select";
import { ShowFormFields } from "../pages/CreateCustomShow";
import LeagueWeeklyRedraftForm from "../molecules/LeagueWeeklyRedraftForm";
import LeagueTeamForm from "../molecules/LeagueTeamForm";
import LeagueFullDraftForm from "../molecules/LeagueFullDraftForm";
import Switch from "../atoms/Switch";

interface CustomLeagueInfoFormProps {
    values: ShowFormFields;
    edit?: boolean;
}

const CustomLeagueInfoForm: React.FC<CustomLeagueInfoFormProps> = ({
    values,
    edit,
}) => {
    const getDraftText = (draftType: string) => {
        if (draftType === "Weekly Redraft") {
            return strings.createLeague.weeklyRedraft;
        } else if (draftType === "Full Draft") {
            return strings.createLeague.fullDraft;
        } else if (draftType === "Team") {
            return strings.createLeague.team;
        } else if (draftType === "Weekly Budget") {
            return strings.createLeague.weeklyBudget;
        } else {
            return "";
        }
    };

    return (
        <Row style={{ alignItems: "center" }}>
            <Col md={12}>
                <Input
                    name="leagueName"
                    label="League Name:"
                    placeholder="League Name"
                />
            </Col>
            {!edit && (
                <Col md={12}>
                    <Input
                        name="teamName"
                        label="Team Name:"
                        placeholder="Team Name"
                    />
                </Col>
            )}
            {edit ? (
                <Col md={12}>
                    <Form.Group>
                        <Form.Label className="mr-1">
                            Draft Type: <strong>{values.draftType}</strong>.
                        </Form.Label>
                        <Form.Label>
                            If you would like to change league types, please{" "}
                            <Link to={"/contact"}>contact us</Link>
                        </Form.Label>
                    </Form.Group>
                </Col>
            ) : (
                <Col md={12}>
                    <Select
                        name="draftType"
                        label="Draft Type:"
                        items={configs.leagues.draftTypes}
                        formText={getDraftText(values.draftType)}
                    />
                </Col>
            )}
            {values.draftType === "Full Draft" && (
                <Col md={12}>
                    <LeagueFullDraftForm />
                </Col>
            )}
            {(values.draftType === "Weekly Redraft" ||
                values.draftType === "Weekly Budget") && (
                <Col md={12}>
                    <LeagueWeeklyRedraftForm
                        budget={values.draftType === "Weekly Budget"}
                    />
                </Col>
            )}
            {values.draftType === "Team" && (
                <>
                    {(!edit || values.draftType === "Team") && (
                        <>
                            <Col className="mt-2" xs={12}>
                                <Switch
                                    name="genderLimits"
                                    label="Gender Limits"
                                    formText={strings.createLeague.genderLimit}
                                />
                            </Col>
                            <Col xs={12}>
                                <Switch
                                    name="rookieLimits"
                                    label="Rookie Limits"
                                    formText={strings.createLeague.rookieLimit}
                                />
                            </Col>
                        </>
                    )}
                    <Col md={12}>
                        <LeagueTeamForm
                            genderLimit={values.genderLimits}
                            rookieLimit={values.rookieLimits}
                            edit={edit}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
};

export default CustomLeagueInfoForm;
