import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import {
    LeagueActivity as LeagueActivityModel,
    LeagueScoringLog,
} from "../../models/Leagues";
import apiRoutes from "../../services/apiRoutes";
import { ActivityPagination } from "../../models/Activity";

import Button from "react-bootstrap/Button";

import { Well } from "../atoms";
import ScoreActivityItem from "../molecules/ScoreActivityItem";
import ActivityListItem from "../molecules/ActivityListItem";
import { TableSkeleton } from "../atoms/Skeletons";

interface LeagueActivityProps {
    leagueId: string;
    showId?: string | number;
}

const LeagueActivity: React.FC<LeagueActivityProps> = ({
    leagueId,
    showId,
}) => {
    const activityRequest = useApi(
        apiRoutes.GET_LEAGUE_ACTIVITY(leagueId, showId),
        {
            onSuccess,
        }
    );

    const pageSize = 50;
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [scoringLog, setScoringLog] = useState<LeagueScoringLog[] | []>([]);
    const [activity, setActivity] = useState<LeagueActivityModel[] | []>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        activityRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leagueId]);

    function onSuccess(response: ActivityPagination) {
        setActivity([...activity, ...response.activity]);
        setHasMore(response.has_more);
        setPage(page + 1);
        if (!loaded) {
            setScoringLog(response.scoring_log);
        }
        setLoaded(true);
        setLoading(false);
    }

    const fetchNextActivityData = () => {
        setLoading(true);
        activityRequest.request({ page: page, limit: pageSize });
    };

    return (
        <Well>
            {!loaded && <TableSkeleton />}
            {loaded && (
                <>
                    <div className="mt-4">
                        <h3 className="mb-4">Scoring Log</h3>
                        {scoringLog.length > 0 ? (
                            scoringLog.map((score: LeagueScoringLog) => {
                                return (
                                    <ScoreActivityItem
                                        key={`${score.id}${score.episode}`}
                                        score={score}
                                        leagueId={leagueId}
                                    />
                                );
                            })
                        ) : (
                            <p>No Scores Logged</p>
                        )}
                    </div>
                    <div className="mt-4" id="scrollableDivActivity">
                        <h3 className="mb-4">Activity Log</h3>
                        {activity.length > 0 ? (
                            activity.map((item: LeagueActivityModel) => {
                                return (
                                    <ActivityListItem
                                        key={item.id}
                                        item={item}
                                    />
                                );
                            })
                        ) : (
                            <p>No League Activity</p>
                        )}
                        {hasMore && (
                            <Button
                                onClick={fetchNextActivityData}
                                disabled={loading}
                                className="mt-2 w-100"
                            >
                                Load More
                            </Button>
                        )}
                    </div>
                </>
            )}
        </Well>
    );
};

export default LeagueActivity;
