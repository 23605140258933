import { Row } from "react-bootstrap";
import styled from "styled-components";

export const ShowHeader = styled.div`
    color: ${(props) => props.theme.textColor};
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`;

export const MyleaguesListItem = styled(Row)`
    background-color: ${(props) => props.theme.primary};
    border-radius: 20px !important;
    margin: 10px;
    padding: 30px;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    position: relative;
`;
