import React, { useMemo } from "react";
import { ErrorMessage, FastField, Field, FieldProps } from "formik";
import { useTheme } from "styled-components";

import Form from "react-bootstrap/Form";

interface SelectProps {
    name: string;
    label?: string;
    items: any;
    errorMessage?: boolean;
    formText?: string;
    selectKey?: string;
    selectValue?: string;
    selectValue2?: string;
    selectConcatString?: string;
    defaultKey?: string | number;
    defaultValue?: string;
    handleChange?: (item: any) => void;
    backgroundColor?: string;
    disabled?: boolean;
    field?: boolean;
}

const Select: React.FC<SelectProps> = ({
    name,
    label,
    items,
    errorMessage,
    formText,
    selectKey,
    selectValue,
    selectValue2,
    selectConcatString,
    defaultKey,
    defaultValue,
    handleChange,
    backgroundColor,
    disabled = false,
    field = false,
}) => {
    const theme = useTheme();
    const selectStyles = useMemo(
        () => ({
            backgroundColor: backgroundColor || theme.inputColor,
            color: theme.textColor,
        }),
        [backgroundColor, theme]
    );

    const renderField = ({ field, form, meta }: FieldProps) => (
        <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                as="select"
                disabled={disabled}
                style={selectStyles}
                value={field.value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleChange?.(e.target.value);
                    form.setFieldValue(name, e.target.value);
                }}
                isInvalid={form.submitCount > 0 && !!meta.error}
            >
                {defaultKey !== undefined && defaultValue !== undefined && (
                    <option value={defaultKey}>{defaultValue}</option>
                )}
                {items.map((item) => (
                    <option
                        key={selectKey ? item[selectKey] : item}
                        value={selectKey ? item[selectKey] : item}
                    >
                        {selectValue ? item[selectValue] : item}
                        {selectValue2 &&
                        item[selectValue2] &&
                        selectConcatString
                            ? selectConcatString
                            : " "}
                        {selectValue2 && item[selectValue2]}
                    </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                {errorMessage ? <ErrorMessage name={name} /> : meta.error}
            </Form.Control.Feedback>
            {formText && <Form.Text muted>{formText}</Form.Text>}
        </Form.Group>
    );

    return field ? (
        <Field name={name}>{renderField}</Field>
    ) : (
        <FastField name={name}>{renderField}</FastField>
    );
};

export default Select;
