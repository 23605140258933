import { ApiReturnType } from "./";

const profileRoutes = {
    GET_PROFILE: (id: string): ApiReturnType => {
        return {
            url: `v2/profile/${id}`,
            method: "get",
        };
    },
    SAVE_PROFILE: (): ApiReturnType => {
        return {
            url: `v2/profile/save`,
            method: "post",
        };
    },
};

export default profileRoutes;
