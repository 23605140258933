import styled from "styled-components";

import BootstrapTable from "react-bootstrap/Table";

export const LeagueTableStyled = styled.div`
    table-layout: fixed;

    & div.header div {
        color: ${(props) => props.theme.textColor};
        border-bottom: 0px solid ${(props) => props.theme.border};
        border-top: 0px solid ${(props) => props.theme.border};
    }

    & div.tbody div.row:hover:not(.selected) {
        background-color: ${(props) => props.theme.primaryHalf};
    }

    & div.tbody div.row:hover {
        cursor: pointer;
    }

    & div.tbody div.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & div.tbody div {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: 0px solid ${(props) => props.theme.border};
    }
`;

export const TableStyled = styled(BootstrapTable)`
    table-layout: fixed;

    & thead th {
        color: ${(props) => props.theme.textColor};
        border-bottom: 0px solid ${(props) => props.theme.border};
        border-top: 0px solid ${(props) => props.theme.border};
    }

    & tbody tr:hover td,
    .table-hover tbody tr:hover th {
        /* background-color: ${(props) => props.theme.primary}; */
        cursor: pointer;
    }

    & tbody tr.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & tbody td {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: 0px solid ${(props) => props.theme.border};
    }
`;

export const LeagueTableTeamStyled = styled.div`
    table-layout: fixed;
    margin-bottom: 0px;

    &.selected {
        background-color: rgb(25, 135, 84);
    }

    & .header div {
        color: ${(props) => props.theme.textColor};
    }

    &:not(.selected) .tbody div:hover,
    .table-hover .tbody div:hover {
        background-color: ${(props) => props.theme.primaryHalf};
    }

    & .tbody div.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & .tbody div {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: none;
    }
`;

export const TableTeamStyled = styled(BootstrapTable)`
    table-layout: fixed;
    margin-bottom: 0px;

    &.selected {
        background-color: rgb(25, 135, 84);
    }

    & thead th {
        color: ${(props) => props.theme.textColor};
    }

    &:not(.selected) tbody tr:hover td,
    .table-hover tbody tr:hover th {
        background-color: ${(props) => props.theme.secondary};
    }

    & tbody tr.selected {
        background-color: ${(props) => props.theme.primary};
    }

    & tbody td {
        vertical-align: middle;
        color: ${(props) => props.theme.textColor};
        border-top: none;
    }
`;
