const darkTheme = {
    primary: "#151a30",
    primaryHalf: "#1e2338",
    secondary: "#222b45",
    secondaryHeader: "#222b45",
    border: "#000000",
    textColor: "#fff",
    selected: "#198754",
    inputColor: "#151a30",
};

export default darkTheme;
