import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { LeaguesLeagueUser, LeagueUser } from "../../models/LeagueUsers";
import { UserModel } from "../../models/User";

import Input from "../atoms/Input";
import AddCoowner from "../molecules/AddCoowner";
import CoownerList from "../molecules/CoownerList";
import {
    ButtonSkeleton,
    InputSkeleton,
    LabelSkeleton,
    ButtonGroupSkeleton,
} from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import PageTemplate from "../templates/PageTemplate";
import AreYouSureModal from "../atoms/AreYouSureModal";
import { VerticalPadding } from "../atoms";

interface ParamTypes {
    id: string;
    showId: string;
}

interface LeagueUserSuccessProps {
    leagueUser: LeaguesLeagueUser;
    coOwners: UserModel[];
}

interface SaveLeagueUserSuccessProps {
    message: string;
    leagueUser: LeagueUser;
}

const validationSchema = yup.object({
    teamName: yup.string().required("Team Name is required"),
});

const EditTeamForm: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const history = useHistory();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState({
        teamName: "",
    });
    const [team, setTeam] = useState<LeaguesLeagueUser>();
    const [showAddCoOwnerModal, setShowAddCoOwnerModal] =
        useState<boolean>(false);
    const [coOwners, setCoOwners] = useState<UserModel[]>();
    const [showLeave, setShowLeave] = useState<boolean>(false);
    const [leaving, setLeaving] = useState<boolean>(false);

    const leagueUserRequest = useApi(apiRoutes.GET_LEAGUE_USER(id), {
        onSuccess: (response: LeagueUserSuccessProps) => {
            setTeam(response.leagueUser);
            setCoOwners(response.coOwners);
            setInitialValues({ teamName: response.leagueUser.team_name });
        },
    });
    const saveLeagueUserRequest = useApi(apiRoutes.SAVE_LEAGUE_USER(id), {
        onSuccess: (response: SaveLeagueUserSuccessProps) => {
            toast.success(response.message);
            setSubmitting(false);
            if (
                response.leagueUser.league_id === 0 ||
                response.leagueUser.league_id === "0" ||
                response.leagueUser.league_id === "team" ||
                response.leagueUser.league_id === "budget"
            ) {
                history.push(
                    `/myleagues/view/${response.leagueUser.league_id}/${response.leagueUser.show_id}`
                );
            } else {
                history.push(
                    `/myleagues/view/${response.leagueUser.league_id}`
                );
            }
        },
        onFailure: () => setSubmitting(false),
    });
    const leaveLeagueRequest = useApi(apiRoutes.LEAVE_LEAGUE(id), {
        onSuccess: (message: string) => {
            toast.success(message);
            setLeaving(false);
            setShowLeave(false);
            history.push(`/myleagues`);
        },
        onFailure: () => setLeaving(false),
        responseKey: "message",
    });

    useEffect(() => {
        leagueUserRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const addCoowner = (coOwner: UserModel) => {
        setCoOwners([...coOwners, coOwner]);
        setShowAddCoOwnerModal(false);
    };

    return (
        <PageTemplate
            header="Edit Team"
            headerSecondary={team ? team.team_name : ""}
            skeleton={
                <>
                    <LabelSkeleton />
                    <InputSkeleton />
                    <ButtonSkeleton />
                    <ButtonGroupSkeleton size={2} />
                </>
            }
            loading={leagueUserRequest.loading}
        >
            {!leagueUserRequest.loading && team && (
                <>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            setSubmitting(true);
                            saveLeagueUserRequest.request(values);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <Link
                                    to={`/myleagues/view/${team.league_id}${
                                        team.league_id === 0 ||
                                        team.league_id === "0" ||
                                        team.league_id === "team" ||
                                        team.league_id === "budget"
                                            ? `/${team.show_id}`
                                            : ""
                                    }`}
                                >
                                    <Button variant="light">Back</Button>
                                </Link>
                                <VerticalPadding />
                                <Input
                                    name="teamName"
                                    label="Team Name:"
                                    placeholder="Team Name"
                                />
                                <div className="mb-5">
                                    <CoownerList
                                        id={team.id.toString()}
                                        coOwners={coOwners}
                                        onDeleteSuccess={(
                                            coOwners: UserModel[]
                                        ) => setCoOwners(coOwners)}
                                    />
                                    <Button
                                        onClick={() =>
                                            setShowAddCoOwnerModal(true)
                                        }
                                    >
                                        Add Co-Owner
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <SubmitButton
                                        className="mr-2"
                                        title="Update"
                                        submitText="Updating"
                                        submitting={submitting}
                                    />
                                    <Button
                                        variant="danger"
                                        onClick={() => setShowLeave(true)}
                                    >
                                        Leave League
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <AreYouSureModal
                        title="Leave League"
                        primaryButton="Leave League"
                        showModal={showLeave}
                        setShowModal={setShowLeave}
                        body={`Are you sure you want to leave this league? This cannot be undone!!`}
                        onSuccess={() => {
                            setLeaving(true);
                            leaveLeagueRequest.request();
                        }}
                        submitting={leaving}
                    />
                    <AddCoowner
                        teamId={team.id}
                        teamName={team.team_name}
                        showModal={showAddCoOwnerModal}
                        setShowModal={setShowAddCoOwnerModal}
                        onAddCoOwner={(coOwner: UserModel) =>
                            addCoowner(coOwner)
                        }
                    />
                </>
            )}
        </PageTemplate>
    );
};

export default EditTeamForm;
