import { ApiReturnType } from "./";

const teamRoutes = {
    ADD_PLAYER_TO_TEAM: (id: string): ApiReturnType => {
        return {
            url: `v2/teams/addPlayerToTeam/${id}`,
            method: "post",
        };
    },
    GET_LIVE_TEAM: (slug: string, episode: string): ApiReturnType => {
        return {
            url: `v2/teams/live/${slug}/${episode}`,
            method: "get",
        };
    },
    GET_PLAYER_LIST: (id: string): ApiReturnType => {
        return {
            url: `v2/teams/playerList/${id}`,
            method: "get",
        };
    },
    GET_TEAM: (id?: string): ApiReturnType => {
        if (id) {
            return {
                url: `v2/teams/${id}`,
                method: "get",
            };
        } else {
            return {
                url: `v2/teams`,
                method: "get",
            };
        }
    },
    GET_TEAM_BY_LEAGUE_USER: (id: string): ApiReturnType => {
        return {
            url: `v2/teams/byLeagueUser/${id}`,
            method: "get",
        };
    },
    REMOVE_PLAYER_FROM_TEAM: (id: string): ApiReturnType => {
        return {
            url: `v2/teams/removePlayerFromTeam/${id}`,
            method: "post",
        };
    },
    SWAP_PLAYER: (id: string): ApiReturnType => {
        return {
            url: `v2/teams/swapPlayer/${id}`,
            method: "post",
        };
    },
};

export default teamRoutes;
