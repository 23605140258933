import React, { ChangeEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import { RootState } from "../../store";
import apiRoutes from "../../services/apiRoutes";
import useApi from "../../hooks/useApi";
import { Article } from "../../models/Articles";
import { imageUrl } from "../../config";

import Input from "../atoms/Input";
import ImageUpload from "../atoms/ImageUpload";
import Wysiwyg from "../atoms/Wysiwyg";
import Select from "../atoms/Select";
import SubmitButton from "../atoms/SubmitButton";
import PageTemplate from "../templates/PageTemplate";
import { TableSkeleton } from "../atoms/Skeletons";

const validationSchema = yup.object({
    show: yup.string().required("Show is required"),
    title: yup.string().required("Title is required"),
    content: yup.string().required("Content is required"),
    image: yup.mixed().required("Article Image is required"),
});

interface ActiveShows {
    id: number | string;
    show: string;
    slug: string;
}

interface ParamTypes {
    type: string;
    id?: string;
}

const AdminArticle: React.FC = () => {
    const { id, type } = useParams<ParamTypes>();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [article, setArticle] = useState<Article>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [initialContent, setInitalContent] = useState<string>(
        type === "edit" ? "Loading..." : ""
    ); //needs to have something here to load the values properly
    const [initialValues, setInitialValues] = useState({
        show: "",
        title: "",
        content: "",
        image: undefined,
    });
    const shows = useSelector((state: RootState) => state.shows);
    const [selectedShow, setSelectedShow] = useState<ActiveShows>();
    const [activeShows] = useState<ActiveShows[]>([
        { id: "general", show: "General Article", slug: "general" },
        ...shows,
    ]);
    const getArticleRequest = useApi(apiRoutes.GET_ARTICLE(id), {
        responseKey: "articles",
        onSuccess: (response: Article) => {
            setArticle(response);

            const showId =
                response.show_id.toString() === "0"
                    ? "general"
                    : response.show_id.toString();
            setInitialValues({
                show: showId,
                title: response.title,
                content: response.description,
                image: `${imageUrl}${response.image}`,
            });
            const activeShow = activeShows.filter(
                (activeShow) => activeShow.id.toString() === showId.toString()
            )[0];
            setSelectedShow(activeShow);
            setInitalContent(response.description);
            setLoading(false);
        },
        onFailure: () => setLoading(false),
    });
    const saveArticleRequest = useApi(apiRoutes.SAVE_ARTICLE(type, id), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setSubmitting(false);
            history.push(`/admin/articles`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const publishArticleRequest = useApi(apiRoutes.PUBLISH_ARTICLE(id), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setSubmitting(false);
            history.push(`/admin/articles`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const unpublishArticleRequest = useApi(apiRoutes.UNPUBLISH_ARTICLE(id), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setSubmitting(false);
            history.push(`/admin/articles`);
        },
        onFailure: () => {
            setSubmitting(false);
        },
    });
    const deleteArticleRequest = useApi(apiRoutes.DELETE_ARTICLE(id), {
        responseKey: "message",
        onSuccess: (message: string) => {
            toast.success(message);
            setDeleting(false);
            history.push(`/admin/articles`);
        },
        onFailure: () => {
            setDeleting(false);
        },
    });

    useEffect(() => {
        if (type === "edit") {
            getArticleRequest.request();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const onImageChange = (
        e: ChangeEvent<HTMLInputElement>,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean
        ) => void
    ) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue("image", files[0]);
        }
    };

    return (
        <PageTemplate
            header={
                type === "edit"
                    ? article
                        ? article.title
                        : "Article"
                    : "Add New Article"
            }
            headerButtons={
                type === "edit" &&
                article && (
                    <Button
                        className="mr-2"
                        onClick={() => {
                            setSubmitting(true);
                            if (article.published) {
                                unpublishArticleRequest.request();
                            } else {
                                publishArticleRequest.request();
                            }
                        }}
                    >
                        {article.published
                            ? "Unpublish Article"
                            : "Publish Article"}
                    </Button>
                )
            }
            loading={loading}
            skeleton={<TableSkeleton size={6} />}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setSubmitting(true);
                    const headers = { "content-type": "multipart/form-data" };
                    const data = new FormData();
                    data.append("image", values.image);
                    data.append("title", values.title);
                    data.append("show_id", values.show);
                    data.append("description", values.content);
                    saveArticleRequest.request(data, headers);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    submitCount,
                    errors,
                    isValid,
                }) => (
                    <Form
                        onSubmit={(e) => {
                            if (!isValid) {
                                toast.error("Please fix the errors");
                            }
                            handleSubmit(e);
                        }}
                    >
                        {type === "edit" && selectedShow && (
                            <div className="text-center">
                                <Link
                                    to={`/shows/article/${selectedShow.slug}/${id}`}
                                    target="_blank"
                                >
                                    <Button className="mb-4">
                                        View Article
                                    </Button>
                                </Link>
                            </div>
                        )}
                        <Select
                            name="show"
                            label="Show:"
                            items={activeShows}
                            selectKey="id"
                            selectValue="show"
                            defaultKey={"0"}
                            defaultValue="Choose Show"
                        />
                        <Input
                            name="title"
                            label="Title:"
                            placeholder="Title"
                        />
                        <Wysiwyg
                            label="Content"
                            name="content"
                            value={values.content}
                            error={errors.content}
                            initialValue={initialContent}
                            setValue={(value) =>
                                setFieldValue("content", value)
                            }
                        />
                        <ImageUpload
                            name="image"
                            label="Article Image"
                            image={values.image}
                            handleChange={(e) =>
                                onImageChange(e, setFieldValue)
                            }
                        />
                        <Row className="ml-1 justify-content-center">
                            <SubmitButton
                                className="mr-1"
                                title="Save Article"
                                submitText="Saving"
                                submitting={submitting}
                            />
                            {type === "edit" && (
                                <Button
                                    variant="danger"
                                    disabled={deleting}
                                    onClick={() => {
                                        setDeleting(true);
                                        deleteArticleRequest.request();
                                    }}
                                >
                                    {deleting
                                        ? "Deleting..."
                                        : "Delete Article"}
                                </Button>
                            )}
                        </Row>
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default AdminArticle;
