import axios from "axios";
import { toast } from "react-toastify";
import { configure } from "axios-hooks";

import { apiUrl, apiPrefix } from "../config";
import { store } from "../store";

const instance = axios.create({
    baseURL: `${apiUrl}${apiPrefix}`,
});

configure({ axios: instance });

instance.interceptors.request.use(
    (config) => {
        //would really like to do this via the setToken method, but it doesn't work
        //oh well, this works
        const token = store.getState().user.api_token;
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(null, (error) => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedError) {
        toast.error("An unexpected error occurrred.");
    }

    //unauthorized
    if (error.response && error.response.status === 401) {
        //logging person out
        toast.error("You are unauthorized to perform this action");
    }

    //dealing with laravel errors
    if (error.response && error.response.status === 422) {
        //all laravel responses
        let errors = error.response.data.errors;
        let result = Object.keys(errors).map(function (key) {
            return errors[key];
        });
        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].length; j++) {
                toast.error(result[i][j]);
            }
        }
    }

    if (error.response && error.response.status === 400) {
        return Promise.reject(error.response.data.message);
    }

    return Promise.reject(error);
});

const setToken = (token: string) => {
    if (token && token.length > 0) {
        instance.defaults.headers.common.Authorization = "Bearer " + token;
    }
};

const request = (method, url, data = {}, headers = {}) => {
    if (method === "get") {
        return instance.request({
            method: method,
            url: url,
            data: data,
            params: data,
            headers: headers,
        });
    } else {
        return instance.request({
            method: method,
            url: url,
            data: data,
            headers: headers,
        });
    }
};

const getReq = (url, params, headers) => {
    return request("get", `${apiUrl}${apiPrefix}${url}`, params, headers);
};

const postReq = (url, params, headers) => {
    return request("post", `${apiUrl}${apiPrefix}${url}`, params, headers);
};

const putReq = (url, params, headers) => {
    return request("put", `${apiUrl}${apiPrefix}${url}`, params, headers);
};

const deleteReq = (url, params, headers) => {
    return request("delete", `${apiUrl}${apiPrefix}${url}`, params, headers);
};

const network = {
    request,
    setToken,
    get: getReq,
    post: postReq,
    put: putReq,
    delete: deleteReq,
};

export default network;
