import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Col, Row } from "react-bootstrap";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { imageUrl } from "../../config";
import { Team } from "../../models/LeagueUsers";
import { League } from "../../models/Leagues";

import { Strikethrough } from "../atoms";
import PlayerRowData from "../atoms/PlayerRowData";
import CastTeam from "./CastTeam";

interface PlayerRowProps {
    commissioner: boolean;
    league: League;
    player: Team;
    onDeleteClick: (e: any) => void;
    currentEpisode: number;
    episode?: number;
}

const PlayerRow: React.FC<PlayerRowProps> = ({
    commissioner,
    league,
    player,
    onDeleteClick,
    currentEpisode,
    episode,
}) => {
    const history = useHistory();
    const [eliminated, setEliminated] = useState<boolean>(false);

    useEffect(() => {
        if (episode) {
            if (episode > 0) {
                if (
                    player.episode_eliminated !== null &&
                    player.episode_eliminated <= episode
                ) {
                    setEliminated(true);
                } else {
                    setEliminated(false);
                }
            } else {
                if (
                    player.episode_eliminated !== null &&
                    player.episode_eliminated < currentEpisode
                ) {
                    setEliminated(true);
                } else {
                    setEliminated(false);
                }
            }
        } else {
            if (
                player.episode_eliminated !== null &&
                player.episode_eliminated < currentEpisode
            ) {
                setEliminated(true);
            } else {
                setEliminated(false);
            }
        }
    }, [episode, player, currentEpisode]);

    return (
        <Row
            className="p-3 align-items-center"
            key={player.cast_id}
            onClick={() =>
                history.push(
                    `/shows/castscores/${player.cast_id}?league_id=${league.id}`
                )
            }
        >
            <Col>
                <Avatar src={`${imageUrl}${player.pic}`} />
                <Col>
                    <Row className="align-items-center">
                        <PlayerRowData
                            player={player}
                            eliminated={eliminated}
                            value={player.name}
                            league={league}
                            errorOverride={
                                <>
                                    <Strikethrough>{player.name}</Strikethrough>{" "}
                                    (Eliminated){" "}
                                </>
                            }
                        />
                        {commissioner && league.active && (
                            <DeleteIcon onClick={onDeleteClick} />
                        )}
                    </Row>
                </Col>
                <CastTeam
                    left
                    size={10}
                    team={player.cast ? player.cast.team : player.team}
                    partner={player.cast ? player.cast.partner : player.partner}
                />
                {player.price && league.draft_type === "Weekly Budget" && (
                    <Col>
                        <Row
                            style={{ color: "#777" }}
                            className="align-items-center font-weight-bold"
                        >
                            <FontAwesomeIcon
                                fontSize={10}
                                icon={faDollarSign}
                            />
                            <span className="pr-3" style={{ fontSize: 10 }}>
                                {player.price}
                            </span>
                        </Row>
                    </Col>
                )}
            </Col>
            {Object.keys(player.scores).map((category: string, i: number) => (
                <Col
                    key={category}
                    className="text-center d-none d-md-table-cell"
                >
                    <PlayerRowData
                        player={player}
                        eliminated={eliminated}
                        value={player.scores[category]}
                        league={league}
                    />
                </Col>
            ))}
            <Col className="text-right pr-5">
                <PlayerRowData
                    player={player}
                    eliminated={eliminated}
                    value={player.total}
                    league={league}
                />
            </Col>
        </Row>
    );
};

export default PlayerRow;
