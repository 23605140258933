import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

import Row from "react-bootstrap/Row";

import { TopScorerList } from "../../models/Scores";
import { Show } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";

import { Well } from "../atoms";
import CastImage from "../atoms/CastImage";
import { ScoreTeamListSkeleton } from "../atoms/Skeletons";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import CastTeam from "./CastTeam";

interface TopScorersProps {
    side?: boolean;
    show: Show;
    leagueId: string;
    episode: string;
}

const TopScorers: React.FC<TopScorersProps> = ({
    show,
    leagueId,
    episode,
    side,
}) => {
    const timezoneOffsets = useSelector(
        (state: RootState) => state.timezoneOffsets
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [topScorers, setTopScorers] = useState<TopScorerList>([]);

    const getTopScorers = useApi(
        apiRoutes.GET_TOP_SCORERS(show.slug, episode),
        {
            responseKey: "topScorers",
            onSuccess(response: TopScorerList) {
                setTopScorers(response);
                setLoading(false);
            },
            onFailure() {
                setLoading(false);
            },
        }
    );

    useEffect(() => {
        if (show) {
            const date = new Date();
            const offsetInHours = date.getTimezoneOffset() / 60;
            if (
                offsetInHours >= timezoneOffsets.hawaii &&
                show.hawaii_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    hawaii: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.alaska &&
                show.alaska_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    alaska: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.pacific &&
                show.pacific_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    pacific: true,
                });
            } else if (
                offsetInHours >= timezoneOffsets.mountain &&
                show.mountain_offset > 0
            ) {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                    mountain: true,
                });
            } else {
                getTopScorers.request({
                    league_id: leagueId === "default" ? 0 : leagueId,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, leagueId]);

    return (
        <Well
            className={
                side
                    ? "d-none d-lg-block d-xl-block"
                    : "d-sm-block d-xs-block d-md-block d-lg-none d-xl-none"
            }
        >
            <h4 className="text-capitalize">Top Scorers:</h4>
            {loading ? (
                <ScoreTeamListSkeleton />
            ) : (
                <>
                    {topScorers.length > 0 ? (
                        <div>
                            {topScorers.map((topScorer, index) => {
                                return (
                                    <Row
                                        key={index}
                                        className="align-items-center ml-1 mb-3 mt-3"
                                    >
                                        <Col xs="10">
                                            <Row className="align-items-center">
                                                <CastImage
                                                    src={topScorer.pic}
                                                />
                                                <div className="ml-2">
                                                    <div>{`${topScorer.name}`}</div>
                                                    {topScorer.cast && (
                                                        <CastTeam
                                                            left
                                                            team={
                                                                topScorer.cast
                                                                    .team
                                                            }
                                                            partner={
                                                                topScorer.cast
                                                                    .partner
                                                            }
                                                            size={10}
                                                        />
                                                    )}
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col xs="2">
                                            <div className="font-weight-bold text-right mr-2">
                                                {topScorer.total}
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    ) : (
                        <div>There are currently no scores</div>
                    )}
                </>
            )}
        </Well>
    );
};

export default React.memo(TopScorers);
