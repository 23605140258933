const apiPrefix = "/api/";
const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const facebookKey = process.env.REACT_APP_FACEBOOK_KEY;
const googleKey = process.env.REACT_APP_GOOGLE_KEY;

const config = {
    apiPrefix,
    apiUrl,
    baseUrl,
    facebookKey,
    googleKey,
};

export { apiPrefix, apiUrl, imageUrl, baseUrl, facebookKey, googleKey };

export default config;
