import { ApiReturnType } from "./";

const wishlistRoutes = {
    ADD_TO_WISHLIST: (league_user_id: string): ApiReturnType => {
        return {
            url: `v2/wishlist/add/${league_user_id}`,
            method: "post",
        };
    },
    REMOVE_FROM_WISHLIST: (league_user_id: string): ApiReturnType => {
        return {
            url: `v2/wishlist/remove/${league_user_id}`,
            method: "post",
        };
    },
};

export default wishlistRoutes;
